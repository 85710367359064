import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-validate-fournisseur',
  templateUrl: './validate-fournisseur.component.html',
  styleUrls: ['./validate-fournisseur.component.css']
})
export class ValidateFournisseurComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ValidateFournisseurComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.data.typeTransaction = "AMOUNT"
     }

  ngOnInit(): void {
  }

  typeTransaction(searchValue: string): void {
    if(searchValue == "AMOUNT")
      delete this.data.valueTransaction;
    this.data.typeTransaction = searchValue
  }

  valueTransactiont(searchValue: string): void {  
    // console.log(searchValue);
    this.data.valueTransaction = searchValue
  }

}
