import { Component, OnInit } from '@angular/core';
import { CommercantsService } from 'src/app/services/commercants/commercants.service';

@Component({
  selector: 'app-fournisseurs',
  templateUrl: './fournisseurs.component.html',
  styleUrls: ['./fournisseurs.component.css']
})
export class FournisseursComponent implements OnInit {

  allCommercants: any = []

  constructor(
    private commercantsService: CommercantsService
  ) { }

  ngOnInit(): void {
    this.listCommercants();
  }

  async listCommercants() {
    await this.commercantsService.allCommercants().then((res: any) => {
      if (res) {
        this.allCommercants = res;
      }
    });
  }
}
