import { Component, OnInit } from '@angular/core';
import { CouponsService } from 'src/app/services/coupons/coupons.service';

@Component({
  selector: 'app-all-codes-promo',
  templateUrl: './all-codes-promo.component.html',
  styleUrls: ['./all-codes-promo.component.css']
})
export class AllCodesPromoComponent implements OnInit {

  tagActive = "ALL"
  codesPromoList: any = []

  constructor(private couponServices: CouponsService) { }

  ngOnInit(): void {
    this.fetchCodePromo();
  }

  fetchCodePromo() {
    this.couponServices.listCoupons().then((d) => {
      if (d) {
        console.log("fetchCodePromo : ")
        console.log(d)
        this.codesPromoList = d;
      }
    });
  }

  listCodesPromo(tagActive) {
    this.tagActive = tagActive
  }

  listCodesPromoActif(tagActive) {
    this.tagActive = tagActive
  }

}
