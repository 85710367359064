import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommercantsService } from 'src/app/services/commercants/commercants.service';

@Component({
  selector: 'app-add-fournisseur',
  templateUrl: './add-fournisseur.component.html',
  styleUrls: ['./add-fournisseur.component.css']
})
export class AddFournisseurComponent implements OnInit {

  villes = [
    '',
    'Ariana',
    ' Béja',
    ' Ben Arous',
    'Bizerte',
    'Gabès',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kébili',
    'Kef',
    'Mahdia',
    'Manouba',
    'Médenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    ' Sidi Bouzid',
    'Siliana',
    ' Sousse',
    'Tataouine',
    ' Tozeur',
    'Tunis',
    'Zaghouan',
  ]

  myForm: FormGroup = new FormGroup({
    nom: new FormControl('', Validators.required),
    societe: new FormControl('', Validators.required),
    raisonSociale: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email,
    ]),
    password: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required),
    telephone: new FormControl('', [Validators.required, Validators.minLength(8), Validators.maxLength(8)]),
    adresse: new FormControl('', Validators.required),
    ville: new FormControl('', Validators.required),
    pays: new FormControl('', Validators.required),
    codePostal: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
  });

  constructor(private router: Router,
    private commercantsService: CommercantsService) { }

  ngOnInit(): void {
  }

  addCommercant() {
    // console.log(this.myForm.value)
    if (this.myForm.valid) {
      if (confirm('Etes vous sûre?')) {
        this.commercantsService.addCommercant(this.myForm.value).then((res: any) => {
          if (res) {
            location.href = '/commercants';
          }
        });
      }
      // this.router.navigate(['commercants'])
    } else {
      alert('Commercant est invalide');
    }
  }

}
