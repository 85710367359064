import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-add-reclamation',
  templateUrl: './add-reclamation.component.html',
  styleUrls: ['./add-reclamation.component.css']
})
export class AddReclamationComponent implements OnInit {

  raisons = [
    "Selectionnez une raison",
    'Produit ne marche pas',
    'Produit non conforme à celui commandé',
    'Retard de livraison',
    'Autre',
  ];
  selectedRaison: string = '';
  isAutre = true;
  submitted = false;

  cmdID: string = '';
  cmd: any = {};

  constructor(
    private route: ActivatedRoute,
    private CmdsServices: CommandesService,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchCmd();
    });
  }
  async _fetchCmd() {
    this.CmdsServices.getOneCMD(this.cmdID).then((d) => {
      if (d) {
        console.log("cmd fetchCmd : ")
        console.log(d)
        this.cmd = d;
      } else {
        alert('Aucune commande valide');
        location.href = '/commandes';
      }
    });
  }

  selectChangeRaison(event: any) {
    //update the ui
    this.selectedRaison = event.target.value;
    console.log(event.target.value)
    if (event.target.value == "Autre")
      this.isAutre = false;
    else
      this.isAutre = true;
  }

  addReclamation(comment, autre) {
    this.submitted = true;
    let raison = !this.isAutre ? autre.value : this.selectedRaison
    this.CmdsServices.addReclamation(this.cmdID, raison, comment.value).then((rec) => {
      console.log("rec : " + rec)
      if (rec) {
        location.href = '/reclamations-commande';
      }
    })
  }

}
