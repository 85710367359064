import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from 'src/app/services/products/products.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-produits-category',
  templateUrl: './produits-category.component.html',
  styleUrls: ['./produits-category.component.css']
})
export class ProduitsCategoryComponent implements OnInit {

  idCat: string = ""
  prdsCat: any = []
  allCats: any = [];
  cat: any = {}

  isAdmin: Boolean = false
  isStock: Boolean = false
  isValidator: Boolean = false

  constructor(private route: ActivatedRoute,
    private PrdsServices: ProductsService,
    private authService: UsersService,) {      
    this.isUserAdmin()
    this.isUserStock()
    this.isUserValidator()
     }

  
    ngOnInit(): void {
      this.listCats()
  
      this.route.queryParams.subscribe(async (params) => {
        this.idCat = params.cat;
      });
      this.listProduitsCat()
      this.getCat()
    }

    invaliderProduct(idPrd) {
      if (confirm('Etes vous sûre?')) {
        this.PrdsServices.invalidPrd(idPrd).then((res: any) => {
          // console.log("invaliderProduct res")
          // console.log(res)
          if (res) {
            // location.href = '/pending-produit';
            this.choiceCat()
          }
        });
      }
    }
  
    selectCat(event) {
      this.idCat = event.target.value
    }
  
    async listProduitsCat() {
      await this.PrdsServices.allPrdsCat(this.idCat).then((res: any) => {
        if (res) {
          this.prdsCat = res;
        }
      });
    }
  
    async listCats() {
      await this.PrdsServices.allCats().then((res: any) => {
        if (res) {
          this.allCats = res;
        }
      });
    }
  
    async getCat() {
      await this.PrdsServices.oneCat(this.idCat).then((res: any) => {
        if (res) {
          this.cat = res;
        }
      });
    }
  
    choiceCat() {
      this.listProduitsCat()
      this.getCat()
    }

    async isUserAdmin() {
      this.isAdmin = await this.authService.isAdmin()
    }
    async isUserStock() {
      this.isStock = await this.authService.isStock()
    }
    async isUserValidator() {
      this.isValidator = await this.authService.isValidator()
    }
  
  }