<section class="section">
  <div class="d-flex flex-wrap align-items-stretch">
    <div class="col-lg-4 col-md-6 col-12 order-lg-1 min-vh-100 order-2 bg-white">
      <div class="p-4 m-3">
        <img src="assets/img/stisla-fill.svg" alt="logo" width="80" class="shadow-light rounded-circle mb-5 mt-2">
        <h4 class="text-dark font-weight-normal"><span class="font-weight-bold">Authentification</span></h4>
        <p class="text-muted">{{msg}}</p>
        <form class="needs-validation" action="" method="post" onsubmit="return false;" (onsubmit)="authentification();">
          <div class="form-group">
            <label for="username">Email</label>
            <input type="text" id="username" class="form-control" name="username" tabindex="1" #username [(ngModel)]="userInscrir.username" required autofocus>
            <label class="msg-error" *ngIf="msgErrorUsername!=null">{{msgErrorUsername}}</label>
            <div class="invalid-feedback">
              Email obligatoire et doit contenir '@'
            </div>
          </div>

          <div class="form-group">
            <div class="d-block">
              <label for="password" class="control-label">Mot de passe</label>
            </div>
            <input type="password" id="password" class="form-control" name="password" tabindex="2" #password [(ngModel)]="userInscrir.password" required>
            <label class="msg-error" *ngIf="msgErrorPassword!=null">{{msgErrorPassword}}</label> <br />
            <div class="invalid-feedback">
              Mot de passe Obligatoire
            </div>
          </div>


          <div class="form-group text-right">

            <button type="submit" (click)="authentification()" class="btn btn-primary btn-lg btn-icon icon-right"
              tabindex="4">
              Se connecter
            </button>
            <br />
            <label class="msg-error" *ngIf="msgErrorLogin!=null">{{msgErrorLogin}}</label>
          </div>

          <div class="mt-5 text-center">

          </div>
        </form>

        <div class="text-center mt-5 text-small">
          Copyright &copy; MarketBey
        </div>
      </div>
    </div>
    <div
      class="col-lg-8 col-12 order-lg-2 order-1 min-vh-100 background-walk-y position-relative overlay-gradient-bottom"
      data-background="assets/img/unsplash/login-bg.jpg">
      <div class="absolute-bottom-left index-2">
        <!-- <div class="text-light p-5 pb-2">
          <div class="mb-5 pb-3">
            <h1 class="mb-2 display-4 font-weight-bold">Authetification</h1>
            <h5 class="font-weight-normal text-muted-transparent">Bali, Indonesia</h5>
          </div>
         </div> -->
      </div>
    </div>
  </div>
</section>
