<!-- Main Content -->
<div class="main-content">
    <section class="section">
        <div class="section-header">
            <h1>Clients MarketBey</h1>
            <div class="section-header-button">
                <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
            </div>
            <div class="section-header-breadcrumb">
                <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
                <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                <div class="breadcrumb-item">Toutes les Commandes</div>
            </div>
        </div>
        <div class="section-body">
            <!-- <h2 class="section-title">Liste des commandes</h2> -->
            <!-- <p class="section-lead">
              </p> -->

            <div *ngIf="isLoading; else elseAllClients">
                <div class="row">
                    <div class="col-12">
                        <div class="mb-0 card">
                            <div class="card-body text-center">
                                <div class="spinner-container blue-spinner">
                                    <mat-progress-spinner mode="indeterminate" [diameter]="40"
                                        [strokeWidth]="5"></mat-progress-spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #elseAllClients>
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-header-uppercase">Liste des clients MarketBey</h4>
                                <!-- <div class="card-header-action">
                                        <a routerLink="/add-user-mb" class="btn btn-danger">Ajouter <i
                                                class="fas fa-plus"></i></a>
                                    </div> -->
                            </div>
                            <div class="card-body">
                                <div class="table-responsive table-invoice">
                                    <table
                                        class="table table-stripped table-hover table-bordred color-table primary-table">
                                        <tbody>
                                            <tr *ngFor="let user of allUsers; let index=index">
                                                <td>#{{index+1}}</td>
                                                <td width="350">
                                                    <div class="row">
                                                        <div class="col-xs-2 margin-right">
                                                            <ngx-avatar class="rounded-circle"
                                                                name="{{user.client.prenom_user}} {{user.client.nom_user}}">
                                                            </ngx-avatar>
                                                        </div>
                                                        <div class="col-xs-8">
                                                            <!-- <div class="media-title">
                                                                  {{user.email_user}}</div> -->
                                                            <h4 class="text-muted m-t-0">{{user.client.email_user}}
                                                            </h4>
                                                        </div>

                                                    </div>


                                                </td>
                                                <td>{{user.client.prenom_user}} {{user.client.nom_user}}</td>
                                                <td>{{user.client.tel_user}}</td>
                                                <td>{{user.client.dateinscrip_user | date:'dd MMM yyyy'}}</td>
                                                <td>{{user.nbrCmds}}</td>
                                                <td class="text-right">
                                                    <!-- <a title="Modifier"
                                                            class="btn btn-success btn-action trigger--fire-modal-1"><i
                                                                class="fas fa-pencil-alt"></i></a> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </section>
</div>
<!-- <footer class="main-footer">
          <div class="footer-left">
            Copyright &copy; 2018 <div class="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval Azhar</a>
          </div>
          <div class="footer-right">
            2.3.0
          </div>
        </footer> -->
<!-- </div>
</div> -->