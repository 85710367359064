<div class="main-sidebar" style="overflow: auto;">
  <aside id="sidebar-wrapper">
    <div class="sidebar-brand">
      <a routerLink="commandes">MarketBey</a>
    </div>
    <div class="sidebar-brand sidebar-brand-sm">
      <a routerLink="commandes">MB</a>
    </div>
    <ul class="sidebar-menu">
      <!-- <li class="menu-header">Dashboard</li>
      <li routerLinkActive="active"><a class="nav-link" [routerLink]="['/dashboard']"><i
            class="fas fa-fire"></i> <span>Dashboard</span></a></li>
      <li class="dropdown" routerLinkActive="active">
        <a href="#" class="nav-link has-dropdown"><i class="far fa-user"></i> <span>User</span></a>
        <ul class="dropdown-menu">
          <li routerLinkActive="active"><a [routerLink]="['/dashboard']">Manage User</a></li>
          <li routerLinkActive="active"><a [routerLink]="['/dashboard']">Add User</a></li>
        </ul>
      </li> -->
      <div *ngIf="isAdmin">
        <li class="menu-header">Tableau de bord</li>
        <li class="nav-item"> <a routerLink="" class="nav-link"><i class="fas fa-fire"></i><span>Tableau de
              bord</span></a>
        </li>
      </div>

      <div *ngIf="isAdmin">
        <li class="menu-header">MarketBey Teams</li>
        <li class="nav-item">
          <a routerLink="teams-mb" class="nav-link"><i class="fas fa-file-alt"></i>
            <span>Liste d'équipe</span>
          </a>
        </li>
      </div>

      <li class="menu-header">Commandes</li>
      <li class="nav-item"> <a routerLink="my-commandes" class="nav-link"><i class="fas fa-file-alt"></i><span>
            Mes commandes
          </span></a>
      </li>
      <li class="nav-item"> <a routerLink="commandes" class="nav-link"><i class="fas fa-file-alt"></i><span>
            Gestion des commandes
          </span></a>
      </li>
      <li class="nav-item"> <a routerLink="commandes-website" class="nav-link"><i class="fas fa-file-alt"></i><span>
            Les commandes MarketBey
          </span></a>
      </li>
      <li class="nav-item"> <a routerLink="commandes-facebook" class="nav-link"><i class="fas fa-file-alt"></i><span>
            Les commandes Facebook
          </span></a>
      </li>

      <li class="menu-header">Gestion de stock</li>
      <li class="nav-item" *ngIf="isAdmin || isStock || isValidator"> <a routerLink="add-produit" class="nav-link"><i
            class="fas fa-file-alt"></i><span>
            Ajouter un produit
          </span></a>
      </li>
      <li class="nav-item"> <a routerLink="produits" class="nav-link"><i class="fas fa-file-alt"></i><span>
            Liste des produits
          </span></a>
      </li>
      <li class="nav-item" *ngIf="isAdmin || isStock || isValidator">
        <a routerLink="pending-produit" class="nav-link">
          <i class="fas fa-file-alt"></i>
          <span>
            Les produits en attente
          </span>
        </a>
      </li>
      <li class="nav-item" *ngIf="isAdmin || isStock || isValidator"> <a routerLink="add-category" class="nav-link"><i
            class="fas fa-file-alt"></i><span>
            Ajouter catégorie
          </span></a>
      </li>
      <li class="nav-item"> <a routerLink="categories" class="nav-link"><i class="fas fa-file-alt"></i><span>
            Liste des catégories
          </span></a>
      </li>
      <li class="nav-item" *ngIf="isAdmin || isStock || isValidator"> <a routerLink="code-produit" class="nav-link"><i
            class="fas fa-file-alt"></i><span>
            Ajouter code produit
          </span></a>
      </li>
      <!-- <li class="nav-item"> <a routerLink="/commandes-facebook" class="nav-link"><i class="fas fa-file-alt"></i><span>
            Sliders
          </span></a>
      </li> -->
      <!-- <li class="nav-item"> <a routerLink="/commandes-pretes" class="nav-link"><i class="fas fa-file-alt"></i><span>
            Commandes prêtes
          </span></a>
      </li> -->
      <!-- <li class="nav-item dropdown">
        <a class="nav-link has-dropdown" data-toggle="dropdown"><i class="fas fa-file-alt"></i>
          <span>Commandes</span></a>
        <ul class="dropdown-menu">
          <li><a class="nav-link" routerLink="/commandes">En attente</a></li>
          <li><a class="nav-link" routerLink="/commandes">Validées</a></li>
          <li><a class="nav-link" routerLink="/commandes">Livrées</a></li>
          <li><a class="nav-link" routerLink="/commandes">Annulées</a></li>
        </ul>
      </li> -->

      <!-- <li class="menu-header">Configuration</li>
      <li class="nav-item">
        <a routerLink="/dashboard" class="nav-link"><i class="fas fa-map-marker"></i>
          <span>Points de collecte</span>
        </a>
      </li>
      <li class="nav-item">
        <a routerLink="/dashboard" class="nav-link"><i class="fas fa-mobile"></i>
          <span>Accès application</span>
        </a>
      </li> -->

      <!-- <li class="menu-header">Suivi des transporteurs</li>
      <li class="nav-item">
        <a routerLink="/tracking-user" class="nav-link"><i class="fas fa-file-alt"></i>
          <span>Transporteurs</span>
        </a>
      </li> -->
      <div *ngIf="isAdmin || isValidator">
        <li class="menu-header">Fournisseurs</li>
        <li class="nav-item">
          <a routerLink="commercants" class="nav-link"><i class="fas fa-file-alt"></i>
            <span>Liste des fournisseurs</span>
          </a>
          <a routerLink="add-commercant" class="nav-link"><i class="fas fa-file-alt"></i>
            <span>Ajouter un fournisseur</span>
          </a>
          <a routerLink="invalid-commercant" class="nav-link"><i class="fas fa-file-alt"></i>
            <span>Valider Fournisseurs</span>
          </a>
        </li>
      </div>

      <div *ngIf="isAdmin || isValidator">
        <li class="menu-header">Promotions</li>
        <li class="nav-item">
          <a routerLink="add-code-promo" class="nav-link"><i class="fas fa-edit"></i>
            <span>Ajouter Code promro</span>
          </a>
          <a routerLink="codes-promo" class="nav-link"><i class="fas fa-file-alt"></i>
            <span>Liste des codes promo</span>
          </a>
        </li>
      </div>

      <div *ngIf="isAdmin">
        <li class="menu-header">Inventaire</li>
        <li class="nav-item">
          <a routerLink="inventaire" class="nav-link"><i class="fas fa-search"></i>
            <span>Chiffre d'affaire</span>
          </a>
        </li>
      </div>
      <!-- <li class="nav-item dropdown">
        <a href="#" class="nav-link has-dropdown"><i class="fas fa-th-large"></i>
          <span>Points de collecte</span></a>
        <ul class="dropdown-menu">
          <li><a class="nav-link" routerLink="/dashboard">Ajouter</a></li>
          <li><a class="nav-link" routerLink="/dashboard">Liste</a></li>
        </ul>
      </li> -->
      <div *ngIf="isAdmin">
        <li class="menu-header">Clients MB</li>
        <li class="nav-item">
          <a routerLink="clients" class="nav-link"><i class="fas fa-search"></i>
            <span>Clients</span>
          </a>
        </li>
      </div>

      <li class="menu-header">Réclamation des commandes</li>
      <li class="nav-item">
        <a routerLink="reclamations-commande" class="nav-link"><i class="fas fa-file-alt"></i>
          <span>Réclamations</span>
        </a>
      </li>

    </ul>

    <div class="mt-4 mb-4 p-3 hide-sidebar-mini">
      <button type="button" (click)="deconnexion()" class="btn btn-primary btn-lg btn-block btn-icon-split">
        <i class="fas fa-power-off"></i> Déconnexion
      </button>
    </div>
  </aside>
</div>