import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  currentUser: any;


  isAdmin: Boolean = false
  isSimpleUser: Boolean = false
  isStock: Boolean = false
  isValidator: Boolean = false

  constructor(private route: ActivatedRoute,
    private authService: UsersService,
    private router: Router,) {
      this.authService.getUserInfos().then(x => this.currentUser = x);
      this.authService.isAdmin().then(x => this.isAdmin = x)
    this.isUserAdmin()
    this.isUserStock()
    this.isUserValidator()
  }
  get isAdminn() {
    //return this.currentUser && this.currentUser.role === Role.Admin;

    if (this.currentUser.profiles != null && this.currentUser.profiles.find(profile => profile === 'admin')) {
      return true
    }
    return false
}

  async ngOnInit() {
    this.route.fragment.subscribe(f => {
      const element = document.querySelector("#" + f)
      if (element) element.scrollIntoView()
    })
    // this.isSimpleUser = await this.authService.isSimpleUser()
  }

  async deconnexion() {
    await this.authService.deconnexion()
    // window.location.reload()
    this.router.navigate(['/logout'])
  }

  async isUserAdmin() {
    this.isAdmin = await this.authService.isAdmin()
  }
  async isUserStock() {
    this.isStock = await this.authService.isStock()
  }
  async isUserValidator() {
    this.isValidator = await this.authService.isValidator()
  }
}
