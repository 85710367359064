<!-- Main Content -->
<div class="main-content">
  <section class="section">
    <div class="section-header">
      <h1>Liste des commandes</h1>
      <div class="section-header-button">
        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
      </div>
      <div class="section-header-breadcrumb">
        <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
        <div class="breadcrumb-item">Toutes les Commandes</div>
      </div>
    </div>
    <div class="section-body">
      <!-- <h2 class="section-title">Liste des commandes</h2> -->
      <!-- <p class="section-lead">
          </p> -->

      <div *ngIf="isLoading; else elseAllCmds">
        <div class="row">
          <div class="col-12">
            <div class="mb-0 card">
              <div class="card-body text-center">
                <div class="spinner-container blue-spinner">
                  <mat-progress-spinner mode="indeterminate" [diameter]="40" [strokeWidth]="5"></mat-progress-spinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-template #elseAllCmds>
        <div class="row">
          <div class="col-12">
            <div class="card mb-0">
              <div class="card-body">
                <ul class="nav nav-pills float-right">
                  <li class="nav-item">
                    <a [ngClass]="(tagActive==='ALL')?'nav-link active':'nav-link'"
                      (click)="listCmdsByStatus('ALL')">Tous
                      <span
                        [ngClass]="(tagActive==='ALL')?'badge badge-white':'badge badge-primary'">{{cmdsListAll.length}}</span></a>
                  </li>
                  <li class="nav-item">
                    <a [ngClass]="(tagActive==='validated')?'nav-link active':'nav-link'"
                      (click)="listCmdsByStatus('validated')">Validées <span
                        [ngClass]="(tagActive==='validated')?'badge badge-white':'badge badge-primary'">{{cmdsListValidate.length}}</span></a>
                  </li>

                  <li class="nav-item">
                    <a [ngClass]="(tagActive==='prepared')?'nav-link active':'nav-link'"
                      (click)="listCmdsByStatus('prepared')">Préparées <span
                        [ngClass]="(tagActive==='prepared')?'badge badge-white':'badge badge-primary'">{{cmdsListPrete.length}}</span></a>
                  </li>

                  <li class="nav-item">
                    <a [ngClass]="(tagActive==='delivered')?'nav-link active':'nav-link'"
                      (click)="listCmdsByStatus('delivered')">Livrées <span
                        [ngClass]="(tagActive==='delivered')?'badge badge-white':'badge badge-primary'">{{cmdsListArrived.length}}</span></a>
                  </li>

                  <li class="nav-item">
                    <a [ngClass]="(tagActive==='paied')?'nav-link active':'nav-link'"
                      (click)="listCmdsByStatus('paied')">Payées <span
                        [ngClass]="(tagActive==='paied')?'badge badge-white':'badge badge-primary'">{{cmdsListPaied.length}}</span></a>
                  </li>

                  <li class="nav-item">
                    <a [ngClass]="(tagActive==='returned')?'nav-link active':'nav-link'"
                      (click)="listCmdsByStatus('returned')">Retournée <span
                        [ngClass]="(tagActive==='returned')?'badge badge-white':'badge badge-primary'">{{cmdsListReturned.length}}</span></a>
                  </li>

                  <li class="nav-item">
                    <a [ngClass]="(tagActive==='canceled')?'nav-link active':'nav-link'"
                      (click)="listCmdsByStatus('canceled')">Annulée <span
                        [ngClass]="(tagActive==='canceled')?'badge badge-white':'badge badge-primary'">{{cmdsListCanceled.length}}</span></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <div class="card">
              <!-- <div class="card-header">
                  <h4>Liste des commandes</h4>
                </div> -->
              <div class="card-body">
                <div class="float-left">
                  <!-- <select class="form-control selectric">
                      <option>Recherche par</option>
                      <option>Référence</option>
                      <option>Date</option>
                      <option>nom client</option>
                    </select> -->
                  <div class="div-form">
                    <form class="needs-validation" action="" method="post" onsubmit="return false;"
                      (onsubmit)="search(ref, numero, datein, datefin);">
                      <div class="form-group">
                        <label for="ref">Référence</label>
                        <input id="ref" type="text" class="form-control" name="ref" tabindex="1" #ref ngModel>
                      </div>
                      <div class="form-group">
                        <label for="stat">Status</label>
                        <select class="form-control selectric" (change)="selectChangeStatus($event)">
                          <option [value]="stat" *ngFor="let stat of status">{{stat}}</option>
                        </select>
                      </div>
                      <div class="form-group">
                        <label for="ville">Ville</label>
                        <select class="form-control selectric" (change)="selectChangeVille($event)">
                          <option [value]="ville" *ngFor="let ville of villes">{{ville}}</option>
                        </select>
                        <!-- <div class="d-block">
                            <label for="ville">Ville</label>
                          </div>
                          <input id="ville" type="text" class="form-control" name="ville" tabindex="2" #ville ngModel> -->
                        <!-- <div class="invalid-feedback">
                          Mot Obligatoire
                          </div> -->
                      </div>
                      <div class="form-group">
                        <label for="numero">Numéro de téléphone</label>
                        <input id="numero" type="text" class="form-control" name="numero" tabindex="1" #numero ngModel>
                      </div>

                      <!-- <div class="form-group">
                          <label for="status">Status</label>
                          <input id="status" type="text" class="form-control" name="status" tabindex="1" #status
                            ngModel>
                        </div> -->

                      <div class="form-group">
                        <label for="datein">Date Début</label>
                        <input id="datein" type="date" class="form-control" name="datein" tabindex="1" #datein ngModel>
                      </div>

                      <div class="form-group">
                        <label for="datefin">Date Fin</label>
                        <input id="datefin" type="date" class="form-control" name="datefin" tabindex="1" #datefin
                          ngModel>
                      </div>
                      <button type="submit" (click)="search(ref, numero, datein, datefin)"
                        class="btn btn-primary btn-lg btn-icon icon-righ" id="search" tabindex="4">
                        <!-- Recherche -->
                        <i class="fas fa-search"></i>
                      </button>
                    </form>
                  </div>
                </div>

                <div class="float-right" *ngIf="cmdsListToAffect.length > 0">
                  <form>
                    <div class="input-group">
                      <div class="input-group-append">
                        <a class="btn btn-info" (click)="affectMoreCmds()"><i class="fas fa-search"> Affecté
                            tous</i></a>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="clearfix mb-3"></div>

                <div class="table-responsive table-invoice">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Actions</th>
                        <th>Référence</th>
                        <th>Date Création</th>
                        <th class="text-center">Email<br>Nom Client & Téléphone</th>
                        <th class="text-center">Produits<br>
                          Quantité - Nom produit</th>
                        <th>Ville</th>
                        <th>Commentaire</th>
                        <th>Total Commande</th>
                        <!-- <th>Nombre des produits</th> -->
                        <!-- <th>Affecté à</th> -->
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let cmd of cmdsList">
                        <!-- <td><input type="checkbox" (change)="addCmdToAffect($event, cmd)"
                            *ngIf="cmd.etat_cmd=='prepared'"></td> -->
                        <td>
                          <div class="dropdown d-inline mr-2">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">Actions</button>
                            <div class="dropdown-menu">
                              <a (click)="updateStatusCmd(cmd._id,'refused')" class="dropdown-item"
                                *ngIf="cmd.etat_cmd=='prepared' ">
                                <i class="fas fa-exclamation-circle"></i>Retourner</a>

                              <a (click)="validerCmd(cmd)" class="dropdown-item" *ngIf="cmd.etat_cmd=='pending'">
                                <i class="fa fa-check"></i>Valider</a>
                              <!-- (click)="updateStatusCmd(cmd._id,'validated')"> <i class="fa fa-check"></i> Valider</a> -->

                              <a href="javascript:void(0)" *ngIf="cmd.etat_cmd=='validated'" class="dropdown-item"
                                (click)="updateStatusCmd(cmd._id,'prepared')"> <i class="fa fa-check"></i>Prête</a>

                              <!-- <a href="javascript:void(0)" *ngIf="cmd.etat_cmd=='preparing'" class="dropdown-item"
                                (click)="updateStatusCmd(cmd._id,'')"> <i class="fa fa-check"></i>Prête</a> -->

                              <a [routerLink]="['/details-commande']" [queryParams]="{ cmd: cmd._id }"
                                class="dropdown-item">
                                <i class="fa fa-info-circle"></i> Détails</a>

                              <a [routerLink]="['/facture']" [queryParams]="{ cmd: cmd._id }" class="dropdown-item">
                                <i class="fa fa-info-circle"></i>Facture</a>

                              <a (click)="affectCmd(cmd._id)" class="dropdown-item" *ngIf="cmd.etat_cmd=='prepared'">
                                <i class="fa fa-check"></i>Affecter</a>

                              <!-- <a [routerLink]="['/add-reclamation']" [queryParams]="{ cmd: cmd._id }" -->
                              <a (click)="createReclamation(cmd._id)" class="dropdown-item">
                                <i class="fa fa-check"></i>Reclamer</a>

                              <a (click)="updateStatusCmd(cmd._id,'canceled')" class="dropdown-item"
                                *ngIf="cmd.etat_cmd=='pending' || cmd.etat_cmd=='validated'">
                                <i class="fa fa-check"></i>Annuler</a>

                              <a (click)="updateStatusCmd(cmd._id,'refused')" class="dropdown-item"
                                *ngIf="cmd.etat_cmd=='prepared' ">
                                <i class="fas fa-exclamation-circle"></i>Retourner</a>
                            </div>
                          </div>
                        </td>
                        <td><b>{{cmd.ref_cmd}}</b>
                        </td>
                        <td>
                          {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}<br>
                          <b>{{cmd.date_creation_cmd | date:'HH:mm'}}</b>
                        </td>
                        <td class="text-center">{{cmd.facturation.email}} <br>
                          <div class="d-inline-block ml-1">{{cmd.facturation.nom}}</div><br />
                          <b>{{cmd.facturation.numtel}}</b>
                        </td>
                        <td>
                          <div *ngFor="let prd of cmd.basket.prdts, let index = index">
                            {{prd.quantite}} - {{prd.nom}}
                            <div *ngIf="prd.variation_choisi!=null">
                              <span *ngFor="let variation of prd?.variation_choisi | objNgFor">
                                <div><b> {{variation.value}} </b></div>
                              </span>
                            </div>
                          </div>
                        </td>
                        <td class="text-center">{{cmd.facturation.adresse}}<br>{{cmd.facturation.ville}}</td>
                        <td class="text-center">
                          <span class="badge badge-danger" (click)="addComment(cmd)">{{cmd.comments?.length}}</span>
                        </td>
                        <td>{{cmd.total_ca_cmd + cmd.bulk_session_datas_cmd.frais_livraison | number:'0.3'}} TND</td>
                        <!-- <td>{{cmd.nbr_prdt_cmd}}</td> -->
                        <!-- <td>{{cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_name}}</td> -->
                        <td>
                          <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">
                            {{statusCmds[cmd.etat_cmd].label}}
                          </div>
                          <div><span>{{cmd.msg_returned_cmd}}</span></div>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="d-block text-center card-footer" *ngIf="cmdsList?.length > 0 && totalPages > 1">
                  <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                      <li [ngClass]="(currentPage===0) ? 'page-item disabled' : 'page-item'">
                        <a class="page-link" tabindex="-1" (click)="previousPage()">
                          <i class="nav-link-icon pe-7s-angle-left-circle"></i>
                          Précédent</a>
                      </li>

                      <div *ngFor="let p of pagesDisplaied">
                        <li [ngClass]="(currentPage===p)?'page-item active':'page-item'">
                          <a *ngIf="currentPage!==p" class="page-link" (click)="selectPage(p)">{{p+1}}</a>

                          <span *ngIf="currentPage===p" class="page-link">
                            {{p+1}}
                            <span class="sr-only">(current)</span>
                          </span>
                        </li>
                      </div>

                      <li [ngClass]="(currentPage===totalPages-1)?'page-item disabled':'page-item'">
                        <a class="page-link" tabindex="-1" (click)="nextPage()">Suivant <i
                            class="nav-link-icon pe-7s-angle-right-circle"></i></a>
                      </li>
                    </ul>
                  </nav>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </ng-template>

    </div>
  </section>
</div>