<!-- <div id="app">
    <div class="main-wrapper">
      <app-top></app-top>
      <app-menu></app-menu> -->
  
      <!-- Main Content -->
      <div class="main-content">
        <section class="section">
          <div class="section-header">
            <h1>Liste des commandes du fournisseur</h1>
            <div class="section-header-button">
              <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
            </div>
            <div class="section-header-breadcrumb">
              <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
              <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
              <div class="breadcrumb-item">Toutes les Commandes</div>
            </div>
          </div>
          <div class="section-body">
            <!-- <h2 class="section-title">Liste des commandes</h2> -->
            <!-- <p class="section-lead">
            </p> -->
  
            
            <div class="row mt-4">
              <div class="col-12">
                <div class="card">
                  <!-- <div class="card-header">
                    <h4>Liste des commandes</h4>
                  </div> -->
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped">
                        <tr>
                          <!-- <th>Actions</th> -->
                          <th>Référence</th>
                          <th>Date Création</th>
                          <th>Nom Client & Téléphone</th>
                          <th>Email</th>
                          <th>Ville</th>
                          <th>Total Commande</th>
                          <!-- <th>Nombre des produits</th> -->
                          <!-- <th>Affecté à</th> -->
                          <th>Status</th>
                        </tr>
                        <tr *ngFor="let cmd of cmdsComm">
                          <!-- <td><input type="checkbox" (change)="addCmdToAffect($event, cmd)"
                              *ngIf="cmd.etat_cmd=='prepared'"></td> -->
                          <td><b>{{cmd.ref_cmd}}</b>
                          </td>
                          <td>
                            {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}
                          </td>
                          <td>
                            <div class="d-inline-block ml-1">{{cmd.facturation.nom}}</div><br />
                            <b>{{cmd.facturation.numtel}}</b>
                          </td>
                          <td>{{cmd.facturation.email}}</td>
                          <td>{{cmd.facturation.ville}}</td>
                          <td>{{cmd.total_ca_cmd + cmd.bulk_session_datas_cmd.frais_livraison | number:'0.3'}} TND</td>
                          <!-- <td>{{cmd.nbr_prdt_cmd}}</td> -->
                          <!-- <td>{{cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_name}}</td> -->
                          <td>
                            <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">{{statusCmds[cmd.etat_cmd].label}}
  
                            </div>
                            <div><span>{{cmd.msg_returned_cmd}}</span></div>
                          </td>
  
                        </tr>
                      </table>
                    </div>
  
                    <!-- <div class="float-right">
                      <nav>
                        <ul class="pagination">
                          <li class="page-item disabled">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>
                          <li class="page-item active">
                            <a class="page-link" href="#">1</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">2</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">3</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- <footer class="main-footer">
        <div class="footer-left">
          Copyright &copy; 2018 <div class="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval Azhar</a>
        </div>
        <div class="footer-right">
          2.3.0
        </div>
      </footer> -->
    <!-- </div>
  </div> -->