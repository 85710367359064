import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  getProduct(idPrd) {
    return new Promise((slv) => {
      this.http.post(environment.apiOneProduct + "/" + idPrd, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
  addStockProduct(idPrd, stockPrd) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddStockProduct, {idPrd, stockPrd}).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
  allPrds(page) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllPrds, {
        page: page
      }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  allPendingPrds() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllPendingPrds, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  allPrdsCat(idCat) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllPrdsCat, {"cat": idCat}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  allCmdsCat(idCat) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsCat, {"idCat": idCat}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  addCatPrds(label, code, parent) {
    // console.log("addCatPrds : ")
    return new Promise((slv) => {
      this.http.post(environment.apiAddCatProducts, {
        label: label, code: code, parent: parent
      }).subscribe(
        (data: any) => {
          // console.log("dataaaaaaa : ")
          // console.log(data);
          if (data && data.response) {
            slv(data.response);
          } else slv(false);
        },
        (error) => {
          console.log(error)
          slv(false);
        }
      );
    });
  }
  allCatsPrds() {
    return new Promise((slv) => {
      this.http.post(environment.apiCatsProducts, {}).subscribe(
        (data: any) => {
          // console.log("dataaaaaaa : ")
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  allSousCatsPrds(idParentCat) {
    return new Promise((slv) => {
      this.http.post(environment.apiSousCatsProducts, {idParentCat: idParentCat}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  countCatsPrds(catProduit, ssCatProduit, typecodePrd) {
    return new Promise((slv) => {
      this.http.post(environment.apiCountCatsProducts, {catProduit,
        ssCatProduit,
        typecodePrd}).subscribe(
        (data: any) => {
          if (data && data.response && data.count) {
            slv(data.count);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  addProduct(formData) {
    return new Promise((slv) => {
      this.http.post(environment.apiUploadProducts, formData).subscribe(
        (data: any) => {
          console.log("dataaaaaaaaaa : ")
          console.log(data)
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  editProduct(formData) {
    console.log("d5al")
    return new Promise((slv) => {
      this.http.post(environment.apiEditProduct, formData).subscribe(
        (data: any) => {
          console.log("dataaaaaaaaaa apiEditProduct : ")
          console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  invalidPrd(idPrd) {
    return new Promise((slv) => {
      this.http.post(environment.apiInvalidProduct, {"idProduct": idPrd}).subscribe(
        (data: any) => {
          console.log("data service : ")
          console.log(JSON.stringify(data));
          if (data && data.response) {
            slv(data.response);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  oneCat(idCat) {
    return new Promise((slv) => {
      this.http.post(environment.apiOneCat, {"idCat": idCat}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  allRecoveryPrds() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllRecoveryPrds, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  allSoldPrds() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllSoldPrds, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  allCats() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCats, {}).subscribe(
        (data: any) => {
          //console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  addCat(formData) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddCat, formData).subscribe(
        (res: any) => {
          //console.log(JSON.stringify(data));
          if (res) {
            if (res.response) {
              slv(res.response);
            } else {
              slv(res);
            }
          }
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
}
