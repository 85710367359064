<!-- <div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu> -->

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Liste des produits vendus</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les Commandes</div>
                    </div>
                </div>
                <div class="section-body">
                    <!-- <h2 class="section-title">Liste des commandes</h2> -->
                    <!-- <p class="section-lead">
              </p> -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card mb-0">
                                <div class="card-body">
                                    <!-- <ul class="nav nav-pills">
                        <li class="nav-item">
                            <a class="nav-link active">Tous <span
                                    class="badge badge-white">fffff</span></a>
                        </li>

                    </ul> -->
                                    <div class="row">
                                        <div class="col-11">
                                            <div class="text-right">
                                                <h3><i class="fa fa-circle m-r-5 text-info margin-right"></i>Nombre des
                                                    produits :
                                                    {{prdsSold.length}}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">

                        <div class="col-md-12">
                            <div class="card">
                                <!-- <div class="card-header">
                                    <h4>Dernières commandes</h4>
                                    <div class="card-header-action">
                                        <a href="#" class="btn btn-danger">View More <i
                                                class="fas fa-chevron-right"></i></a>
                                    </div>
                                </div> -->
                                <div class="card-body">
                                    <div class="table-responsive table-invoice">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th width="150">Photo</th>
                                                    <th>Nom du produit</th>
                                                    <th width="150">Référence</th>
                                                    <th>Entrée</th>
                                                    <th>Sortie</th>
                                                    <th>Quantité disponible</th>
                                                    <th>Prix d'achat</th>
                                                    <th>Prix</th>
                                                    <th width="150" class="text-center">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let prd of prdsSold">
                                                    <td>
                                                        <!-- <img class="img-responsive"
                                                            src="https://assets.marketbey.tn/produits/{{prd.photo_produit}}" alt="" /> -->

                                                        <div class="ingredient-img"
                                                            style="background-image: url('https://assets.marketbey.tn/produits/{{prd?.produnct?.photo_produit}}'); 
                                                                            background-position: center; background-repeat: no-repeat; 
                                                                            background-size: cover; width: 100px; height: 100px;">
                                                        </div>
                                                    </td>
                                                    <td>{{prd?.produnct?.nom_produit}}</td>
                                                    <td>{{prd?.produnct?.ref_interne_produit}}</td>
                                                    <td>{{prd?.sumEntree}}</td>
                                                    <td>{{prd?.sumSortie}}</td>
                                                    <td>{{prd?.produnct?.stock_reelle}}</td>
                                                    <td>{{prd?.produnct?.prix_achat_produit}}</td>
                                                    <td>{{prd?.produnct?.prix_produit}}</td>
                                                    <td class="text-right">
                                                        <a title="View" [routerLink]="['/view-produit']"
                                                        [queryParams]="{ prd: prd?.produnct?._id }" class="btn btn-warning btn-action trigger--fire-modal-1" >
                                                        <i class="fas fa-eye"></i></a>
                                                        <!-- <a title="Modifier" class="btn btn-success btn-action trigger--fire-modal-1"><i class="fas fa-pencil-alt"></i></a>
                                                        <a title="Supprimer" class="btn btn-danger btn-action trigger--fire-modal-1"><i class="fas fa-trash"></i></a> -->
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    <!-- </div>
</div> -->