<!-- <div id="app">
    <div class="main-wrapper">
      <app-top></app-top>
      <app-menu></app-menu> -->
  
      <!-- Main Content -->
      <div class="main-content">
        <section class="section">
          <div class="section-header">
            <h1>Liste des fournisseurs invalide</h1>
            <div class="section-header-button">
              <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
            </div>
            <div class="section-header-breadcrumb">
              <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
              <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
              <div class="breadcrumb-item">Toutes les Commandes</div>
            </div>
          </div>
          <div class="section-body">
            <!-- <h2 class="section-title">Liste des commandes</h2> -->
            <!-- <p class="section-lead">
              </p> -->
  
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <!-- <div class="card-header">
                      <h4>Liste des commandes</h4>
                    </div> -->
                  <div class="card-body">
  
                    <div class="clearfix mb-3"></div>
  
                    <!-- <div class="table-responsive"> -->
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th width="250">Fournisseur</th>
                          <th>Societe</th>
                          <th>Adresse</th>
                          <th>Num Tel</th>
                          <th width="230" class="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let comm of allCommercantsInvalid, let index= index">
                          <td>#{{index+1}}</td>
                          <td>
                            <div>
                              <div class="row">
                                <div class="col-xs-2 margin-right">
                                  <ngx-avatar class="rounded-circle" name="{{comm.nomresponsable_comercant}}">
                                  </ngx-avatar>
                                </div>
                                <div class="col-xs-8">
                                  <div class="media-title">
                                    {{comm.nomresponsable_comercant}}</div>
                                  <h5 class="text-muted m-t-0">{{comm.adminuser_comercant}}
                                  </h5>
                                </div>
  
                              </div>
                            </div>
  
                          </td>
                          <td>{{comm.societe_comercant}}</td>
                          <td>{{comm.adresse_comercant}}</td>
                          <td>{{comm.telresponsable_comercant}}</td>
                          <td class="text-center">
                            <button class="btn btn-success btn-sm btn-action trigger--fire-modal-1" 
                                    ><i class="fas fa-check" (click)="validateCommercant(comm)">
                                Valider fournisseur</i></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- <footer class="main-footer">
          <div class="footer-left">
            Copyright &copy; 2018 <div class="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval Azhar</a>
          </div>
          <div class="footer-right">
            2.3.0
          </div>
        </footer> -->
    <!-- </div>
  </div> -->