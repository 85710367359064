<!-- <div id="app">
  <div class="main-wrapper">
    <app-top></app-top>
    <app-menu></app-menu> -->

    <!-- Main Content -->
    <div class="main-content">
      <section class="section">
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="card card-statistic-2">
              <div class="card-stats">
                <div class="card-stats-title">Statistiques des commandes -
                  <div class="dropdown d-inline">
                    <a class="font-weight-600 dropdown-toggle" data-toggle="dropdown" href="#"
                      id="orders-month">{{tagPeriode}}</a>
                    <ul class="dropdown-menu dropdown-menu-sm">
                      <li class="dropdown-title">Période</li>
                      <li><a [ngClass]="(tagPeriode==='Aujourd\'hui')?'dropdown-item active':'dropdown-item'"
                            (click)="getStatisticsCmd('Aujourd\'hui')">Aujourd'hui</a></li>
                      <li><a [ngClass]="(tagPeriode==='Hier')?'dropdown-item active':'dropdown-item'"
                            (click)="getStatisticsCmd('Hier')">Hier</a></li>
                      <li><a [ngClass]="(tagPeriode==='Cette semaine')?'dropdown-item active':'dropdown-item'"
                            (click)="getStatisticsCmd('Cette semaine')">Cette semaine</a></li>
                      <li><a [ngClass]="(tagPeriode==='Ce mois')?'dropdown-item active':'dropdown-item'"
                            (click)="getStatisticsCmd('Ce mois')">Ce mois</a></li>
                      <!-- <li><a class="dropdown-item">Personnalisée</a></li> -->
                    </ul>
                  </div>
                </div>
                <div class="card-stats-items">
                  <div class="card-stats-item">
                    <div class="card-stats-item-count">{{statsCmds.allCmdsPending?.length}}</div>
                    <div class="card-stats-item-label">En attente</div>
                  </div>
                  <div class="card-stats-item">
                    <div class="card-stats-item-count">{{statsCmds.allCmdsPrepared?.length}}</div>
                    <div class="card-stats-item-label">Préparé</div>
                  </div>
                  <div class="card-stats-item">
                    <div class="card-stats-item-count">{{statsCmds.allCmdsLivred?.length}}</div>
                    <div class="card-stats-item-label">Livré</div>
                  </div>
                </div>
              </div>
              <div class="card-icon shadow-primary bg-primary">
                <i class="fas fa-archive"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Total commandes</h4>
                </div>
                <div class="card-body">
                  {{statsCmds.allCmds?.length}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="card card-statistic-2">
              <div class="card-chart">
                <canvas id="balancechart" height="80"></canvas>
              </div>
              <div class="card-icon shadow-primary bg-primary" *ngIf="isAdmin==true">
                <i class="fas fa-dollar-sign"></i>
              </div>
              <div class="card-wrap" *ngIf="isAdmin==true">
                <div class="card-header">
                  <h4>Chiffre d'affaire</h4>
                </div>
                <div class="card-body">
                  {{statsCmds?.turnover?.sumSortie | number:'0.3-3'}} TND
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="card card-statistic-2">
              <div class="card-chart">
                <canvas id="sales-chart" height="80"></canvas>
              </div>
              <div class="card-icon shadow-primary bg-primary">
                <i class="fas fa-shopping-bag"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Produits vendus</h4>
                </div>
                <div class="card-body">
                  {{nbrSoldPrd}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-6 col-12" [routerLink]="isAdmin||isValidator ? ['/commercants'] : ['/commandes']">
            <div class="card card-statistic-1">
              <div class="card-icon bg-primary">
                <i class="far fa-user"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Total <br>des fournisseurs</h4>
                </div>
                <div class="card-body">
                  {{nbrCommercant}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-12" [routerLink]="['/produits']">
            <div class="card card-statistic-1">
              <div class="card-icon bg-danger">
                <i class="far fa-newspaper"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Total <br>des produits</h4>
                </div>
                <div class="card-body">
                  {{nbrPrd}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-12" [routerLink]="['/recovery-produits']">
            <div class="card card-statistic-1">
              <div class="card-icon bg-warning">
                <i class="far fa-file"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Produits <br>en reptures de stock</h4>
                </div>
                <div class="card-body">
                  {{nbrRecoveryPrd}}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6 col-12" [routerLink]="['/sold-produits']">
            <div class="card card-statistic-1">
              <div class="card-icon bg-success">
                <i class="fas fa-circle"></i>
              </div>
              <div class="card-wrap">
                <div class="card-header">
                  <h4>Total <br>des produits vendus</h4>
                </div>
                <div class="card-body">
                  {{nbrSoldPrd}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-7">
            <div class="card">
              <div class="card-header">
                <h4 class="card-header-uppercase">Liste des catégories</h4>
                <!-- <div class="card-header-action">
                  <a href="#" class="btn btn-danger">View More <i class="fas fa-chevron-right"></i></a>
                </div> -->
              </div>
              <div class="card-body">
                <div class="table-responsive table-invoice">
                  <table class="table table-stripped table-hover table-bordred color-table primary-table">
                    <tbody>
                      <tr *ngFor="let cat of allCats">
                        <td>
                          <div class="ingredient-img" style="background-image: url('https://assets.marketbey.tn/categories/{{cat.photo_cat}}'); 
                                    background-position: center; background-repeat: no-repeat; 
                                    background-size: cover; width: 40px; height: 40px;">
                          </div>
                        </td>
                        <td>{{cat.label_cat}}</td>
                        <td>{{cat.nbrCmds}}</td>
                        <td class="text-right">
                          <a title="Modifier" class="btn btn-success btn-action trigger--fire-modal-1"><i
                              class="fas fa-pencil-alt"></i></a>
                          <a title="Supprimer" class="btn btn-danger btn-action trigger--fire-modal-1"><i
                              class="fas fa-trash"></i></a>
                          <a title="Modifier" class="btn btn-warning btn-action trigger--fire-modal-1"
                            [routerLink]="['/products-category']" [queryParams]="{ cat: cat._id }"><i
                              class="fas fa-trash">
                              Produits</i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="card gradient-bottom">
              <div class="card-header">
                <h4 class="card-header-uppercase">Meilleures produits</h4>
                <!-- <div class="card-header-action dropdown">
                  <a href="#" data-toggle="dropdown" class="btn btn-danger dropdown-toggle">Aujourd'hui</a>
                  <ul class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                    <li class="dropdown-title">Période</li>
                    <li><a href="#" class="dropdown-item active">Aujourd'hui</a></li>
                    <li><a href="#" class="dropdown-item">Cette semaine</a></li>
                    <li><a href="#" class="dropdown-item">Ce mois</a></li>
                    <li><a href="#" class="dropdown-item">Période</a></li>
                  </ul>
                </div> -->
              </div>
              <div class="card-body" id="top-5-scroll">
                <ul class="list-unstyled list-unstyled-border">
                  <li class="media" *ngFor="let prd of soldProduits">
                    <div class="mr-3 rounded"
                      style="background-image: url('https://assets.marketbey.tn/produits/{{prd.produnct.photo_produit}}'); 
                                                                            background-position: center; background-repeat: no-repeat; 
                                                                            background-size: cover; width: 55px; height: 55px;">
                    </div>
                    <!-- <img class="mr-3 rounded" width="55" src="../assets/img/products/product-3-50.png" alt="product"> -->
                    <div class="media-body">
                      <div class="float-right">
                        <!-- <div class="font-weight-600 text-muted text-small">{{prd.sumEntree}} Entree</div> -->
                        <div class="font-weight-600 text-muted text-small">{{prd.sumSortie}} Sortie</div>
                      </div>
                      <div class="media-title">{{prd.produnct.nom_produit}}</div>
                      <div class="mt-1">
                        <div class="budget-price">
                          <div class="budget-price-square bg-primary" data-width="64%"></div>
                          <div class="budget-price-label">{{prd.produnct.prix_produit}} DT</div>
                        </div>
                        <div class="budget-price">
                          <div class="budget-price-square bg-danger" data-width="43%"></div>
                          <div class="budget-price-label">{{prd.produnct.prix_achat_produit}} DT</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <!-- <li class="media">
                    <img class="mr-3 rounded" width="55" src="../assets/img/products/product-4-50.png" alt="product">
                    <div class="media-body">
                      <div class="float-right">
                        <div class="font-weight-600 text-muted text-small">67 Sales</div>
                      </div>
                      <div class="media-title">iBook Pro 2018</div>
                      <div class="mt-1">
                        <div class="budget-price">
                          <div class="budget-price-square bg-primary" data-width="84%"></div>
                          <div class="budget-price-label">$107,133</div>
                        </div>
                        <div class="budget-price">
                          <div class="budget-price-square bg-danger" data-width="60%"></div>
                          <div class="budget-price-label">$91,455</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="media">
                    <img class="mr-3 rounded" width="55" src="../assets/img/products/product-1-50.png" alt="product">
                    <div class="media-body">
                      <div class="float-right">
                        <div class="font-weight-600 text-muted text-small">63 Sales</div>
                      </div>
                      <div class="media-title">Headphone Blitz</div>
                      <div class="mt-1">
                        <div class="budget-price">
                          <div class="budget-price-square bg-primary" data-width="34%"></div>
                          <div class="budget-price-label">$3,717</div>
                        </div>
                        <div class="budget-price">
                          <div class="budget-price-square bg-danger" data-width="28%"></div>
                          <div class="budget-price-label">$2,835</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="media">
                    <img class="mr-3 rounded" width="55" src="../assets/img/products/product-3-50.png" alt="product">
                    <div class="media-body">
                      <div class="float-right">
                        <div class="font-weight-600 text-muted text-small">28 Sales</div>
                      </div>
                      <div class="media-title">oPhone X Lite</div>
                      <div class="mt-1">
                        <div class="budget-price">
                          <div class="budget-price-square bg-primary" data-width="45%"></div>
                          <div class="budget-price-label">$13,972</div>
                        </div>
                        <div class="budget-price">
                          <div class="budget-price-square bg-danger" data-width="30%"></div>
                          <div class="budget-price-label">$9,660</div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="media">
                    <img class="mr-3 rounded" width="55" src="../assets/img/products/product-5-50.png" alt="product">
                    <div class="media-body">
                      <div class="float-right">
                        <div class="font-weight-600 text-muted text-small">19 Sales</div>
                      </div>
                      <div class="media-title">Old Camera</div>
                      <div class="mt-1">
                        <div class="budget-price">
                          <div class="budget-price-square bg-primary" data-width="35%"></div>
                          <div class="budget-price-label">$7,391</div>
                        </div>
                        <div class="budget-price">
                          <div class="budget-price-square bg-danger" data-width="28%"></div>
                          <div class="budget-price-label">$5,472</div>
                        </div>
                      </div>
                    </div>
                  </li> -->
                </ul>
              </div>
              <div class="card-footer pt-3 d-flex justify-content-center">
                <div class="budget-price justify-content-center">
                  <div class="budget-price-square bg-primary" data-width="20"></div>
                  <div class="budget-price-label">Prix de vente</div>
                </div>
                <div class="budget-price justify-content-center">
                  <div class="budget-price-square bg-danger" data-width="20"></div>
                  <div class="budget-price-label">Prix d'achat</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="card">
              <div class="card-header">
                <h4>Commandes finalisées / Abondonnées</h4>
              </div>
              <div class="card-body">
                <canvas id="myChart" height="158"></canvas>
              </div>
            </div>
            <!-- <div class="card">
              <div class="card-header">
                <h4>Dernières commandes</h4>
                <div class="card-header-action">
                  <a href="#" class="btn btn-danger">View More <i class="fas fa-chevron-right"></i></a>
                </div>
              </div>
              <div class="card-body p-0">
                <div class="table-responsive table-invoice">
                  <table class="table table-striped">
                    <tr>
                      <th>Invoice ID</th>
                      <th>Customer</th>
                      <th>Status</th>
                      <th>Due Date</th>
                      <th>Action</th>
                    </tr>
                    <tr>
                      <td><a href="#">INV-87239</a></td>
                      <td class="font-weight-600">Kusnadi</td>
                      <td>
                        <div class="badge badge-warning">Unpaid</div>
                      </td>
                      <td>July 19, 2018</td>
                      <td>
                        <a href="#" class="btn btn-primary">Detail</a>
                      </td>
                    </tr>
                    <tr>
                      <td><a href="#">INV-48574</a></td>
                      <td class="font-weight-600">Hasan Basri</td>
                      <td>
                        <div class="badge badge-success">Paid</div>
                      </td>
                      <td>July 21, 2018</td>
                      <td>
                        <a href="#" class="btn btn-primary">Detail</a>
                      </td>
                    </tr>
                    <tr>
                      <td><a href="#">INV-76824</a></td>
                      <td class="font-weight-600">Muhamad Nuruzzaki</td>
                      <td>
                        <div class="badge badge-warning">Unpaid</div>
                      </td>
                      <td>July 22, 2018</td>
                      <td>
                        <a href="#" class="btn btn-primary">Detail</a>
                      </td>
                    </tr>
                    <tr>
                      <td><a href="#">INV-84990</a></td>
                      <td class="font-weight-600">Agung Ardiansyah</td>
                      <td>
                        <div class="badge badge-warning">Unpaid</div>
                      </td>
                      <td>July 22, 2018</td>
                      <td>
                        <a href="#" class="btn btn-primary">Detail</a>
                      </td>
                    </tr>
                    <tr>
                      <td><a href="#">INV-87320</a></td>
                      <td class="font-weight-600">Ardian Rahardiansyah</td>
                      <td>
                        <div class="badge badge-success">Paid</div>
                      </td>
                      <td>July 28, 2018</td>
                      <td>
                        <a href="#" class="btn btn-primary">Detail</a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div> -->
          </div>
          <div class="col-md-4">
            <div class="card card-hero">
              <div class="card-header">
                <div class="card-icon">
                  <i class="far fa-question-circle"></i>
                </div>
                <h4>14</h4>
                <div class="card-description">Livraisons</div>
              </div>
              <div class="card-body p-0">
                <div class="tickets-list">
                  <a href="#" class="ticket-item">
                    <div class="ticket-title">
                      <h4>Commande pris en charge</h4>
                    </div>
                    <div class="ticket-info">
                      <div>Mohamed Ali</div>
                      <div class="bullet"></div>
                      <div class="text-primary">Il ya une minute</div>
                    </div>
                  </a>
                  <a href="#" class="ticket-item">
                    <div class="ticket-title">
                      <h4>Commande Chez Point collect A</h4>
                    </div>
                    <div class="ticket-info">
                      <div>Point collect A</div>
                      <div class="bullet"></div>
                      <div>Il y a 1 heure</div>
                    </div>
                  </a>
                  <a href="#" class="ticket-item">
                    <div class="ticket-title">
                      <h4>Commande livré au client Foulen</h4>
                    </div>
                    <div class="ticket-info">
                      <div>Foulen ben foulen</div>
                      <div class="bullet"></div>
                      <div>Il ya 3 heure </div>
                    </div>
                  </a>
                  <a routerLink="history_cmd" class="ticket-item ticket-more">
                    Voir tous<i class="fas fa-chevron-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- <app-footer></app-footer>
  </div>
</div> -->