import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-teams-mb',
  templateUrl: './teams-mb.component.html',
  styleUrls: ['./teams-mb.component.css']
})
export class TeamsMBComponent implements OnInit {

  isLoading: Boolean = true
  allTeams: any = []

  constructor(
    private usersService: UsersService,
  ) { }

  ngOnInit(): void {
    this.listTeams();
  }

  async listTeams() {
    await this.usersService.allTeams().then((res: any) => {
      console.log("allTeams")
      console.log(res)
      if (res) {
        this.isLoading = false;
        this.allTeams = res;
      }
    });
  }

}
