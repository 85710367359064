import { Injectable } from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommandesService {
  constructor(private http: HttpClient) { }
  
  getLabeledStatus() {
    // ["pending", "validated", "preparing", "submited", "transported", "arrived", "delivered", "returned", "refused", "canceled"],
    return new Promise((slv) => {
      this.http.post(environment.apiStatus, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmds(page) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmds, {
        page: page
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsWebsite() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsWebsite, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsFacebook() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsFacebook, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  cmdsPretes() {
    return new Promise((slv) => {
      this.http.post(environment.listeCmdsPretes, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  statisticsCmds(startDate, endDate) {
    return new Promise((slv) => {
      this.http.post(environment.apiStatsCmds, {
        startDate,
        endDate
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  getOneCMD(cmd) {
    return new Promise((slv) => {
      this.http.post(environment.apiOneCmdByID, { cmd }).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  updateCmdStatus(id, status) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiUpdateCmdStatus, {
          cmd: id,
          newstatus: status,
        })
        .subscribe(
          (data: any) => {
            console.log("data update status : ");
            console.log(JSON.stringify(data));
            if (data && data.response) {
              slv(true);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  addComment(cmd, idUser, nameUser, comment) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddComment, {
        cmd,
        idUser,
        nameUser,
        comment
      }).subscribe(
        (data: any) => {
          console.log(data);
          if (data && data.response && data.data) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  searchCmds(ref, status, ville, numero, datein, datefin, idTeam=null) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiSearchCmds, {
          ref: ref,
          status: status,
          ville: ville,
          numtel: numero,
          startDate: datein,
          endDate: datefin,
          idTeam: idTeam
        })
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  affectTransport(cmd, user) {
    return new Promise((slv) => {
      this.http.post(environment.apiAffectTransport, { cmd: cmd, user: user }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    })
  }

  // affectTransportxx(cmd, idd, name, date) {
  //   return new Promise((slv) => {
  //     this.http.post(environment.apiAffectTransport, { cmd: cmd, idd: idd, name: name, date: date }).subscribe(
  //       (data: any) => {
  //         if (data && data.response && data.data) {
  //           slv(data.data);
  //         } else slv(false);
  //       },
  //       (error) => {
  //         slv(false);
  //       }
  //     );
  //   })
  // }

  returnCmd(cmd) {
    return new Promise((slv) => {
      this.http.post(environment.apiReturnCmd, { cmd: cmd }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    })
  }

  addMvtStock(idPrd, type, env, comments, quantity) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddMvtStock, { 
        produit: idPrd, 
        typeMouvement: type, 
        environAction: env, 
        comments: comments,
        quantity: quantity 
      }).subscribe(
        (data: any) => {
          if (data && data.response) {
            slv(data.response);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    })
  }

  allReclamations(status) {
    return new Promise((slv) => {
      this.http.post(environment.apiReclamations, { "status_reclamation": status }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  addReclamation(idCmd, raison, comment) {
    let rec = {
      "commande": idCmd,
      "raison_reclamation": raison,
      "comment": comment
    }
    return new Promise((slv) => {
      this.http.post(environment.apiAddReclamation, rec).subscribe(
        (data: any) => {
          console.log("data")
          console.log(data)
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  validateReclamation(idRec, solution, comment) {
    let rec = {
      "rec": idRec,
      "solution_reclamation": solution,
      "comment": comment
    }
    return new Promise((slv) => {
      this.http.post(environment.apiValidateReclamation, rec).subscribe(
        (data: any) => {
          console.log("data")
          console.log(data)
          if (data && data.response) {
            slv(true);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getOneReclamation(rec) {
    return new Promise((slv) => {
      this.http.get(environment.apiOneReclamation+"/"+rec).subscribe(
        (data: any) => {
          console.log("JSON.stringify(data) get one recla");
          console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  listCmdsByPrdt(ref, datein, datefin, ville) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiListCmdsByPrdt, {
          refPrdt: ref,
          startDate: datein,
          endDate: datefin,
          ville: ville
        })
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  inventaireCategories(category, datein, datefin) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiInventaireCat, {
          idCat: category,
          startDate: datein,
          endDate: datefin
        })
        .subscribe(
          (data: any) => {
            console.log("inventaireCategories")
            console.log(data);
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

}
