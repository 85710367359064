import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import jwt_decode from "jwt-decode";
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {

  user: any = null

  constructor(private httpClient: HttpClient) { }

  getIdUser() {
    var token = localStorage.getItem('token_mb')
    //console.log("tokennnnnnnnn : ");

    if (token && token != null) {
      var decoded = jwt_decode(token);
      //console.log("idddddddddddddddddduser")
      //console.log(decoded["id"])
      return decoded["id"]
    } else return null
  }

  getUserInfos() {
    var token = localStorage.getItem('token_mb')

    //console.log("tokennnnnnnnn : ");
    //console.log(token);

    return new Promise((solve) => {
      if (token && token != null) {
        var decoded = jwt_decode(token);

        this.httpClient.post(environment.apiInfosUser, { token: token, teamid: decoded["id"] }).subscribe((res: any) => {
          // console.log("hajerrrrrrrrrrrrrrrrrrrrrr get info user");
          // console.log(res);
          if (res && res.response && res.data) {
            solve(res.data);
          } else
            solve(true);
        },
          (error) => {
            // console.log("Cmd error")
            // console.log(error)
            solve(false);
          })
      } else {
        solve(false);
      }
    });
  }

  isConnected() {
    var token = localStorage.getItem('token_mb')
    if (token)
      return true;

    return false
  }

  async hasRoles(roles) {
    // this.getUserInfos().then(user => {
    //   if (user) {
    //     this.user = user
    //   } else {
    //     this.user = null
    //   }

    // })
    const currentUser:any = await this.getUserInfos()
    if(!roles) {
      return true
    }
    if (currentUser && roles) {
      const filteredItems = roles.filter(x => currentUser.profiles.some(y => y == x));
      if (filteredItems && filteredItems.length <= 0) {
        // role not authorised so redirect to home page
        // this.router.navigate(['/dashboard']);

        return false;
      } else {
        return true
      }
    } else {
      return false;
    }
  }

  async isAdmin() {
    this.user = await this.getUserInfos()
    if (this.user.profiles != null && this.user.profiles.find(profile => profile === 'admin')) {
      return true
    }
    return false
  }

  async isSimpleUser() {
    this.user = await this.getUserInfos()
    if (this.user.profiles != null && this.user.profiles.find(profile => profile === 'simple_user')) {
      return true
    }
    return false
  }

  async isStock() {
    this.user = await this.getUserInfos()
    if (this.user.profiles != null && this.user.profiles.find(profile => profile === 'stock')) {
      return true
    }
    return false
  }

  async isValidator() {
    this.user = await this.getUserInfos()
    if (this.user.profiles != null && this.user.profiles.find(profile => profile === 'validator')) {
      return true
    }
    return false
  }

  saveUserCreds(usercreds) {
    localStorage.setItem('token_mb', JSON.stringify(usercreds.token));
  }

  login(creds) {
    // console.log("creds");
    // console.log(creds);
    return new Promise((solve) => {
      this.httpClient.post(environment.apiUsersAuth, creds).subscribe((res: any) => {
        //console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        //console.log(res);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            solve(true);
          } else {
            solve(res);
          }
        }
      })
    });
  }

  register(creds) {
    //console.log("source");
    //console.log(source);
    return new Promise((solve) => {
      this.httpClient.post(environment.apiUsersRegister, creds).subscribe((res: any) => {
        //console.log("FN register service");
        //console.log(res);
        if (res) {
          if (res.response && res.data) {
            this.saveUserCreds(res.data);
            solve(true);
          } else {
            solve(res);
          }
        }
      })
    });
  }

  deconnexion() {
    localStorage.removeItem('token_mb');
  }

  allTeams() {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiAllTeams, {}).subscribe((res: any) => {
        //console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        //console.log(res);
        if (res) {
          if (res.response && res.data) {
            solve(res.data);
          } else {
            solve(res);
          }
        }
      })
    });
  }
  addUserMB(creds) {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiAddUserMB, creds).subscribe((res: any) => {
        //console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        //console.log(res);
        if (res) {
          if (res.response) {
            solve(res.response);
          } else {
            solve(res);
          }
        }
      })
    });
  }

  allUsers() {
    return new Promise((solve) => {
      this.httpClient.post(environment.apiAllUsers, {}).subscribe((res: any) => {
        //console.log("hajerrrrrrrrrrrrrrrrrrrrrr");
        //console.log(res);
        if (res) {
          if (res.response && res.data) {
            solve(res.data);
          } else {
            solve(res);
          }
        }
      })
    });
  }
}
