<!-- <div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu> -->

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Nouveau Code promo</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Ajout code Promo</div>
                    </div>
                </div>
                <div class="section-body">
                    <!-- <h2 class="section-title">Liste des commandes</h2> -->
                    <!-- <p class="section-lead">
             
                    </p> -->

                    <div class="row">
                        <div class="col-12">
                            <!-- <div class="card mb-0">
                                <div class="card-body">
                                    <ul class="nav nav-pills float-right">
                                        <li class="nav-item">
                                            <a [ngClass]="(tagActive==='ALL')?'nav-link active':'nav-link'"
                                                (click)="listCodesPromo('ALL')">Tous <span
                                                    [ngClass]="(tagActive==='ALL')?'badge badge-white':'badge badge-primary'">0</span></a>
                                        </li>
                                        <li class="nav-item">
                                            <a [ngClass]="(tagActive==='ACTIF')?'nav-link active':'nav-link'"
                                                (click)="listCodesPromoActif('ACTIF')">Actifs <span
                                                    [ngClass]="(tagActive==='ACTIF')?'badge badge-white':'badge badge-primary'">0</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">
                                    <!-- <div class="float-left"> -->
                                    <div class="div-form">
                                        <form class="needs-validation" action="" method="post" onsubmit="return false;"
                                            (onsubmit)="addCodePromo();">
                                            <div class="form-group">
                                                <label for="code">Code</label>
                                                <input type="text" class="form-control" placeholder="Code Promo"
                                                    name="code" id="code" [(ngModel)]="coupon.code" tabindex="1"
                                                    required maxlength="8">
                                            </div>

                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="amount">Montant</label>
                                                        <input type="number" class="form-control" placeholder="Montant"
                                                            name="amount" id="amount" [(ngModel)]="coupon.amount"
                                                            tabindex="1" required>
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="reduction">Type de reduction</label>
                                                        <select class="form-control selectric" name="type_reduction"
                                                            id="type_reduction"
                                                            (change)="selectChangeHandler($event)"
                                                            [(ngModel)]="coupon.type_reduction">
                                                            <option *ngFor="let reduction of reductions"
                                                                [ngValue]="reduction"
                                                                [ngModelOptions]="{standalone: true}">{{reduction}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="row">
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <p for="is_reusable">Réutilisable</p>
                                                        <input type="radio" name="is_reusable" value="true"
                                                            id="is_reusable" [(ngModel)]="coupon.is_reusable">
                                                        <label for="is_reusable"> Oui</label>
                                                        <br />
                                                        <input type="radio" name="is_reusable" value="false"
                                                            id="is_reusable" [(ngModel)]="coupon.is_reusable">
                                                        <label for="is_reusable"> Non</label>
                                                    </div>
                                                </div>

                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <p for="is_used">utilisé</p>
                                                        <input type="radio" name="is_used" value="true" id="is_used"
                                                            [(ngModel)]="coupon.is_used">
                                                        <label for="is_used"> Oui</label>
                                                        <br />
                                                        <input type="radio" name="is_used" value="false" id="is_used"
                                                            [(ngModel)]="coupon.is_used">
                                                        <label for="is_used"> Non</label>
                                                    </div>
                                                </div>

                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <p for="is_disabled">Disactivé</p>
                                                        <input type="radio" name="is_disabled" value="true"
                                                            id="is_disabled" [(ngModel)]="coupon.is_disabled">
                                                        <label for="is_disabled"> Oui</label>
                                                        <br />
                                                        <input type="radio" name="is_disabled" value="false"
                                                            id="is_disabled" [(ngModel)]="coupon.is_disabled">
                                                        <label for="is_disabled"> Non</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="date_used">Date Fin</label>
                                                        <input type="date" class="form-control" name="date_used"
                                                            id="date_used" tabindex="1" [(ngModel)]="coupon.date_used">
                                                    </div>
                                                </div>

                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label for="nbr_reply">Nombre d'utilisation</label>
                                                        <input type="number" class="form-control" name="nbr_reply"
                                                            id="nbr_reply" tabindex="1" [(ngModel)]="coupon.nbr_reply">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="float-right">
                                                <button type="submit" class="btn btn-primary btn-lg btn-icon icon-righ"
                                                    id="submit" tabindex="4" (click)="addCodePromo();">
                                                    Ajouter
                                                </button>
                                            </div>

                                        </form>
                                    </div>

                                    <!-- </div> -->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- <footer class="main-footer">
        <div class="footer-left">
          Copyright &copy; 2018 <div class="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval Azhar</a>
        </div>
        <div class="footer-right">
          2.3.0
        </div>
      </footer> -->
    <!-- </div>
</div> -->