import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommercantsService {

  constructor(private http: HttpClient) { }

  getComercant(idCom) {
    return new Promise((solve) => {
      this.http.post(environment.apiGetCommercant, {idCom: idCom}).subscribe((res: any) => {
        if (res && res.response) {
          solve(res.data);
        } else {
          solve(false);
        }
      })
    });
  }

  addCommercant(creds) {
    return new Promise((solve) => {
      this.http.post(environment.apiAddCommercant, creds).subscribe((res: any) => {
        if (res) {
          if (res.response) {
            solve(res.response);
          } else {
            solve(res);
          }
        }
      })
    });
  }

  addAccountCommercant(creds) {
    return new Promise((solve) => {
      this.http.post(environment.apiAddAccountComercant, creds).subscribe((res: any) => {
        if (res) {
          if (res.response) {
            solve(res);
          } else {
            solve(false);
          }
        }
      })
    });
  }
  allAccountCommercant(idCommercant) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllAccountComercant, {commercant: idCommercant}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  updateCommercant(creds) {
    return new Promise((solve) => {
      this.http.post(environment.apiUpdateCommercant, creds).subscribe((res: any) => {
        if (res) {
          if (res.response) {
            solve(res.response);
          } else {
            solve(res);
          }
        }
      })
    });
  }

  allCommercants() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCommercant, {}).subscribe(
        (data: any) => {
          //console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  allInvalidCommercants() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllInvalidCommercant, {}).subscribe(
        (data: any) => {
          //console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  allPrdsCommercant(idComm) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllPrdsCommercant, {"idCommercant": idComm}).subscribe(
        (data: any) => {
          //console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  totalStockComercant(idComm) {
    return new Promise((slv) => {
      this.http.post(environment.apiTotalStockCommercant, {"idCommercant": idComm}).subscribe(
        (data: any) => {
          //console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  statisticComercant(idComm) {
    return new Promise((slv) => {
      this.http.post(environment.apiStatisticCommercant, {"idCommercant": idComm}).subscribe(
        (data: any) => {
          //console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
  allCmdsCommercant(idComm) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsCommercant, {"idCommercant": idComm}).subscribe(
        (data: any) => {
          //console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
}
