import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommandesService } from '../../services/commandes/commandes.service';
import { NotifierService } from 'angular-notifier';
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-details-commande',
  templateUrl: './details-commande.component.html',
  styleUrls: ['./details-commande.component.css'],
})
export class DetailsCommandeComponent implements OnInit {
  @ViewChild('commande', { static: false }) commandeCtn: ElementRef;

  cmd: any = {
    facturation: {},
    livraison: {},
    basket: {},
    livraison_cmd: { pcollect_details: {} },
    bulk_session_datas_cmd : {},
    appel_client: {},
    statusCmds: {}
  };
  // elementType: 'url' | 'canvas' | 'img' = 'url';
  // imgdataURL: any;
  isPrinting: boolean = false;
  statusCmds: any = {};
  baseURL: String = environment.baseImagesURL;
  cmdID: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private CmdsServices: CommandesService,
    private notif: NotifierService
  ) { }

  ngOnInit(): void {
    this.CmdsServices.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchCmd();
      // console.log(this.cmd);
    });
  }
  _fetchCmd() {
    // console.log(cid);
    this.CmdsServices.getOneCMD(this.cmdID).then((d) => {
      if (d) {
        // console.log("dddddddddddddd")
        // console.log(d)
        this.cmd = d;
      } else {
        alert('Aucune commande valide');
        location.href = '/commandes';
      }
    });
  }
  //  (click)="affect(cmd.transport_topcollect.transporter_id,cmd.transport_topcollect.transporter_name,cmd.transport_topcollect.date_accept)"
  //  (click)="affect(cmd.pcollect_transport.pcollect_id,cmd.pcollect_transport.pcollect_name,cmd.pcollect_transport.date_accept)"
  // affect(idd, name, date) {
  //   this.CmdsServices.affectTransportxx(this.cmdID, idd, name, date).then((d) => {
  //     console.log("dddddddddddfffgghhhdddd")
  //     console.log(d)
  //     if (d) {
  //       console.log("dddddddddddddd")
  //       console.log(d)
  //       this.cmd = d;
  //     } else {
  //       // alert('Aucune commande valide');
  //       // location.href = '/commandes';
  //     }
  //   });
  // }
  generatePDF() {
    // const docpdf = new jsPDF();

    // const specialElementHandlers = {
    //   '#commande': function (element, renderer) {
    //     return true;
    //   },
    // };

    // const content = this.commandeCtn.nativeElement;

    // docpdf.fromHTML(content.innerHTML, 15, 15, {
    //   width: 1600,
    //   elementHandlers: specialElementHandlers,
    // });

    // docpdf.save('export_commande_' + this.cmd.ref_cmd + '.pdf');

    var divContents = document.getElementById("commande").innerHTML;
    var a = window.open('', '', 'height=860, width=1600');
    a.document.write('<html><head> ');
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/style.css" />'
    );
    a.document.write(
      '<link rel="stylesheet" type="text/css" href="/assets/css/components.css" />'
    );
    a.document.write(
      '</head><body style="background:#FFFFFF" ><div  class="container invoice">'
    );

    a.document.write(divContents);
    a.document.write('</div></body></html>');
    a.document.close();
    a.print();





    // this.isPrinting = true;
    // var data = document.getElementById('commande');
    // var a = window.open(
    //   '',
    //   '',
    //   'height=' + screen.height + ', width=' + screen.width
    // );
    // a.document.write('<html><head> ');
    // a.document.write(
    //   '<link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />'
    // );
    // a.document.write(
    //   '<link rel="stylesheet" type="text/css" href="/assets/css/style.css" />'
    // );
    // a.document.write(
    //   '<link rel="stylesheet" type="text/css" href="/assets/css/components.css" />'
    // );
    // a.document.write(
    //   '</head><body style="background:#FFFFFF" ><div  class="container invoice">'
    // );
    // a.document.write("<div class='invoice-print'>" + data.innerHTML + '</div>');
    // a.document.write('</div></body></html>');
    // // a.document.close();
    // setTimeout(() => {
    //   a.print();
    //   a.close();
    // }, 800);





    // html2canvas(data, {
    //   allowTaint: false,
    //   useCORS: false,
    //   scale: 0.8,
    //   height: data.offsetHeight,
    //   width: data.offsetWidth,
    //   scrollX: 0,
    //   scrollY: 0,
    //   ignoreElements: (node) => {
    //     return node.nodeName === 'qrcode';
    //   },
    // }).then((canvas) => {
    //   // console.log(canvas.width + ' // ' + canvas.height);
    //   // Few necessary setting options
    //   var imgWidth = canvas.width;
    //   // var pageHeight = 800;
    //   var imgHeight = canvas.height; //(canvas.height * imgWidth) / canvas.width;
    //   // var heightLeft = imgHeight;

    //   const contentDataURL = canvas.toDataURL('image/png');
    //   this.imgdataURL = contentDataURL;
    //   let pdf = new jsPDF('p', 'mm', 'A4'); // A4 size page of PDF
    //   var position = 0;
    //   this.isPrinting = false;

    //   // pdf.addImage(
    //   //   contentDataURL,
    //   //   'PNG',
    //   //   0,
    //   //   position,
    //   //   canvas.width,
    //   //   canvas.height
    //   // );
    //   // pdf.save('commande_' + this.cmd.ref_cmd + '.pdf'); // Generated PDF
    // });
  }

  updateStatusCmd(status) {
    if (confirm('Etes vous sûre?'))
      this.CmdsServices.updateCmdStatus(this.cmd._id, status).then((res) => {
        if (res) {
          this.notif.notify('success', 'Commande mis à jour avec succès');
          this._fetchCmd();
        } else this.notif.notify('error', "Une erreur s'est produite");
      });
  }

}
