<!-- Main Content -->
<div class="main-content">
    <section class="section">
        <div class="section-header">
            <h1>Liste des categories</h1>
            <div class="section-header-button">
                <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
            </div>
            <div class="section-header-breadcrumb">
                <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
                <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                <div class="breadcrumb-item">Toutes les Commandes</div>
            </div>
        </div>
        <div class="section-body">
            <!-- <h2 class="section-title">Liste des commandes</h2> -->
            <!-- <p class="section-lead">
              </p> -->

            <div *ngIf="isLoading; else elseAllCats">
                <div class="row">
                    <div class="col-12">
                        <div class="mb-0 card">
                            <div class="card-body text-center">
                                <div class="spinner-container blue-spinner">
                                    <mat-progress-spinner mode="indeterminate" [diameter]="40"
                                        [strokeWidth]="5"></mat-progress-spinner>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #elseAllCats>
                <div class="row">
                    <div class="col-12">
                        <div class="card mb-0">
                            <div class="card-body">
                                <!-- <ul class="nav nav-pills">
                                <li class="nav-item">
                                    <a class="nav-link active">Tous <span
                                            class="badge badge-white">fffff</span></a>
                                </li>

                            </ul> -->
                                <div class="row">
                                    <div class="col-11">
                                        <div class="text-right">
                                            <h3><i class="fa fa-circle m-r-5 text-info margin-right"></i>Nombre des
                                                categories :
                                                {{allCats.length}}
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-header-uppercase">Liste des catégories</h4>
                                <!-- <div class="card-header-action">
                                    <a href="#" class="btn btn-danger">View More <i class="fas fa-chevron-right"></i></a>
                                  </div> -->
                            </div>
                            <div class="card-body">
                                <div class="table-responsive table-invoice">
                                    <table
                                        class="table table-stripped table-hover table-bordred color-table primary-table">
                                        <thead>
                                            <tr>
                                                <th width="70">#</th>
                                                <th width="600">Nom de la catégorie</th>
                                                <th>Nombre des produits</th>
                                                <th>Nombre des commandes</th>
                                                <th width="270" class="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let cat of allCats">
                                                <td>
                                                    <div class="ingredient-img" style="background-image: url('https://assets.marketbey.tn/categories/{{cat.photo_cat}}'); 
                                                      background-position: center; background-repeat: no-repeat; 
                                                      background-size: cover; width: 40px; height: 40px;">
                                                    </div>
                                                </td>
                                                <td>{{cat.label_cat}}</td>
                                                <td>{{cat.nbrPrds}}</td>
                                                <td>{{cat.nbrCmds}}</td>
                                                <td class="text-right">
                                                    <a title="Modifier"
                                                        class="btn btn-success btn-action trigger--fire-modal-1"><i
                                                            class="fas fa-pencil-alt"></i></a>
                                                    <a title="Supprimer"
                                                        class="btn btn-danger btn-action trigger--fire-modal-1"><i
                                                            class="fas fa-trash"></i></a>
                                                    <a title="Modifier"
                                                        class="btn btn-warning btn-action trigger--fire-modal-1"
                                                        [routerLink]="['/products-category']"
                                                        [queryParams]="{ cat: cat._id }"><i class="fas fa-trash">
                                                            Prds</i></a>
                                                    <a title="Modifier"
                                                        class="btn btn-primary btn-action trigger--fire-modal-1"
                                                        [routerLink]="['/commandes-category']"
                                                        [queryParams]="{ cat: cat._id }"><i class="fas fa-trash">
                                                            Cmds</i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-template>
        </div>
    </section>
</div>