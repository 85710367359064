<!-- <div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu> -->

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Nouveau utilisateur MB</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les Commandes</div>
                    </div>
                </div>
                <div class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card author-box card-primary">
                                <div class="card-header">
                                    <h4>Ajouter un utilisateur MB</h4>
                                </div>
                                <div class="card-body">
                                    <div class="card-body">
                                        <form>
                                            <div class="row">
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Prénom</label>
                                                        <input type="text" class="form-control" name="first_name"
                                                            ngModel #first_name>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="first_name.value==''">Entrez le prénom de
                                                            l'utilisateur
                                                        </mat-error>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Email</label>
                                                        <input type="text" class="form-control" name="email_user"
                                                            ngModel #email_user>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="email_user.value==''">Entrez l'email de l'utilisateur
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Nom</label>
                                                        <input type="text" class="form-control" name="last_name" ngModel
                                                            #last_name>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="last_name.value==''">Entrez le prénom de
                                                            l'utilisateur
                                                        </mat-error>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Num tel</label>
                                                        <input type="number" class="form-control" name="tel_user"
                                                            ngModel #tel_user>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="tel_user.value==''">Entrez num tel de l'utilisateur
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="profiles">Roles</label>
                                                        <select class="form-control" name="profiles"
                                                            [(ngModel)]="profiles" multiple style="height:180px">
                                                            <!-- <option value="-"></option> -->
                                                            <option *ngFor="let profile of allProfiles"
                                                                [ngValue]="profile">
                                                                {{profile}}</option>
                                                        </select>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="profiles.length==0">
                                                            Selectionnez au mois un profile
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-6 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Mot de passe</label>
                                                        <input type="password" class="form-control" name="password_user"
                                                            ngModel #password_user>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="password_user.value==''">Entrez mot de passe
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-6 col-md-6 col-lg-6">
                                                    <div class="form-group">
                                                        <label>Confirmer mot de passe</label>
                                                        <input type="password" class="form-control" name="confirme_password_user"
                                                            ngModel #confirme_password_user>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="confirme_password_user.value=='' || 
                                                            confirme_password_user.value!=password_user.value">Confirmer mot de passe
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <button type="button"
                                                    (click)="addUserMB(first_name, email_user, last_name, tel_user, 
                                                    password_user, confirme_password_user)"
                                                    class="btn btn-primary btn-lg btn-block">
                                                    Ajouter Utilisateur MB
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    <!-- </div>
</div> -->