import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CouponsService {

  constructor(private http: HttpClient) { }

  addCoupon(coupon) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddCodePromo, coupon).subscribe(
        (data: any) => {
          console.log("add coupon data")
          console.log(data)
          if (data && data.response) {
            slv(data.response);
          } else slv(false);
        },
        (error) => {
          console.log("add coupon error")
          console.log(error)
          slv(false);
        }
      );
    });
  }

  listCoupons() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCodePromos, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }
}
