import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products/products.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-recovery-produits',
  templateUrl: './recovery-produits.component.html',
  styleUrls: ['./recovery-produits.component.css']
})
export class RecoveryProduitsComponent implements OnInit {

  listRecoveryPrds: any = []

  isAdmin: Boolean = false
  isStock: Boolean = false
  isValidator: Boolean = false

  constructor(private PrdsServices: ProductsService,
    private authService: UsersService) {
    this.isUserAdmin()
    this.isUserStock()
    this.isUserValidator() }

  ngOnInit(): void {
    this.listRecoveryProduits()
  }

  async listRecoveryProduits() {
    await this.PrdsServices.allRecoveryPrds().then((res: any) => {
      if (res) {
        this.listRecoveryPrds = res;
      }
    });
  }

  async isUserAdmin() {
    this.isAdmin = await this.authService.isAdmin()
  }
  async isUserStock() {
    this.isStock = await this.authService.isStock()
  }
  async isUserValidator() {
    this.isValidator = await this.authService.isValidator()
  }
}
