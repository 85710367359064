import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { CommercantsService } from 'src/app/services/commercants/commercants.service';

@Component({
  selector: 'app-commandes-fournisseurs',
  templateUrl: './commandes-fournisseurs.component.html',
  styleUrls: ['./commandes-fournisseurs.component.css']
})
export class CommandesFournisseursComponent implements OnInit {

  idCommercant: string = ""
  cmdsComm: any = []
  statusCmds: any = {};

  constructor(private route: ActivatedRoute,
    private CmdsServices: CommandesService,
    private commercantsService: CommercantsService) { }

  ngOnInit(): void {
    this.CmdsServices.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.route.queryParams.subscribe(async (params) => {
      this.idCommercant = params.commercant;
    });
    this.listCommandesCommercants()
  }

  async listCommandesCommercants() {
    await this.commercantsService.allCmdsCommercant(this.idCommercant).then((res: any) => {
      // console.log("ressssssssssss : ")
      // console.log(res)
      if (res) {
        this.cmdsComm = res;
      }
    });
  }

}
