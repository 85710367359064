import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  constructor(private http: HttpClient) { }

  async getResponsables() {
    return await new Promise((slv) => {
      this.http.post(environment.apiGetUsersTracking, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  getOneUserTracking(id) {
    return new Promise((slv) => {
      this.http.post(environment.apiGetOneUserTracking + id, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  deleteCmdsPayed(id) {
    return new Promise((slv) => {
      this.http.post(environment.apiDeleteCmdsPayed + id, {}).subscribe(
        (data: any) => {
          console.log("data.data : ")
          console.log(data.data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  getCmdsAffectedUser(id) {
    return new Promise((slv) => {
      this.http.post(environment.apiCmdsAffectedToUser + id, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  getCmdsLivredUser(id) {
    return new Promise((slv) => {
      this.http.post(environment.apiCmdsLivredForUser + id, {}).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }
}
