import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-sold-produits',
  templateUrl: './sold-produits.component.html',
  styleUrls: ['./sold-produits.component.css']
})
export class SoldProduitsComponent implements OnInit {

  prdsSold: any = []
  
  constructor(
    private PrdsServices: ProductsService) { }

  ngOnInit(): void {
    this.listSoldProduits()
  }

  async listSoldProduits() {
    await this.PrdsServices.allSoldPrds().then((res: any) => {
      if (res) {
        this.prdsSold = res;
      }
    });
  }

}

