// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
var baseURLAPI = 'https://api.marketbey.tn:2043/';

export const environment = {
  production: false,
  baseImagesURL: 'https://assets.marketbey.tn/produits',

  apiAuthUsers: baseURLAPI + 'tracking/auth',
  apiAllCmds: baseURLAPI + 'tracking/cmds',
  apiAllCmdsWebsite: baseURLAPI + 'tracking/cmds-website',
  apiAllCmdsFacebook: baseURLAPI + 'tracking/cmds-facebook',
  apiStatus: baseURLAPI + 'tracking/liststatus',
  apiOneCmdByID: baseURLAPI + 'tracking/cmdbyid',
  apiUpdateCmdStatus: baseURLAPI + 'tracking/updatecmdstatus',
  listeCmdsPretes: baseURLAPI + 'tracking/cmdspretes',
  apiAddComment: baseURLAPI + 'cmds/add-comment',

  apiGetUsersTracking: baseURLAPI + 'tracking/user-responsable',
  apiGetOneUserTracking: baseURLAPI + 'tracking/get-users-tracking/',
  apiDeleteCmdsPayed: baseURLAPI + 'tracking/delete-cmds/',

  apiGetUsersBySupp: baseURLAPI + 'tracking/get-users-supp/',
  apiAffectTransport: baseURLAPI + 'cmds/affect-transport',
  apiReturnCmd: baseURLAPI + 'cmds/return-cmd',
  apiSearchCmds: baseURLAPI + 'cmds/search',
  apiStatsCmds: baseURLAPI + 'cmds/statistics-cmds',

  apiAddReclamation: baseURLAPI + 'cmds/add-reclamation',
  apiValidateReclamation: baseURLAPI + 'cmds/validate-reclamation',
  apiReclamations: baseURLAPI + 'cmds/reclamations',
  apiOneReclamation: baseURLAPI + 'cmds/one-reclamation',

  apiCmdsAffectedToUser: baseURLAPI + 'tracking/cmds-affected/',
  apiCmdsLivredForUser: baseURLAPI + 'tracking/cmds-livred/',
  
  // Produit
  apiAddMvtStock: baseURLAPI + 'products/add-mvt-stock',
  apiOneProduct: baseURLAPI + 'products/product',
  apiAddStockProduct: baseURLAPI + 'products/add-stock-prd',
  apiAllPrds: baseURLAPI + 'products/prds',
  apiAllPendingPrds: baseURLAPI + 'products/pending-prds',
  apiAllCats: baseURLAPI + 'products/all-cats',
  apiAddCat: baseURLAPI + 'products/add-cat',
  apiAllRecoveryPrds: baseURLAPI + 'products/recovery-prds',
  apiAllSoldPrds: baseURLAPI + 'products/sold-prds',
  apiAllPrdsCat: baseURLAPI + 'products/prds-cat',
  apiAllCmdsCat: baseURLAPI + 'products/cmds-cat',
  apiOneCat: baseURLAPI + 'products/one-cat',
  apiAddCatProducts: baseURLAPI + 'products/add-cats-prds',
  apiCatsProducts: baseURLAPI + 'products/cats-prds',
  apiSousCatsProducts: baseURLAPI + 'products/sous-cats-prds',
  apiCountCatsProducts: baseURLAPI + 'products/count-cats-prds',
  apiUploadProducts: baseURLAPI + 'products/add-product',
  apiEditProduct: baseURLAPI + 'products/edit-product',
  apiInvalidProduct: baseURLAPI + 'products/invalid-product',

  // commercant
  apiAllCommercant: baseURLAPI + 'commercants/all',
  apiAllInvalidCommercant: baseURLAPI + 'commercants/all-invalid',
  apiAllPrdsCommercant: baseURLAPI + 'commercants/prds-commercant',
  apiAllCmdsCommercant: baseURLAPI + 'commercants/cmds-commercant',
  apiAddCommercant: baseURLAPI + 'commercants/add-commercant',
  apiUpdateCommercant: baseURLAPI + 'commercants/update-commercant',
  apiGetCommercant: baseURLAPI + 'commercants/get-comercant',
  apiTotalStockCommercant: baseURLAPI + 'commercants/total-stock-comercant',
  apiStatisticCommercant: baseURLAPI + 'commercants/statistic-commercant',
  apiAddAccountComercant: baseURLAPI + 'commercants/add-account-comercant',
  apiAllAccountComercant: baseURLAPI + 'commercants/accounts-comercant',

  apiAddCodePromo: baseURLAPI + 'promos/add-coupon',
  apiAllCodePromos: baseURLAPI + 'promos/coupons',

  // inventaire
  apiListCmdsByPrdt: baseURLAPI + 'cmds/cmdsByPrdt',
  apiInventaireCat: baseURLAPI + 'cmds/inv-categories',

  apiInfosUser: baseURLAPI + "teams-mb/infos",
  apiUsersAuth: baseURLAPI + "teams-mb/auth",
  apiUsersRegister: baseURLAPI + "teams-mb/inscrip",
  apiAllTeams: baseURLAPI + "teams-mb/all-teams",
  apiAddUserMB: baseURLAPI + "teams-mb/add-user-mb",

  apiAllUsers: baseURLAPI + "users/infos-users",
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
