import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products/products.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-pending-produit',
  templateUrl: './pending-produit.component.html',
  styleUrls: ['./pending-produit.component.css']
})
export class PendingProduitComponent implements OnInit {

  isLoading: Boolean = true
  prdsListAll: any = [];

  isValidator: Boolean = false
  isAdmin: Boolean = false

  constructor(private PrdsServices: ProductsService,
    private authService: UsersService,) {
    this.isUserAdmin()
    this.isUserValidator()
   }

  ngOnInit(): void {
    this.listProduits();
    //this.deleteProduct();
  }

  async listProduits() {
    await this.PrdsServices.allPendingPrds().then((res: any) => {
      if (res) {
        this.isLoading = false
        this.prdsListAll = res;
      }
    });
  }
  async isUserAdmin() {
    this.isAdmin = await this.authService.isAdmin()
  }
  async isUserValidator() {
    this.isValidator = await this.authService.isValidator()
  }
}
