import { Component, OnInit } from '@angular/core';
import { NotifierService } from 'angular-notifier';

import { CommandesService } from '../../services/commandes/commandes.service';

@Component({
  selector: 'app-commandes-pretes',
  templateUrl: './commandes-pretes.component.html',
  styleUrls: ['./commandes-pretes.component.css']
})
export class CommandesPretesComponent implements OnInit {
  cmdsList: any = [];
  statusCmds: any = {};

  constructor(
    private CmdsServices: CommandesService,
    private notif: NotifierService
  ) { }

  ngOnInit(): void {
    this.CmdsServices.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.commandesPretes();
  }

  commandesPretes() {
    this.CmdsServices.cmdsPretes().then((res: any) => {
      if (res) {
        this.cmdsList = res;
      }
    });
  }

  generatePDF() {
            var divContents = document.getElementById("commandePrete").innerHTML;
            var a = window.open('', '', 'height=860, width=1600');
            a.document.write('<html><head> ');
            a.document.write(
              '<link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />'
            );
            a.document.write(
              '<link rel="stylesheet" type="text/css" href="/assets/css/style.css" />'
            );
            a.document.write(
              '<link rel="stylesheet" type="text/css" href="/assets/css/components.css" />'
            );
            a.document.write(
              '</head><body style="background:#FFFFFF" ><div  class="container invoice">'
            );
          
            a.document.write(divContents);
            a.document.write('</div></body></html>');
            a.document.close();
            a.print();
  }

}
