<!-- <div id="app">
  <div class="main-wrapper">
    <app-top></app-top>
    <app-menu></app-menu> -->

    <!-- Main Content -->
    <div class="main-content">
      <section class="section">
        <div class="section-header">
          <h1>Liste des fournisseurs</h1>
          <div class="section-header-button">
            <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
          </div>
          <div class="section-header-breadcrumb">
            <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
            <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
            <div class="breadcrumb-item">Toutes les Commandes</div>
          </div>
        </div>
        <div class="section-body">
          <!-- <h2 class="section-title">Liste des commandes</h2> -->
          <!-- <p class="section-lead">
            </p> -->

          <div class="row">
            <div class="col-12">
              <div class="card">
                <!-- <div class="card-header">
                    <h4>Liste des commandes</h4>
                  </div> -->
                <div class="card-body">

                  <div class="clearfix mb-3"></div>

                  <!-- <div class="table-responsive"> -->
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th width="250">Fournisseur</th>
                        <th>Societe</th>
                        <th>Adresse</th>
                        <th>Num Tel</th>
                        <th>Nombre<br> des produits</th>
                        <th width="230" class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let comm of allCommercants, let index= index">
                        <td>#{{index+1}}</td>
                        <td>
                          <div>
                            <div class="row">
                              <div class="col-xs-2 margin-right">
                                <!-- <div class="circle circle-md bg-info pull-right m-t-10"><i
                                                        class="ti-shopping-cart"></i>
                                                </div> -->
                                <ngx-avatar class="rounded-circle" name="{{comm.commercant.nomresponsable_comercant}}">
                                </ngx-avatar>
                              </div>
                              <div class="col-xs-8">
                                <div class="media-title">
                                  {{comm.commercant.nomresponsable_comercant}}</div>
                                <h5 class="text-muted m-t-0">{{comm.commercant.adminuser_comercant}}
                                </h5>
                              </div>

                            </div>
                          </div>

                          <!-- <ngx-avatar class="mr-3 rounded-circle" name="{{comm.commercant.nomresponsable_comercant}}">
                          </ngx-avatar>
                          <div class="media-body">
                            <div class="float-right">
                              <div class="font-weight-600 text-muted text-small">86 Sales</div>
                            </div>
                            <div class="media-title">oPhone S9 Limited</div>
                            <div class="mt-1">
                              <div class="budget-price">
                                <div class="budget-price-square bg-primary" data-width="64%"></div>
                                <div class="budget-price-label">$68,714</div>
                              </div>
                              <div class="budget-price">
                                <div class="budget-price-square bg-danger" data-width="43%"></div>
                                <div class="budget-price-label">$38,700</div>
                              </div>
                            </div>
                          </div> -->

                        </td>
                        <td>{{comm.commercant.societe_comercant}}</td>
                        <td>{{comm.commercant.adresse_comercant}}</td>
                        <td>{{comm.commercant.telresponsable_comercant}}</td>
                        <td>{{comm.products}}</td>
                        <td class="text-right">
                          <a title="Détail" class="btn btn-info btn-sm btn-action trigger--fire-modal-1" [routerLink]="['/details-comercant']"
                            [queryParams]="{ commercant: comm.commercant._id }"><i class="fas fa-eye">
                              Détail</i></a>
                          <a title="Modifier" class="btn btn-primary btn-sm btn-action trigger--fire-modal-1" [routerLink]="['/products-commercant']"
                            [queryParams]="{ commercant: comm.commercant._id }"><i class="fas fa-eye">
                              PRD</i></a>
                          <a title="Modifier" class="btn btn-warning btn-sm btn-action trigger--fire-modal-1" [routerLink]="['/commandes-commercant']"
                            [queryParams]="{ commercant: comm.commercant._id }"><i class="fas fa-eye">
                              CMD</i></a>
                          <a title="Modifier" class="btn btn-success btn-sm btn-action trigger--fire-modal-1"><i
                              class="fas fa-pencil-alt"></i></a>
                          <a title="Supprimer" class="btn btn-danger btn-sm btn-action trigger--fire-modal-1"><i
                              class="fas fa-trash"></i></a>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <!-- </div> -->

                  <!-- <div class="float-right">
                      <nav>
                        <ul class="pagination">
                          <li class="page-item disabled">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>
                          <li class="page-item active">
                            <a class="page-link" href="#">1</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">2</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">3</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- <footer class="main-footer">
        <div class="footer-left">
          Copyright &copy; 2018 <div class="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval Azhar</a>
        </div>
        <div class="footer-right">
          2.3.0
        </div>
      </footer> -->
  <!-- </div>
</div> -->