import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TrackingService } from '../../services/tracking/tracking.service';

@Component({
  selector: 'app-tracking-users',
  templateUrl: './tracking-users.component.html',
  styleUrls: ['./tracking-users.component.css']
})
export class TrackingUsersComponent implements OnInit {

  leadersList: Observable<Array<any>>;
  
  constructor(private trackingServices: TrackingService,) { }

  ngOnInit(): void {
    this.listLeaders()
  }

  async listLeaders() {
    await this.trackingServices.getResponsables().then((res: any) => {
      if (res) {
        console.log("leader res");
        console.log(res);
        this.leadersList = res;
      }
     
    });
  }

}
