<!-- <div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu> -->

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Détail fournisseur</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les Commandes</div>
                    </div>
                </div>
                <div class="section-body">
                    <!-- <h2 class="section-title">Liste des commandes</h2> -->
                    <!-- <p class="section-lead">
              </p> -->
                    <div class="invoice">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <!-- <div class="card-header">
                      <h4>Liste des commandes</h4>
                    </div> -->
                                    <div class="card-body">

                                        <div class="row">

                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-xs-2 margin-right">
                                                        <!-- <div class="circle circle-md bg-info pull-right m-t-10"><i
                                                          class="ti-shopping-cart"></i>
                                                  </div> -->
                                                        <ngx-avatar class="rounded-circle"
                                                            name="{{comercant.nomresponsable_comercant}}">
                                                        </ngx-avatar>
                                                    </div>
                                                    <div class="col-xs-8">
                                                        <div class="media-title">
                                                            {{comercant.nomresponsable_comercant}}</div>
                                                        <h5 class="text-muted m-t-0">{{comercant.adminuser_comercant}}
                                                        </h5>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-md-6 text-md-right">
                                                <address>
                                                    <strong>Fournisseur:</strong><br>
                                                    Société: {{comercant.societe_comercant}}<br>
                                                    {{comercant.raisonsocial_comercant}}<br>
                                                    {{comercant.adminuser_comercant}}<br>
                                                    Tél: {{comercant.telresponsable_comercant}}<br />
                                                </address>
                                                <address>
                                                    <strong>Date d'inscription:</strong><br>
                                                    {{comercant.date_ajt_comercant | date:'dd/MM/yyyy'}}<br>
                                                    <div *ngIf="comercant.etat_comercant==1"
                                                        class="badge badge-success">
                                                        Valide
                                                    </div>
                                                    <div *ngIf="comercant.etat_comercant==0" class="badge badge-danger">
                                                        Invalide
                                                    </div>
                                                </address>
                                            </div>

                                        </div>

                                        <div class="row">
                                            <div class="col-md-12 text-md-right">
                                                <button class="btn btn-warning btn-icon icon-left"
                                                    (click)="addAcompte()"><i class="fas fa-print"></i>
                                                    Acompte</button>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="invoice-title">
                                                <h2>Total des produits dans le stock : </h2>
                                            </div>
                                            <div class="table-responsive table-invoice">
                                                <table class="table table-striped">
                                                    <tr>
                                                        <!-- <th>Invoice ID</th> -->
                                                        <th>#</th>
                                                        <th>Prix d'achat</th>
                                                        <th>Prix de vente</th>
                                                    </tr>
                                                    <tr>
                                                        <!-- <td><a href="#">INV-87239</a></td> -->
                                                        <td>
                                                            #
                                                        </td>
                                                        <td>{{totalStock.totalPrixAchat}} DT</td>
                                                        <td>{{totalStock.totalPrixVente}} DT</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="invoice-title">
                                                <h2>Total des produits vendu : </h2>
                                            </div>
                                            <div class="table-responsive table-invoice">
                                                <table class="table table-striped">
                                                    <tr>
                                                        <!-- <th>Invoice ID</th> -->
                                                        <th>#</th>
                                                        <th>Quantité total</th>
                                                        <th>Prix total</th>
                                                        <th>Acompte fournisseur</th>
                                                    </tr>
                                                    <tr>
                                                        <!-- <td><a href="#">INV-87239</a></td> -->
                                                        <td>
                                                            #
                                                        </td>
                                                        <td>{{totalQuantities}}</td>
                                                        <td>{{totalPrix | number:'0.3'}} DT</td>
                                                        <td>{{accountComm}} DT</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>

                                        <div class="row">

                                            <div class="col-12">
                                                <div class="card">
                                                    <!-- <div class="card-header">
                                                        <h4>Dernières commandes</h4>
                                                        <div class="card-header-action">
                                                            <a href="#" class="btn btn-danger">View More <i
                                                                    class="fas fa-chevron-right"></i></a>
                                                        </div>
                                                    </div> -->
                                                    <div class="card-header">
                                                        <h4>Détail des acomptes fournisseur : </h4>
                                                    </div>
                                                    <div class="card-body">

                                                        <div class="table-responsive table-invoice">
                                                            <table class="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th width="450">Fournisseur</th>
                                                                        <th width="250">Acompte</th>
                                                                        <th class="text-center">Date de l'action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr
                                                                        *ngFor="let comm of listAccountsComm, let index= index">
                                                                        <td>#{{index+1}}</td>
                                                                        <td>
                                                                            <div>
                                                                                <div class="row">
                                                                                    <div class="col-xs-2 margin-right">
                                                                                        <!-- <div class="circle circle-md bg-info pull-right m-t-10"><i
                                                                                                        class="ti-shopping-cart"></i>
                                                                                                </div> -->
                                                                                        <ngx-avatar
                                                                                            class="rounded-circle"
                                                                                            name="{{comercant.nomresponsable_comercant}}">
                                                                                        </ngx-avatar>
                                                                                    </div>
                                                                                    <div class="col-xs-8">
                                                                                        <div class="media-title">
                                                                                            {{comercant.nomresponsable_comercant}}
                                                                                        </div>
                                                                                        <h5 class="text-muted m-t-0">
                                                                                            {{comercant.adminuser_comercant}}
                                                                                        </h5>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{{comm.account_provider}} DT</td>
                                                                        <td class="text-center">{{comm.date_action |
                                                                            date:'dd/MM/yyyy'}}<br>
                                                                            <b>{{comm.date_action | date:'HH:mm'}}</b>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
        <!-- <footer class="main-footer">
          <div class="footer-left">
            Copyright &copy; 2018 <div class="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval Azhar</a>
          </div>
          <div class="footer-right">
            2.3.0
          </div>
        </footer> -->
    <!-- </div>
</div> -->