import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../services/users/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  public msg: String = '';
  
  userInscrir: any = {
    "username": "",
    "password": "",
  }
  detailsUser: any = {}

  msgErrorUsername: string = null;
  msgErrorPassword: string = null;
  msgErrorLogin: string = null;

  constructor(private authService: UsersService,
    private router: Router,) { }

  ngOnInit(): void { 
    // console.log("this.authService.isConnected()")
    // console.log(this.authService.isConnected())
    if (this.authService.isConnected()) {
      this.router.navigate(['/commandes'])
    }
  }

  // authentification() {
  //   location.href = '/dashboard';
  // }


  initMsgError() {
    this.msgErrorUsername = null;
    this.msgErrorPassword = null;
  }

  authentification() {
    this.initMsgError();
    //console.log(username.value, password.value);
    if (this.userInscrir.username == "" || !this.userInscrir.username.includes("@") || this.userInscrir.password == "") {
      if (!this.userInscrir.username.includes("@")) {
        this.msgErrorUsername = "Email est requis et doit contenir '@'";
      }

      if (this.userInscrir.password == "") {
        this.msgErrorPassword = "Le mot de passe est requis";
      }
    } else {
      this.authService.login(this.userInscrir).then((res: any) => {
        // console.log("login user")
        // console.log(res)
        if (res) {
          if (res?.msg) {
            //console.log("inscrir user")
            //console.log(res.msg)
            this.msgErrorLogin = res.msg
          } else {
            this.detailsUser = res
            //console.log("this.detailsUser")
            //console.log(this.detailsUser)

            location.href = '/commandes';
            // this.analyticsService.setUserProperties()
          }
        }
        else {
          console.log("Erreur d'authentification")
        }
      })
    }

  }
}
