import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-stock-product',
  templateUrl: './add-stock-product.component.html',
  styleUrls: ['./add-stock-product.component.css']
})
export class AddStockProductComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddStockProductComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  stockChange(searchValue: string): void {  
    // console.log(searchValue);
    this.data.stockPrd = searchValue
  }
}
