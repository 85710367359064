import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommandesService } from '../../services/commandes/commandes.service';
import { NotifierService } from 'angular-notifier';
import html2canvas from 'html2canvas';
import { MatDialog } from '@angular/material/dialog';
import * as jsPDF from 'jspdf';
import { environment } from 'src/environments/environment';
import { AffectationDialogComponent } from 'src/app/components/shared/dialogs/affectation-dialog/affectation-dialog.component';

@Component({
  selector: 'app-facture',
  templateUrl: './facture.component.html',
  styleUrls: ['./facture.component.css']
})

export class FactureComponent implements OnInit {
  @ViewChild('commande', { static: false }) commandeCtn: ElementRef;

  
  cmd: any = {
    facturation: {},
    livraison: {},
    basket: {},
    livraison_cmd: { pcollect_details: {} },
    bulk_session_datas_cmd : {}
  };
  isPrinting: boolean = false;
  statusCmds: any = {};
  baseURL: String = environment.baseImagesURL;
  cmdID: string = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private CmdsServices: CommandesService,
    private notif: NotifierService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.CmdsServices.getLabeledStatus().then(
      (lst)=>{
        this.statusCmds=lst
      }
    )
    this.route.queryParams.subscribe((params) => {
      this.cmdID = params.cmd;
      this._fetchCmd();
      // console.log(this.cmd);
    });
  }

  _fetchCmd() {
    // console.log(cid);
    this.CmdsServices.getOneCMD(this.cmdID).then((d) => {
      if (d) {
        this.cmd = d;
      } else {
        alert('Aucune commande valide');
        location.href = '/commandes';
      }
    });
  }

}
