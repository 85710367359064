import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommercantsService } from 'src/app/services/commercants/commercants.service';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { UsersService } from 'src/app/services/users/users.service';

declare var Chart: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('chart') canvas: ElementRef;

  nbrCommercant: any = 0
  nbrPrd: any = 0
  nbrRecoveryPrd: any = 0
  nbrSoldPrd: any = 0

  tagPeriode: string = "Aujourd'hui"

  allCats: any = [];
  soldProduits: any = []
  statsCmds: any = {}

  currentUser: any = null
  isAdmin: Boolean = false
  isStock: Boolean = false
  isValidator: Boolean = false

  constructor(
    private cmdsServices: CommandesService,
    private commercantsService: CommercantsService,
    private PrdsServices: ProductsService,
    private authService: UsersService,) {
    this.isUserAdmin()
    this.isUserStock()
    this.isUserValidator()
  }

  ngOnInit(): void {

    this._loadChart();

    this.listCats();
    this.statisticsCmds(new Date(), new Date())

    this.listCommercants();
    /* TODO */
    // this.listProduits()
    this.listRecoveryProduits()
    this.listSoldProduits()

    // this.isAdmin = await this.authService.isAdmin()
  }

  _loadChart() {
    var ctx: any = document.getElementById('myChart');
    ctx.getContext('2d');
    var myChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
        ],
        datasets: [
          {
            label: 'Sales',
            data: [3200, 1800, 4305, 3022, 6310, 5120, 5880, 6154],
            borderWidth: 2,
            backgroundColor: 'rgba(63,82,227,.8)',

            borderColor: 'transparent',
            pointBorderWidth: 0,
            pointRadius: 3.5,
            pointBackgroundColor: 'transparent',
            pointHoverBackgroundColor: 'rgba(63,82,227,.8)',
          },
          {
            label: 'Budget',
            data: [2207, 3403, 2200, 5025, 2302, 4208, 3880, 4880],
            borderWidth: 2,
            backgroundColor: 'rgba(254,86,83,.7)',

            borderColor: 'transparent',
            pointBorderWidth: 0,
            pointRadius: 3.5,
            pointBackgroundColor: 'transparent',
            pointHoverBackgroundColor: 'rgba(254,86,83,.8)',
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                // display: false,
                drawBorder: false,
                color: '#f2f2f2',
              },
              ticks: {
                beginAtZero: true,
                stepSize: 1500,
                callback: function (value, index, values) {
                  return '$' + value;
                },
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
                tickMarkLength: 15,
              },
            },
          ],
        },
      },
    });

    var balance_chart: any = document.getElementById('balancechart');
    balance_chart.getContext('2d');

    // var balance_chart_bg_color: any = balance_chart.createLinearGradient(
    //   0,
    //   0,
    //   0,
    //   70
    // );
    // balance_chart_bg_color.addColorStop(0, 'rgba(63,82,227,.2)');
    // balance_chart_bg_color.addColorStop(1, 'rgba(63,82,227,0)');

    var myChart = new Chart(balance_chart, {
      type: 'line',
      data: {
        labels: [
          '16-07-2018',
          '17-07-2018',
          '18-07-2018',
          '19-07-2018',
          '20-07-2018',
          '21-07-2018',
          '22-07-2018',
          '23-07-2018',
          '24-07-2018',
          '25-07-2018',
          '26-07-2018',
          '27-07-2018',
          '28-07-2018',
          '29-07-2018',
          '30-07-2018',
          '31-07-2018',
        ],
        datasets: [
          {
            label: 'Balance',
            data: [
              50,
              61,
              80,
              50,
              72,
              52,
              60,
              41,
              30,
              45,
              70,
              40,
              93,
              63,
              50,
              62,
            ],
            // backgroundColor: balance_chart_bg_color,
            borderWidth: 3,
            borderColor: 'rgba(63,82,227,1)',
            pointBorderWidth: 0,
            pointBorderColor: 'transparent',
            pointRadius: 3,
            pointBackgroundColor: 'transparent',
            pointHoverBackgroundColor: 'rgba(63,82,227,1)',
          },
        ],
      },
      options: {
        layout: {
          padding: {
            bottom: -1,
            left: -1,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    });

    var sales_chart: any = document.getElementById('sales-chart');
    sales_chart.getContext('2d');

    // var sales_chart_bg_color = sales_chart.createLinearGradient(0, 0, 0, 80);
    // balance_chart_bg_color.addColorStop(0, 'rgba(63,82,227,.2)');
    // balance_chart_bg_color.addColorStop(1, 'rgba(63,82,227,0)');

    var myChart = new Chart(sales_chart, {
      type: 'line',
      data: {
        labels: [
          '16-07-2018',
          '17-07-2018',
          '18-07-2018',
          '19-07-2018',
          '20-07-2018',
          '21-07-2018',
          '22-07-2018',
          '23-07-2018',
          '24-07-2018',
          '25-07-2018',
          '26-07-2018',
          '27-07-2018',
          '28-07-2018',
          '29-07-2018',
          '30-07-2018',
          '31-07-2018',
        ],
        datasets: [
          {
            label: 'Sales',
            data: [
              70,
              62,
              44,
              40,
              21,
              63,
              82,
              52,
              50,
              31,
              70,
              50,
              91,
              63,
              51,
              60,
            ],
            borderWidth: 2,
            // backgroundColor: balance_chart_bg_color,

            borderColor: 'rgba(63,82,227,1)',
            pointBorderWidth: 0,
            pointBorderColor: 'transparent',
            pointRadius: 3,
            pointBackgroundColor: 'transparent',
            pointHoverBackgroundColor: 'rgba(63,82,227,1)',
          },
        ],
      },
      options: {
        layout: {
          padding: {
            bottom: -1,
            left: -1,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              gridLines: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                drawBorder: false,
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
    });
  }
  getStatisticsCmd(tagPeriod) {
    this.tagPeriode = tagPeriod
    var date = new Date(), y = date.getFullYear(), m = date.getMonth(), d = date.getDate();
    if (this.tagPeriode == "Aujourd'hui") {
      this.statisticsCmds(date, date)
    } else if (this.tagPeriode == "Hier") {
      date.setDate(d - 1)
      this.statisticsCmds(date, date)
    } else if (this.tagPeriode == "Cette semaine") {
      this.statisticsCmds(this.getMonday(new Date()), new Date())
    } else if (this.tagPeriode == "Ce mois") {
      this.statisticsCmds(new Date(y, m, 1), new Date(y, m + 1, 0))
    }

  }
  getMonday(d) {
    d = new Date(d);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  async statisticsCmds(startDate, endDate) {
    await this.cmdsServices.statisticsCmds(startDate, endDate).then((res: any) => {
      if (res) {
        this.statsCmds = res;
      }
    });
  }

  async listCommercants() {
    await this.commercantsService.allCommercants().then((res: any) => {
      if (res) {
        this.nbrCommercant = res.length;
      }
    });
  }

  /* TODO */
  // async listProduits() {
  //   await this.PrdsServices.allPrds().then((res: any) => {
  //     if (res) {
  //       this.nbrPrd = res.length;
  //     }
  //   });
  // }

  async listRecoveryProduits() {
    await this.PrdsServices.allRecoveryPrds().then((res: any) => {
      if (res) {
        this.nbrRecoveryPrd = res.length;
      }
    });
  }

  async listSoldProduits() {
    await this.PrdsServices.allSoldPrds().then((res: any) => {
      if (res) {
        this.nbrSoldPrd = res.length;

        for (var i = 0; i < res.length; i++) {
          if (this.soldProduits.length >= 5) break;
          if (res[i].produnct != null) {
            this.soldProduits.push(res[i])
          }
        }
      }
    });

  }

  async listCats() {
    await this.PrdsServices.allCats().then((res: any) => {
      if (res) {
        this.allCats = res;
      }
    });
  }
  async isUserAdmin() {
    this.isAdmin = await this.authService.isAdmin()
  }
  async isUserStock() {
    this.isStock = await this.authService.isStock()
  }
  async isUserValidator() {
    this.isValidator = await this.authService.isValidator()
  }
}
