<!-- <i class="material-icons" style="font-size: 100px;color: #ff0000">info</i> -->
<div mat-dialog-content style="width: 850px;">
    <h1 style="text-align:center"><strong>Commentaire</strong></h1>
    <!-- <h2 style="text-align:center;font-size: x-large; color: rgb(194, 192, 192);">Ajouter le stock du produit</h2> -->
    <div class="div-form">
        <!-- <div class="form-group"> -->

        <!-- <label style="font-size: medium; color: white;">Entrer la nouvelle quantite du produit :</label> -->
        <!-- <input type="number" min="1" class="form-control" name="nom_produit"
                (input)="stockChange($event.target.value)"> -->
        <div class="chat-container">
            <ul class="chat-box chatContainerScroll">
                <div *ngFor="let comment of data.cmd.comments, let index = index">

                    <li class="chat-right" *ngIf="comment?.id_user == currentUser?._id">
                        <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}} <span class="fa fa-check-circle"></span></div>
                        <div class="chat-text">{{comment.content}}</div>
                        <div class="chat-avatar">
                            <!-- <img src="https://www.bootdey.com/img/Content/avatar/avatar4.png"
                                alt="Retail Admin"> -->
                                <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}}">
                                </ngx-avatar>
                            <div class="chat-name">{{comment.name_user}}</div>
                        </div>
                    </li>

                    <li class="chat-left" *ngIf="comment.id_user !== currentUser?._id">
                        <div class="chat-avatar">
                            <ngx-avatar width="42" class="rounded-circle" name="{{comment?.name_user}} ">
                                </ngx-avatar>
                            <div class="chat-name">{{comment.name_user}}</div>
                        </div>
                        <div class="chat-text">{{comment.content}}</div>
                        <div class="chat-hour">{{comment.date_ajout | date:'d MMM y HH:mm'}} <span class="fa fa-check-circle"></span></div>
                    </li>
                </div>
            </ul>

            <div class="form-group mt-3 mb-0">
                <form class="" action="" method="post" onsubmit="return false;" (onsubmit)="addComment(comment);">
                    <div class="form-row">
                        <div class="col-md-1">
                            <div class="position-relative form-group">
                                <ngx-avatar width="42" class="rounded-circle"
                                    name="{{currentUser?.prenom_user + ' ' + currentUser?.nom_user}}">
                                </ngx-avatar>
                            </div>
                            <!-- {{client?.prenom_client +" "+client?.nom_client}} -->
                        </div>
                        <div class="col-md-10">
                            <div class="position-relative form-group">
                                <textarea name="comment" id="comment" class="form-control" rows="2"
                                    placeholder="Ajouter un commentaire..." #comment ngModel></textarea>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="position-relative form-group">
                                <button type="submit" (click)="addComment(comment)" class="form-control btn btn-success"
                                    [mat-dialog-close]="true">Send</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- <mat-error style="color: red;font-weight: bold;" *ngIf="data.stockPrd<=0">Entrez quantité supérieure à 0
            </mat-error> -->

        <!-- </div> -->
    </div>
</div>
<!-- <div class="float-right">
    <input type="button" value="NON" [mat-dialog-close]="false" class="btn btn-info">

    <input style="margin:0px 10px;" type="button" value="OUI" [mat-dialog-close]="true" class="btn btn-danger" *ngIf="data.stockPrd>0">
</div> -->