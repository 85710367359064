import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommandesService } from '../../services/commandes/commandes.service';
import { TrackingService } from '../../services/tracking/tracking.service';

@Component({
  selector: 'app-commandes-users',
  templateUrl: './commandes-users.component.html',
  styleUrls: ['./commandes-users.component.css']
})
export class CommandesUsersComponent implements OnInit {

  cmdsList: any = [];
  idUser: string = '';
  userTracking: any = null;

  tagActive = "affected"
  clickSearch = false

  priceLivred = 0.0
  fraisLivred = 0.0
  priceLivredSearch = 0.0
  fraisLivredSearch = 0.0
  priceAffected = 0.0
  fraisAffected = 0.0
  lengthCmdsAffected = 0;
  lengthCmdsLivred = 0;
  lengthSearchCmds = 0;
  statusCmds: any = {};


  constructor(private route: ActivatedRoute,
    private trakingServices: TrackingService,
    private CmdsServices: CommandesService) { }

  ngOnInit(): void {
    this.CmdsServices.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.route.queryParams.subscribe((params) => {
      this.idUser = params.idUser;
      this.getUserTracking()
      this.getPriceLivred()
      this.getPriceAffected()

      this.fetchCmdsAffected();
    });
  }

  getUserTracking() {
    this.tagActive = "affected"
    // console.log(cid);
    this.trakingServices.getOneUserTracking(this.idUser).then((user) => {
      if (user) {
        // console.log("userrrrrrr : ")
        // console.log(user)
        this.userTracking = user;
      }
    });
  }

  fetchCmdsAffected() {
    this.clickSearch = false
    this.tagActive = "affected"
    // console.log(cid);
    this.trakingServices.getCmdsAffectedUser(this.idUser).then((d) => {
      if (d) {
        // console.log("ddddddddddd : ")
        // console.log(d)
        this.cmdsList = d;
        this.lengthCmdsAffected = this.cmdsList.length
      }
    });
  }
  getPriceAffected() {
    this.trakingServices.getCmdsAffectedUser(this.idUser).then((d) => {
      if (d) {

        var prices = 0.0
        var frais = 0.0
        var tabAffected: any = []
        tabAffected = d
        this.lengthCmdsAffected = tabAffected.length
        tabAffected.map((cmd) => {
          prices += cmd.total_ca_cmd; frais += cmd.bulk_session_datas_cmd.frais_livraison
        })
        this.priceAffected = prices;
        this.fraisAffected = frais
      }
    });
  }

  fetchCmdsLivred() {
    this.clickSearch = false
    this.tagActive = "livred"
    this.trakingServices.getCmdsLivredUser(this.idUser).then((d) => {
      if (d) {
        // console.log("ddddddddddd : ")
        // console.log(d)
        this.cmdsList = d;
        this.lengthCmdsLivred = this.cmdsList.length
      }
    });
  }

  async searchCmdsByMail(email) {
    this.clickSearch = true
    if(this.tagActive == "livred") {
      await this.trakingServices.getCmdsLivredUser(this.idUser).then((d) => {
        if (d) {
          this.cmdsList = d;
        }
      });
    } else if(this.tagActive == "affected") {
      await this.trakingServices.getCmdsAffectedUser(this.idUser).then((d) => {
        if (d) {
          this.cmdsList = d;
        }
      });
    }
    this.cmdsList = this.cmdsList.filter((cmd) => (cmd.facturation.email == email.value));
    this.lengthSearchCmds = this.cmdsList.length

    var prices = 0.0
    var frais = 0.0
    this.cmdsList.map((cmd) => {
      prices += cmd.total_ca_cmd; frais += cmd.bulk_session_datas_cmd.frais_livraison
    })
    this.priceLivredSearch = prices;
    this.fraisLivredSearch = frais

  }
  getPriceLivred() {
    this.trakingServices.getCmdsLivredUser(this.idUser).then((d) => {
      if (d) {
        var prices = 0.0
        var frais = 0.0
        var tabLivred: any = []
        tabLivred = d
        this.lengthCmdsLivred = tabLivred.length
        tabLivred.map((cmd) => {
          prices += cmd.total_ca_cmd; frais += cmd.bulk_session_datas_cmd.frais_livraison
        })
        this.priceLivred = prices;
        this.fraisLivred = frais
      }
    });
  }

  // deleteCmdsLivred() {
  //   this.tagActive = "livred"
  //   this.trakingServices.deleteCmdsPayed(this.idUser).then((d) => {
  //     if (d) {
  //       console.log("ddddddddddddd")
  //       console.log(d)
  //       // this.userTracking = d;
  //       // this.cmdsList = this.userTracking.commandes

  //     }
  //   });
  //   this.getPriceLivred()
  //   this.fetchCmdsLivred()
  // }

  async returnCmd(idCmd) {
    //get user MarketBey
    // var userMarketBey = null;
    // await this.trakingServices.getOneUserTracking("5eff765b5c40227dc4fd656e").then((user) => {
    //   if (user) {
    //     console.log("user admin")
    //     console.log(user)
    //     userMarketBey = user;
    //   }
    // });
    // console.log("userMarketBey")
    // console.log(userMarketBey)
    this.CmdsServices.returnCmd(idCmd).then((res) => {
      if (res) {
        this.fetchCmdsAffected();
      }
    });
  }

  updateStatusCmd(status) {
    if (confirm('Etes vous sûre?'))
    this.tagActive = "livred"
    this.cmdsList.forEach(element => {
      this.CmdsServices.updateCmdStatus(element._id, status).then((res) => {
        if (res) {
          // console.log("resssssss : " + res)
          // this.notif.notify('success', 'Commande mis à jour avec succès');
          // this._fetchCmd();
        } //else this.notif.notify('error', "Une erreur s'est produite");
      });
    })
    this.getPriceLivred()
    this.fetchCmdsLivred()
  }

}
