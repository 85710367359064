import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.css']
})
export class TopComponent implements OnInit {

  currentUser: any = null

  constructor(private authService: UsersService,
    private router: Router,) { }
  

  async ngOnInit() {
    this.currentUser = await this.authService.getUserInfos()
    // console.log(this.currentUser)
  }

  async deconnexion() {
    await this.authService.deconnexion()
    // window.location.reload()
    this.router.navigate(['/logout'])
  }

}
