import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CommandesComponent } from './pages/commandes/commandes.component';
import { DetailsCommandeComponent } from './pages/details-commande/details-commande.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FactureComponent } from './pages/facture/facture.component';
import { CommandesPretesComponent } from './pages/commandes-pretes/commandes-pretes.component';
import { TrackingUsersComponent } from './pages/tracking-users/tracking-users.component';
import { CommandesUsersComponent } from './pages/commandes-users/commandes-users.component';
import { AddReclamationComponent } from './pages/reclamations/add-reclamation/add-reclamation.component';
import { ReclamationsCommandesComponent } from './pages/reclamations/reclamations-commandes/reclamations-commandes.component';
import { ValidateReclamationComponent } from './pages/reclamations/validate-reclamation/validate-reclamation.component';
import { ViewReclamationComponent } from './pages/reclamations/view-reclamation/view-reclamation.component';
import { AddCodePromoComponent } from './pages/promotions/add-code-promo/add-code-promo.component';
import { AllCodesPromoComponent } from './pages/promotions/all-codes-promo/all-codes-promo.component';
import { InventaireComponent } from './pages/inventaire/inventaire.component';
import { CommandesWebsiteComponent } from './pages/commandes-website/commandes-website.component';
import { CommandesFacebookComponent } from './pages/commandes-facebook/commandes-facebook.component';
import { FournisseursComponent } from './pages/fournisseurs/fournisseurs/fournisseurs.component';
import { DetailsFournisseurComponent } from './pages/fournisseurs/details-fournisseur/details-fournisseur.component';
import { AddFournisseurComponent } from './pages/fournisseurs/add-fournisseur/add-fournisseur.component';
import { ProductsFournisseursComponent } from './pages/fournisseurs/products-fournisseurs/products-fournisseurs.component';
import { CommandesFournisseursComponent } from './pages/fournisseurs/commandes-fournisseurs/commandes-fournisseurs.component';
import { RecoveryProduitsComponent } from './pages/produits/recovery-produits/recovery-produits.component';
import { SoldProduitsComponent } from './pages/produits/sold-produits/sold-produits.component';
import { ProduitsCategoryComponent } from './pages/produits/produits-category/produits-category.component';
import { ProduitsComponent } from './pages/produits/produits/produits.component';
import { AddProduitComponent } from './pages/produits/add-produit/add-produit.component';
import { ViewProduitComponent } from './pages/produits/view-produit/view-produit.component';
import { EditProduitComponent } from './pages/produits/edit-produit/edit-produit.component';
import { PendingProduitComponent } from './pages/produits/pending-produit/pending-produit.component';
import { CategoriesComponent } from './pages/categories/categories/categories.component';
import { TeamsMBComponent } from './pages/teamsMB/teams-mb/teams-mb.component';
import { AddUserMbComponent } from './pages/teamsMB/add-user-mb/add-user-mb.component';
import { AuthGuard } from './services/guard/auth.guard';
import { ValidateProduitComponent } from './pages/produits/validate-produit/validate-produit.component';
import { UsersComponent } from './pages/users/users/users.component';
import { CommandesCategoriesComponent } from './pages/categories/commandes-categories/commandes-categories.component';
import { InvalidFournisseurComponent } from './pages/fournisseurs/invalid-fournisseur/invalid-fournisseur.component';
import { MyCommandesComponent } from './pages/my-commandes/my-commandes.component';
import { AddCategoryComponent } from './pages/categories/add-category/add-category.component';
import { CategoriesProduitsComponent } from './pages/categories/categories-produits/categories-produits.component';
import { BaseLayoutComponent } from './layout/base-layout/base-layout.component';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent, canActivate: [AuthGuard],
    children: [
      // Dashboads
      { path: '', component: DashboardComponent, data: { roles: ["admin"] } },

      // Teams MB
      { path: 'teams-mb', component: TeamsMBComponent, data: { roles: ["admin"] } },
      { path: 'add-user-mb', component: AddUserMbComponent, data: { roles: ["admin"] } },

      // COMMANDES
      { path: 'commandes', component: CommandesComponent },
      { path: 'my-commandes', component: MyCommandesComponent },
      { path: 'commandes-website', component: CommandesWebsiteComponent },
      { path: 'commandes-facebook', component: CommandesFacebookComponent },
      { path: 'details-commande', component: DetailsCommandeComponent },
      { path: 'commandes-pretes', component: CommandesPretesComponent },
      { path: 'commandes-user', component: CommandesUsersComponent },

      // Products
      { path: 'produits', component: ProduitsComponent },
      { path: 'add-produit', component: AddProduitComponent, data: { roles: ["admin", "stock"] } },
      { path: 'view-produit', component: ViewProduitComponent },
      { path: 'edit-produit', component: EditProduitComponent, data: { roles: ["admin", "stock", "validator"] } },
      { path: 'validate-produit', component: ValidateProduitComponent, data: { roles: ["admin", "validator"] } },
      { path: 'pending-produit', component: PendingProduitComponent, data: { roles: ["admin", "stock"] } },
      { path: 'recovery-produits', component: RecoveryProduitsComponent },
      { path: 'sold-produits', component: SoldProduitsComponent },
      { path: 'code-produit', component: CategoriesProduitsComponent },
      { path: 'products-category', component: ProduitsCategoryComponent },

      // Categories
      { path: 'categories', component: CategoriesComponent },
      { path: 'add-category', component: AddCategoryComponent },
      { path: 'commandes-category', component: CommandesCategoriesComponent },

      // Commercants
      { path: 'commercants', component: FournisseursComponent, data: { roles: ["admin", "validator"] } },
      { path: 'details-comercant', component: DetailsFournisseurComponent, data: { roles: ["admin"] } },
      { path: 'add-commercant', component: AddFournisseurComponent, data: { roles: ["admin", "validator"] } },
      { path: 'invalid-commercant', component: InvalidFournisseurComponent, data: { roles: ["admin"] } },
      { path: 'products-commercant', component: ProductsFournisseursComponent },
      { path: 'commandes-commercant', component: CommandesFournisseursComponent },

      // tracking-user
      { path: 'tracking-user', component: TrackingUsersComponent },
  
      // codes-promo
      { path: 'add-code-promo', component: AddCodePromoComponent },
      { path: 'codes-promo', component: AllCodesPromoComponent },

      // inventaire
      { path: 'inventaire', component: InventaireComponent },

      // clients
      { path: 'clients', component: UsersComponent, data: { roles: ["admin"] } },

      // Reclamations
      { path: 'add-reclamation', component: AddReclamationComponent },
      { path: 'validate-reclamation', component: ValidateReclamationComponent },
      { path: 'view-reclamation', component: ViewReclamationComponent },
      { path: 'reclamations-commande', component: ReclamationsCommandesComponent },

    ]
  },
  
  { path: '', redirectTo: '', pathMatch: 'full' },

  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  // { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { roles: ["admin"] } },

  { path: 'facture', component: FactureComponent, canActivate: [AuthGuard] },
    // { path: 'add-produit', component: AddProduitComponent },
  
  { path: 'logout', redirectTo: '/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
