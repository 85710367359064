<!-- <div id="app">
  <div class="main-wrapper">
    <app-top></app-top>
    <app-menu></app-menu> -->

    <!-- Main Content -->
    <div class="main-content">
      <section class="section">
        <div class="section-header">
          <h1>Liste des commandes prêtes {{cmdsList.length}}</h1>
          <div class="section-header-button">
            <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
          </div>
          <div class="section-header-breadcrumb">
            <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
            <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
            <div class="breadcrumb-item">Commandes prêtes</div>
          </div>
        </div>
        <div class="section-body">
          <!-- <h2 class="section-title">Liste des commandes</h2> -->
          <!-- <p class="section-lead">
            </p> -->
          <!-- <div class="row">
            <div class="col-12">
              <div class="card mb-0">
                <div class="card-body">
                  <ul class="nav nav-pills">
                    <li class="nav-item">
                      <a class="nav-link active" href="#">Tous <span class="badge badge-white">5</span></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Préparées <span class="badge badge-primary">1</span></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">En attente <span class="badge badge-primary">1</span></a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#">Livrées <span class="badge badge-primary">0</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> -->
          <div class="row mt-4">
            <div class="col-12">
              <div class="card">
                <div class="budget-price justify-content-center" *ngIf="cmdsList.length === 0">
                  <h3>Aucune commande prête pour le grand Tunis </h3>
                </div>
                <!-- <div class="card-header">
                    <h4>Liste des commandes</h4>
                  </div> -->
                <div class="card-body" *ngIf="cmdsList.length > 0">

                  <div class="float-left">
                    <select class="form-control selectric">
                      <option>Selectionner une ville</option>
                      <option>Ariana</option>
                      <option>Ben Arous</option>
                      <option>Mannouba</option>
                      <option>Tunis</option>
                    </select>
                  </div>
                  <!-- <div class="float-right">
                      <form>
                        <div class="input-group">
                          <input type="text" class="form-control" placeholder="Recherche">
                          <div class="input-group-append">
                            <button class="btn btn-primary"><i class="fas fa-search"></i></button>
                          </div>
                        </div>
                      </form>
                    </div> -->

                  <div class="clearfix mb-3"></div>

                  <div class="table-responsive" id="commandePrete" #commandePrete>
                    <table class="table table-striped">
                      <tr>
                        <th>QrCode</th>
                        <th>Reference</th>
                        <!-- <th>Date Création</th> -->
                        <th>Nom Client</th>
                        <th>Téléphone</th>
                        <th>Adresse</th>
                        <th>Total Commande</th>
                        <th>Frais de livraison</th>

                      </tr>
                      <tr *ngFor="let cmd of cmdsList">
                        <td>
                          <qrcode print [width]="100" usesvg="true" [elementType]="'svg'" [qrdata]="cmd._id"
                            [errorCorrectionLevel]="'M'"></qrcode>
                        </td>
                        <td><b>{{cmd.ref_cmd}}</b>
                        </td>
                        <!-- <td>
                            {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}
                          </td> -->
                        <td>
                          <div class="d-inline-block ml-1">{{cmd.facturation.nom}}</div>
                        </td>
                        <td>{{cmd.facturation.numtel}}</td>
                        <td>
                          <h5>{{cmd.facturation.adresse}} <br/> {{cmd.facturation.ville}} </h5>
                        </td>
                        <td>{{cmd.total_ca_cmd}} TND</td>
                        <!-- <td>{{cmd.nbr_prdt_cmd}}</td> -->
                        <td>
                          <div>{{cmd.bulk_session_datas_cmd.frais_livraison | number:'0.3'}} TND </div>
                        </td>
                      </tr>
                    <!-- <tr>
                      <td>Frais de livraison: </td>
                      <td> </td>
                    </tr>
                    <tr>
                      <td>Montant total</td>
                      <td>{{cmd.total_ca_cmd + cmd.bulk_session_datas_cmd.frais_livraison}} TND</td>
                    </tr> -->

                    </table>

                  </div>

                  <div class="float-right">
                    <button (click)="generatePDF()" class="btn btn-warning btn-icon icon-left"><i
                        class="fas fa-print"></i>
                      Imprimer</button>
                  </div>
                  <!-- <div class="float-right">
                      <nav>
                        <ul class="pagination">
                          <li class="page-item disabled">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>
                          <li class="page-item active">
                            <a class="page-link" href="#">1</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">2</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">3</a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- <footer class="main-footer">
        <div class="footer-left">
          Copyright &copy; 2018 <div class="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval Azhar</a>
        </div>
        <div class="footer-right">
          2.3.0
        </div>
      </footer> -->
  <!-- </div>
</div> -->