import { Component, OnInit } from '@angular/core';
import { CommandesService } from 'src/app/services/commandes/commandes.service';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-inventaire',
  templateUrl: './inventaire.component.html',
  styleUrls: ['./inventaire.component.css']
})
export class InventaireComponent implements OnInit {

  villes = ["",
    'Ariana',
    'Béja',
    'Ben Arous',
    'Bizerte',
    'Gabès',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kébili',
    'Kef',
    'Mahdia',
    'Manouba',
    'Médenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    'Sidi Bouzid',
    'Siliana',
    'Sousse',
    'Tataouine',
    'Tozeur',
    'Tunis',
    'Zaghouan'];

  selectedVille: string = '';
  selectedCategory: string = '';
  inventaireCat: any = {}

  totalCmds: Number = 0
  totalprix: Number = 0
  nbrePrdts: Number = 0
  prix: Number = 0
  namePrdt: String = ''
  cmdsList: any = []

  allCats: any = [];

  listCmds: any = [];
  statusCmds: any = {};
  statsCmds: any = {}

  constructor(private CmdsServices: CommandesService,
    private PrdsServices: ProductsService) { }

  ngOnInit(): void {
    this.CmdsServices.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.listCats()
  }

  async listCats() {
    await this.PrdsServices.allCats().then((res: any) => {
      if (res) {
        this.allCats = res;
      }
    });
  }

  selectChangeVille(event: any) {
    //update the ui
    this.selectedVille = event.target.value;
  }

  selectChangeCategory(event: any) {
    //update the ui
    this.selectedCategory = event.target.value;
  }

  async searchInventaire(ref, datein, datefin) {
    // console.log(ref.value, datein.value, datefin.value)
    await this.CmdsServices.listCmdsByPrdt(ref.value, datein.value, datefin.value, this.selectedVille).then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.cmdsList = res.data
        this.totalCmds = res.totalCmds
        this.totalprix = res.totalPricePrdts;
        this.nbrePrdts = res.totalPrdts;
        this.namePrdt = res.namePrdt
        this.prix = res.price
        //console.log( this.cmdsList, this.totalprix, this.nbrePrdts)
      }
    });
  }

  async searchInventaireCategories(dateinCat, datefinCat) {
    // console.log("this.datein : ")
    // console.log(dateinCat.value)
    // console.log("this.datefin : ")
    // console.log(datefinCat.value)
    // console.log("this.selectChangeCategory : ")
    // console.log(this.selectedCategory)
    await this.CmdsServices.inventaireCategories(this.selectedCategory, dateinCat.value, datefinCat.value).then((res: any) => {
      if (res) {
        // console.log("resssssssss searchInventaireCategories : ")
        // console.log(res)
        this.inventaireCat = res
        //console.log( this.cmdsList, this.totalprix, this.nbrePrdts)
      }
    });
  }

  async searchInventaireCommandes(dateinCmd, datefinCmd) {
    await this.CmdsServices.statisticsCmds(dateinCmd.value, datefinCmd.value).then((res: any) => {
      if (res) {
        this.statsCmds = res;
      }
    });
  }

  displayCmdsByStatus(status) {
    if (status == "pending") {
      this.listCmds = this.statsCmds.allCmdsPending
    } else if (status == "validated") {
      this.listCmds = this.statsCmds.allCmdsValidated
    } else if (status == "prepared") {
      this.listCmds = this.statsCmds.allCmdsPrepared
    } else if (status == "transporting") {
      this.listCmds = this.statsCmds.allCmdsTransporting
    } else if (status == "arrived") {
      this.listCmds = this.statsCmds.allCmdsArrived
    } else if (status == "delivered") {
      this.listCmds = this.statsCmds.allCmdsLivred
    } else if (status == "returned") {
      this.listCmds = this.statsCmds.allCmdsReturned
    } else if (status == "canceled") {
      this.listCmds = this.statsCmds.allCmdsCanceled
    } else {
      this.listCmds = []
    }
  }

}
