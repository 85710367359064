<!-- <div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu> -->

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Nouveau produit</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Toutes les Commandes</div>
                    </div>
                </div>
                <div class="section-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="card author-box card-primary">
                                <div class="card-header">
                                    <h4>Ajouter un produit</h4>
                                </div>
                                <div class="card-body">
                                    <div class="card-body">
                                        <form action="" method="post" enctype="multipart/form-data">
                                            <!-- <div class="row">
                                                <input id="cin" name="cin" type="file"
                                                    (change)="fileChangeEvent($event)" placeholder="Upload a file..."
                                                    multiple />
                                                <button type="button" class="btn btn-success btn-s" (click)="upload()">
                                                    <i class="glyphicon glyphicon-open-file"></i>&nbsp;Upload
                                                </button>
                                            </div> -->
                                            <div class="row">
                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label>Nom du produit</label>
                                                        <input type="text" class="form-control" name="nom_produit"
                                                            ngModel #nom_produit>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="nom_produit.value==''">Entrez nom du produit
                                                        </mat-error>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Fournisseur</label>
                                                        <select class="form-control" name="idCommercant"
                                                            [(ngModel)]="idCommercant">
                                                            <!-- <option value="-"></option> -->
                                                            <option *ngFor="let comm of allCommercants"
                                                                [value]="comm.commercant._id">
                                                                {{comm.commercant.nomresponsable_comercant}}</option>
                                                        </select>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="idCommercant==''">Choisir le fournisseur du produit
                                                        </mat-error>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-4">
                                                            <div class="form-group">
                                                                <label>Catégorie </label>
                                                                <select class="form-control selectric" name="catsPrds"
                                                                    [(ngModel)]="catsPrds"
                                                                    (change)="selectCatsPrd(catsPrds)">
                                                                    <option *ngFor="let catPrd of listCatsProds"
                                                                        [ngValue]="catPrd">{{catPrd.nom_cat_prdt}}
                                                                        ({{catPrd.code_cat_prdt}})</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-5">
                                                            <div class="form-group" *ngIf="listSousCatsProds.length>0">
                                                                <label>Sous catégorie </label>
                                                                <select class="form-control selectric"
                                                                    name="sousCatsPrd" [(ngModel)]="sousCatsPrd">
                                                                    <option *ngFor="let sousCatPrd of listSousCatsProds"
                                                                        [value]="sousCatPrd.code_cat_prdt">
                                                                        {{sousCatPrd.nom_cat_prdt}}
                                                                        ({{sousCatPrd.code_cat_prdt}})</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-3">
                                                            <div class="form-group">
                                                                <label>Type </label>
                                                                <select class="form-control" [(ngModel)]="typeCatsPrds"
                                                                    name="typeCatsPrds" style="padding: 5px;">
                                                                    <option [value]="type" *ngFor="let type of types">
                                                                        {{type}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <mat-error style="color: red;font-weight: bold;"
                                                        *ngIf="catsPrds=='' || sousCatsPrd=='' || typeCatsPrds==''">
                                                        Référence du produit est invalid
                                                    </mat-error>
                                                </div>

                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="code_barre">Code à barre (Optionnel)</label>
                                                        <input id="code_barre" type="text" class="form-control"
                                                            name="code_barre" #code_barre>
                                                    </div>
                                                    <div class="form-group">
                                                        <label class="col-md-12">Mots clef</label>
                                                        <div class="col-md-12">
                                                            <div class="row">
                                                                <!-- <div class="col-md-4" *ngFor="let tag_prd of tags_prdt; let index = index">
                                                                    <div class="form-group">
                                                                        <input type="text" [(ngModel)]="tags_prdt[index]" name="index"
                                                                            placeholder="Mot clef"
                                                                            class="form-control">
                                                                    </div>
                                                                </div> -->
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd1" ngModel
                                                                            #tag_prd1 placeholder="Mot clef 1"
                                                                            class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd2" ngModel
                                                                            #tag_prd2 placeholder="Mot clef 1"
                                                                            class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd3" ngModel
                                                                            #tag_prd3 placeholder="Mot clef 1"
                                                                            class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd4" ngModel
                                                                            #tag_prd4 placeholder="Mot clef 1"
                                                                            class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd5" ngModel
                                                                            #tag_prd5 placeholder="Mot clef 1"
                                                                            class="form-control">
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <div class="form-group">
                                                                        <input type="text" name="tag_prd6" ngModel
                                                                            #tag_prd6 placeholder="Mot clef 1"
                                                                            class="form-control">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <textarea name="tags" class="form-control" rows="5"></textarea> -->
                                                        </div>
                                                        <div class="col-md-12">
                                                            <mat-error style="color: red;font-weight: bold;"
                                                                *ngIf="tag_prd1.value=='' && tag_prd2.value=='' && tag_prd3.value=='' 
                                                                    && tag_prd4.value=='' && tag_prd5.value=='' && tag_prd6.value==''">
                                                                Il faut ajouter au moins un mot clé
                                                            </mat-error>
                                                        </div>
                                                        <!-- <small><i>Un mot clef par ligne</i></small> -->
                                                    </div>
                                                </div>

                                                <div class="col-4 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="last_name">Catégorie</label>
                                                        <select class="form-control" name="cats_produit"
                                                            [(ngModel)]="cats_produit" multiple style="height:180px">
                                                            <!-- <option value="-"></option> -->
                                                            <option *ngFor="let cat of allCats" [ngValue]="cat._id">
                                                                {{cat.label_cat}}</option>
                                                        </select>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="cats_produit.length==0">
                                                            Selectionnez une catégorie
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                            </div>

                                            <div class="row">
                                                <div class="form-group col-4">
                                                    <label for="prix_achat_produit">Prix d'achat du produit</label>
                                                    <input id="prix_achat_produit" type="number" class="form-control"
                                                        name="prix_achat_produit" #prix_achat_produit ngModel>
                                                    <mat-error style="color: red;font-weight: bold;"
                                                        *ngIf="prix_achat_produit.value=='' || prix_achat_produit.value<=0">
                                                        Ajoutez le prix d'achat du produit
                                                    </mat-error>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="prix_produit">Prix du produit</label>
                                                    <input id="prix_produit" type="number" class="form-control"
                                                        name="prix_produit" #prix_produit ngModel>
                                                    <mat-error style="color: red;font-weight: bold;"
                                                        *ngIf="prix_produit.value=='' || prix_produit.value<0">
                                                        Ajoutez le prix du produit
                                                    </mat-error>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="ancienprix_produit">Ancien Prix (prix barré)</label>
                                                    <input id="ancienprix_produit" type="number" class="form-control"
                                                        name="ancienprix_produit" #ancienprix_produit ngModel>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="form-group col-4">
                                                    <label for="stock_reelle">Quantité du produit</label>
                                                    <input id="stock_reelle" type="number" min="1" class="form-control"
                                                        name="stock_reelle" [(ngModel)]="stock_reelle"
                                                        [disabled]="form.value.variations.length>0">
                                                    <mat-error style="color: red;font-weight: bold;"
                                                        *ngIf="stock_reelle==null || stock_reelle<=0">
                                                        Ajoutez la quantité du stock
                                                    </mat-error>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for="seuil_stock">Seuil de stock</label>
                                                    <input id="seuil_stock" type="number" class="form-control"
                                                        name="seuil_stock" #seuil_stock ngModel>
                                                </div>
                                                <div class="form-group col-4">
                                                    <label for=""></label>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="isDelivery"
                                                            class="custom-control-input" id="isDelivery"
                                                            [(ngModel)]="isDelivery">
                                                        <label class="custom-control-label" for="isDelivery">Livraison
                                                            gratuite</label>
                                                    </div>

                                                    <label for=""></label>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="tested"
                                                            class="custom-control-input" id="tested"
                                                            [(ngModel)]="tested">
                                                        <label class="custom-control-label" for="tested">Testé pour
                                                            vous</label>
                                                    </div>

                                                    <label for=""></label>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" name="quantiteLimite"
                                                            class="custom-control-input" id="quantiteLimite"
                                                            [(ngModel)]="quantiteLimite">
                                                        <label class="custom-control-label"
                                                            for="quantiteLimite">Quantité limitée</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-12">
                                                    <label for="description">Vidéo</label>
                                                    <input  name="video" id="video"
                                            accept="video/*" (change)="handleVideoInput($event)"
                                            type="file">
                                                    <!-- <mat-error style="color: red;font-weight: bold;"
                                                        *ngIf="description.value==''">
                                                        Ajoutez la description du produit
                                                    </mat-error> -->
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-6">
                                                    <label for="description">Description</label>
                                                    <textarea id="description" name="description" rows="5" #description
                                                        ngModel class="form-control" style="height: 120px;"
                                                        required=""></textarea>
                                                    <mat-error style="color: red;font-weight: bold;"
                                                        *ngIf="description.value==''">
                                                        Ajoutez la description du produit
                                                    </mat-error>
                                                </div>
                                                <div class="form-group col-6">
                                                    <label for="commentaire">Commentaire</label>
                                                    <textarea id="commentaire" name="commentaire" rows="5" #commentaire
                                                        ngModel class="form-control" style="height: 120px;"
                                                        required=""></textarea>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div [formGroup]="form" class="form-group col-3 border-variation"
                                                    *ngFor="let productGroup of form.get('variations')['controls'];let i = index"
                                                    [formGroup]="productGroup">

                                                    <label for="name_variation">Nom de la variation</label>
                                                    <input type="text" class="form-group form-control"
                                                        formControlName="label">
                                                    <mat-error style="color: red;font-weight: bold;"
                                                        *ngIf="productGroup.label==''">
                                                        Ajoutez la description du produit
                                                    </mat-error>

                                                    <label for="valeurs_variation">Valeurs</label>
                                                    <input type="text" class="form-group form-control"
                                                        formControlName="valeurs" (input)="selectLabelVariation()">

                                                    <!-- <label for="quantity_variation">Quantité</label>
                                                    <input type="number" min="1" class="form-group form-control"
                                                        formControlName="quantity" (input)="loadQuantities()"> -->

                                                    <div class="form-group float-right">
                                                        <button type="button" (click)="deleteProductGroup(i)"
                                                            class="mr-2 btn-icon btn-icon-only btn btn-danger"><i
                                                                class="fas fa-trash"> </i></button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <mat-error style="color: red;font-weight: bold;"
                                                    *ngIf="form.value.variations.length > 0 && !formVariationNotEmpty()">
                                                    Les variations sont invalid
                                                </mat-error>
                                            </div>
                                            <div class="row">
                                                <div class="position-relative row form-group">
                                                    <button type="button" (click)="addNewProductGroup()"
                                                        class="mt-1 btn btn-warning"><i class="pe-7s-plus"></i>
                                                        Ajouter une variation</button>
                                                </div>
                                            </div>

                                            <!-- <div class="row" *ngIf="variationNotEmpty()">
                                                <div class="col-3">
                                                    <div class="form-group">
                                                        <label>Label de la variation </label>
                                                        <select class="form-control selectric" name="labelVariation"
                                                            [(ngModel)]="labelVariation"
                                                            (change)="selectLabelVariation()">
                                                            <option value=""></option>
                                                            <option *ngFor="let item of form.value.variations"
                                                                [ngValue]="item.label">{{item.label}}</option>
                                                        </select>
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="labelVariation==''">
                                                            Label de la variation est invalid
                                                        </mat-error>
                                                    </div>
                                                </div>
                                                <div class="col-9">
                                                    <div class="row">
                                                        <div class="col-2"
                                                            *ngFor="let quantity of quantityVariation, let index=index">
                                                            <div class="form-group">
                                                                <label>Quantité
                                                                    {{quantityVariation[index].variation}}</label>
                                                                <input [id]="index+1" type="number" min="1"
                                                                    class="form-control" [name]="index+1"
                                                                    [(ngModel)]="quantityVariation[index].quantity"
                                                                    (input)="loadQuantities()">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <mat-error style="color: red;font-weight: bold;"
                                                            *ngIf="!quantityVariationNotEmpty()">
                                                            les quantités de la variation sont invalid
                                                        </mat-error>
                                                    </div>
                                                </div>
                                            </div> -->

                                            <div class="row" [formGroup]="myForm">
                                                <div class="form-group col-md-12">
                                                    <label for="uploads">Multiple Upload</label>
                                                    <div class="Neon Neon-theme-dragdropbox">

                                                        <div class="Neon-input-dragDrop">
                                                            <div class="Neon-input-inner">
                                                                <div class="Neon-input-icon" *ngIf="images.length==0">
                                                                    <i class="fa fa-file-image-o"></i>
                                                                </div>

                                                                <!-- <div class="col-2">
                                                                    <div class="avatar-item mb-0">
                                                                      <img alt="image" src="../assets/img/avatar/avatar-5.png" class="img-fluid" data-toggle="tooltip" title="Alfa Zulkarnain">
                                                                      <div class="avatar-badge" title="Editor" data-toggle="tooltip"><i class="fas fa-wrench"></i></div>
                                                                    </div>
                                                                  </div> -->

                                                                <div class="col-lg-12  Neon-input-icon"
                                                                    *ngIf="images.length>0">
                                                                    <div class="col-2"
                                                                        *ngFor='let url of images; let index = index'>
                                                                        <img [src]="url" height="120" width="120px">
                                                                        <div class="avatar-badge" title="Editor"
                                                                            (click)="removeSelectedFile(index)"
                                                                            data-toggle="tooltip"><i
                                                                                class="fas fa-times icon"></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="Neon-input-text">
                                                                    <h3>Drag&amp;Drop files here</h3> <span
                                                                        style="display:inline-block; margin: 5px 0">or</span>
                                                                </div>
                                                                <div>
                                                                    <input
                                                                    style="z-index: 999; opacity: 0; width: 320px; height: 60px; position: absolute; right: 0px; 
                                                                    left: 0px; margin-right: auto; margin-left: auto;"
                                                                        name="file" id="file" #file accept="image/*"
                                                                        type="file" multiple
                                                                        (change)="onFileChange($event)">
                                                                    <a class="Neon-input-choose-btn blue">Browse
                                                                        Files</a>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <mat-error style="color: red;font-weight: bold;"
                                                        *ngIf="images.length==0">
                                                        Ajoutez des images du produit
                                                    </mat-error>
                                                </div>

                                            </div>

                                            <div class="form-group">
                                                <button type="button"
                                                    (click)="addProduct(nom_produit, code_barre, prix_achat_produit, prix_produit, ancienprix_produit,
                                                                    seuil_stock, description, commentaire,
                                                                    tag_prd1, tag_prd2, tag_prd3, tag_prd4, tag_prd5, tag_prd6)"
                                                    class="btn btn-primary btn-lg btn-block">
                                                    Ajouter produit
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    <!-- </div>
</div> -->