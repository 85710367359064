<!-- <div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu> -->

        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Suivi des transporteurs</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                </div>
                <div class="section-body">
                    <div class="row" *ngFor="let leader of leadersList">
                        <div class="col-lg-3">
                            <div class="card card-statistic-2">
                                <div class="card-wrap">
                                    <div class="card-header">
                                        <img src="../../../assets/img/avatar/avatar-user.png"
                                            class="img-responsive rotprod profile-user">
                                        <!-- <div class="product-image"
                                            style="background-image: url('../assets/img/products/product-3-50.png'); background-position: center; background-repeat: no-repeat; background-size: cover;">
                                            <img [src]="ingredient.photo" class="img-responsive rotprod"> -->
                                        <!-- <span class="tag3 special">
                                                {{ ingredient.calorie }} calories
                                            </span>
                                        </div> -->
                                    </div>
                                    <div class="card-body">
                                        <h5>{{leader.element.nom_prenom_tracking}}</h5>
                                        <img class="mr-3 rounded" width="30"
                                            src="../assets/img/products/product-3-50.png" alt="product">
                                        <a [routerLink]="['/commandes-user']" [queryParams]="{ idUser: leader.element._id }" class="btn btn-danger">Detail CMD <i
                                                class="fas fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9" *ngIf="leader.teams.length>0">
                            <div class="card gradient-bottom">
                                <div class="card-header">
                                    <h4>L'équipe - {{leader.teams.length}} membre actif :  </h4>
                                    <div class="card-header-action dropdown">
                                        <a data-toggle="dropdown"
                                            class="btn btn-danger dropdown-toggle">Aujourd'hui</a>
                                        <ul class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                            <li class="dropdown-title">Période</li>
                                            <li><a class="dropdown-item active">Aujourd'hui</a></li>
                                            <li><a class="dropdown-item">Cette semaine</a></li>
                                            <li><a class="dropdown-item">Ce mois</a></li>
                                            <li><a class="dropdown-item">Période</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card-body" id="top-5-scroll">
                                    <ul class="list-unstyled list-unstyled-border">
                                        <li class="media" *ngFor="let user of leader.teams">
                                            <img class="mr-3 rounded" width="65"
                                                src="../../../assets/img/avatar/avatar-sub-user.webp" alt="product">
                                            <div class="media-body">
                                                <div class="float-right">
                                                    <div [routerLink]="['/commandes-user']" [queryParams]="{ idUser: user._id }" class="font-weight-600 text-muted text-small">Détail Commandes</div>
                                                </div>
                                                <div class="media-title">{{user.nom_prenom_tracking}}</div>
                                                <div class="mt-1">
                                                    <div class="budget-price">
                                                        <div class="budget-price-square bg-primary" data-width="64%">
                                                        </div>
                                                        <div class="budget-price-label">Email : {{user.username_tracking}}</div>
                                                    </div>
                                                    <div class="budget-price">
                                                        <div class="budget-price-square bg-danger" data-width="43%">
                                                        </div>
                                                        <div class="budget-price-label">Téléphone : 21481233</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="card-footer pt-3 d-flex justify-content-center">
                                    <div class="budget-price justify-content-center">
                                        <div class="budget-price-square bg-primary" data-width="20"></div>
                                        <div class="budget-price-label">Selling Price</div>
                                    </div>
                                    <div class="budget-price justify-content-center">
                                        <div class="budget-price-square bg-danger" data-width="20"></div>
                                        <div class="budget-price-label">Budget Price</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    <!-- </div>
</div> -->