import { Component, OnInit } from '@angular/core';
import { CouponsService } from 'src/app/services/coupons/coupons.service';

@Component({
  selector: 'app-add-code-promo',
  templateUrl: './add-code-promo.component.html',
  styleUrls: ['./add-code-promo.component.css']
})

export class AddCodePromoComponent implements OnInit {

  reductions = [
    "PERCENT",
    "AMOUNT"
  ]

  selectedType : String = '';

  coupon: any = {
    "code" : "",
    "amount": "",
    "type_reduction": this.selectedType,
    "is_reusable" : "",
    "is_used" : "",
    "date_used" : "",
    "is_disabled" : "",
    "nbr_reply" : ""

  }

  constructor(private couponServices: CouponsService) { }

  ngOnInit(): void {

  }

  selectChangeHandler(event: any) {
    this.selectedType = event.target.value;
  }

  addCodePromo() {
    console.log("addCodePromo");
    console.log(this.coupon)
    this.couponServices.addCoupon(this.coupon).then((res: any) => {
      console.log("res")
      console.log(res)
      if (res) {
        console.log("ajouttttttttttttttt");
        location.href = '/codes-promo';
      }
      else {
        console.log("addCodePromo");
        console.log("erreuuuuuuuur");
      }
    })
  }


}
