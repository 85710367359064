<!-- <div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu> -->

        <!-- Main Content -->
        <div class="main-content">
            <section class="section">
                <div class="section-header">
                    <h1>Nouveau Code promo</h1>
                    <div class="section-header-button">
                        <!-- <a href="features-post-create.html" class="btn btn-primary">Nouvelle commande</a> -->
                    </div>
                    <div class="section-header-breadcrumb">
                        <div class="breadcrumb-item active"><a routerLink="">Tableau de bord</a></div>
                        <!-- <div class="breadcrumb-item"><a href="#">Commandes</a></div> -->
                        <div class="breadcrumb-item">Liste des code Promo</div>
                    </div>
                </div>
                <div class="section-body">
                    <!-- <h2 class="section-title">Liste des commandes</h2> -->
                    <!-- <p class="section-lead">
             
                    </p> -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card mb-0">
                                <div class="card-body">
                                    <ul class="nav nav-pills float-right">
                                        <li class="nav-item">
                                            <a [ngClass]="(tagActive==='ALL')?'nav-link active':'nav-link'"
                                                (click)="listCodesPromo('ALL')">Tous <span
                                                    [ngClass]="(tagActive==='ALL')?'badge badge-white':'badge badge-primary'">{{codesPromoList.length}}</span></a>
                                        </li>
                                        <!-- <li class="nav-item">
                                            <a [ngClass]="(tagActive==='ACTIF')?'nav-link active':'nav-link'"
                                                (click)="listCodesPromoActif('ACTIF')">Actifs <span
                                                    [ngClass]="(tagActive==='ACTIF')?'badge badge-white':'badge badge-primary'">0</span></a>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <div class="card">
                                <div class="budget-price justify-content-center" *ngIf="codesPromoList.length === 0">
                                    <h3>Aucun code promo {{tagActive}} </h3>
                                </div>
                                <div class="card-body" *ngIf="codesPromoList.length > 0">
                                    <div class="table-responsive">
                                        <table class="table table-striped">
                                            <tr>

                                                <th>Code</th>
                                                <th>Date Création</th>
                                                <th>Montant</th>
                                                <th>Désactivé</th>
                                                <th>Réutilisable</th>
                                                <th>Utilisé</th>
                                                <th>Nombre <br>d'utilisation</th>
                                                <th>Nombre <br>de fois utilisés</th>
                                                <th>Date d'expiration</th>

                                            </tr>
                                            <tr *ngFor="let coupon of codesPromoList">

                                                <td><b>{{coupon?.code}}</b>
                                                </td>
                                                <td>
                                                    {{coupon?.date_add | date:'dd/MM/yyyy'}}
                                                </td>
                                                <td>
                                                    <div class="d-inline-block ml-1">{{coupon.amount}}
                                                        <strong *ngIf="coupon.type_reduction == 'PERCENT'"> %</strong>
                                                        <strong *ngIf="coupon.type_reduction == 'AMOUNT'"> DT</strong>
                                                    </div>
                                                </td>
                                                <td>{{coupon?.is_disabled}}</td>
                                                <td>{{coupon?.is_reusable}}</td>
                                                <td>
                                                    {{coupon?.is_used}}
                                                </td>
                                                <td>
                                                    {{coupon?.nbr_reply}}
                                                </td>
                                                <td>
                                                    {{coupon?.users?.length}}
                                                </td>

                                                <td>
                                                    {{coupon?.date_used | date:'dd/MM/yyyy'}}
                                                </td>

                                            </tr>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <!-- <footer class="main-footer">
        <div class="footer-left">
          Copyright &copy; 2018 <div class="bullet"></div> Design By <a href="https://nauval.in/">Muhamad Nauval Azhar</a>
        </div>
        <div class="footer-right">
          2.3.0
        </div>
      </footer> -->
    <!-- </div>
</div> -->