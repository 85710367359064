<!-- <div id="app">
  <div class="main-wrapper">
    <app-top></app-top>
    <app-menu></app-menu> -->

    <div class="main-content">
      <section class="section">
        <!-- <div class="section-header">
          <img class="mr-3 rounded" width="65"
            src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="product">
          <h1>Suivi les commandes de "{{userTracking.nom_prenom_tracking}}" </h1>
          <div class="section-header-button">
          </div>
        </div> -->
        <div class="section-body">
          <div class="row">
            <div class="col-lg-12">
              <div class="card gradient-bottom">
                <div class="card-header">
                  <h4>Suivi les commandes de "{{userTracking?.nom_prenom_tracking}}" </h4>
                  <div class="card-header-action dropdown">
                    <a data-toggle="dropdown" class="btn btn-danger dropdown-toggle">Aujourd'hui</a>
                    <ul class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                      <li class="dropdown-title">Période</li>
                      <li><a class="dropdown-item active">Aujourd'hui</a></li>
                      <li><a class="dropdown-item">Cette semaine</a></li>
                      <li><a class="dropdown-item">Ce mois</a></li>
                      <li><a class="dropdown-item">Période</a></li>
                    </ul>
                  </div>
                </div>
                <div class="card-body" id="top-5-scroll">
                  <ul class="list-unstyled list-unstyled-border">
                    <li class="media">
                      <img class="mr-3 rounded" width="65"
                        src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png" alt="product">
                      <div class="media-body">
                        <div class="float-right">
                          <div class="font-weight-600 text-muted text-small">Détail Commandes</div>
                        </div>
                        <div class="media-title">{{userTracking?.nom_prenom_tracking}}</div>
                        <div class="mt-1">
                          <div class="budget-price">
                            <div class="budget-price-square bg-primary" data-width="64%">
                            </div>
                            <div class="budget-price-label">Email : {{userTracking?.username_tracking}}</div>
                          </div>
                          <div class="budget-price">
                            <div class="budget-price-square bg-danger" data-width="43%">
                            </div>
                            <div class="budget-price-label">Téléphone : 21481233</div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="card-footer pt-3 d-flex justify-content-center">
                  <div class="budget-price justify-content-center">
                    <div class="budget-price-square bg-primary" data-width="20"></div>
                    <div class="budget-price-label">En livraison: (Total Commande {{priceAffected}} DT) -- (Total Frais
                      {{fraisAffected}} DT)</div>
                  </div>
                  <div class="budget-price justify-content-center">
                    <div class="budget-price-square bg-danger" data-width="20"></div>
                    <div class="budget-price-label">A payé: (Total Commande {{priceLivred}} DT) -- (Total Frais
                      {{fraisLivred}} DT)</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card mb-0">
                <div class="card-body">
                  <ul class="nav nav-pills float-left">
                    <li class="nav-item">
                      <div class="div-form">
                        <form class="needs-validation" action="" method="post" onsubmit="return false;"
                          (onsubmit)="searchCmdsByMail(email);">
                          <div class="form-group">
                            <label for="email">Email</label>
                            <input id="email" type="email" class="form-control" name="email" tabindex="1" #email
                              ngModel>
                          </div>
                          <button type="submit" class="btn btn-primary btn-lg btn-icon icon-righ" id="search"
                            tabindex="1" (click)="searchCmdsByMail(email)">
                            <!-- Recherche -->
                            <i class="fas fa-search"></i>
                          </button>
                          <div *ngIf="clickSearch==true">
                            <h3>Nombre des commandes <span class="badge badge-primary">{{lengthSearchCmds}}</span></h3>
                            <div class="budget-price-label">A payé: (Total Commande {{priceLivredSearch}} DT) -- (Total
                              Frais
                              {{fraisLivredSearch}} DT)</div>
                          </div>
                        </form>
                      </div>
                    </li>
                  </ul>
                  <ul class="nav nav-pills float-right">
                    <li class="nav-item">
                      <a [ngClass]="(tagActive==='affected')?'nav-link active':'nav-link'"
                        (click)="fetchCmdsAffected()">Affectées <span
                          [ngClass]="(tagActive==='affected')?'badge badge-white':'badge badge-primary'">{{lengthCmdsAffected}}</span></a>
                    </li>
                    <li class="nav-item">
                      <a [ngClass]="(tagActive==='livred')?'nav-link active':'nav-link'"
                        (click)="fetchCmdsLivred()">Livrées <span
                          [ngClass]="(tagActive==='livred')?'badge badge-white':'badge badge-primary'">{{lengthCmdsLivred}}</span></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="card">
                <div class="budget-price justify-content-center" *ngIf="cmdsList.length === 0">
                  <h3>Aucune commande affecté/livré au transporteur "{{userTracking?.nom_prenom_tracking}}" </h3>
                </div>
                <div class="card-body" *ngIf="cmdsList.length > 0">
                  <div class="table-responsive">
                    <table class="table table-striped">
                      <tr>

                        <th>Actions</th>
                        <th>Référence</th>
                        <th *ngIf="tagActive==='affected'&&cmdsList.length>0">Date Création</th>
                        <th>Client</th>
                        <!-- <th>Téléphone</th> -->
                        <th>Adresse</th>
                        <th>Total Commande</th>
                        <th>Livraison</th>
                        <th *ngIf="tagActive==='livred'&&cmdsList.length>0">Nom Produit</th>
                        <!-- <th *ngIf="userTracking?.responsable==true">Affecté à</th> -->
                        <th *ngIf="tagActive==='affected'&&cmdsList.length>0">Status</th>

                      </tr>
                      <tr *ngFor="let cmd of cmdsList">
                        <td>
                          <div class="dropdown d-inline mr-2">
                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown"
                              aria-haspopup="true" aria-expanded="false">Actions</button>
                            <div class="dropdown-menu">
                              <!-- <a href="javascript:void(0)" *ngIf="cmd.etat_cmd=='pending'" class="dropdown-item"
                                (click)="updateStatusCmd(cmd._id,'validated')"> <i class="fa fa-check"></i> Valider</a>

                              <a href="javascript:void(0)" *ngIf="cmd.etat_cmd=='validated'" class="dropdown-item"
                                (click)="updateStatusCmd(cmd._id,'prepared')"> <i class="fa fa-check"></i>Prête</a> -->

                              <!-- <a href="javascript:void(0)" *ngIf="cmd.etat_cmd=='preparing'" class="dropdown-item"
                                    (click)="updateStatusCmd(cmd._id,'')"> <i class="fa fa-check"></i>Prête</a> -->

                              <a [routerLink]="['/details-commande']" [queryParams]="{ cmd: cmd._id }"
                                class="dropdown-item">
                                <i class="fa fa-info-circle"></i> Détails</a>

                              <a [routerLink]="['/facture']" [queryParams]="{ cmd: cmd._id }" class="dropdown-item">
                                <i class="fa fa-info-circle"></i>Facture</a>

                              <a (click)="returnCmd(cmd._id)" class="dropdown-item"
                                *ngIf="tagActive==='affected' && cmd.etat_cmd==='returned'">
                                <i class="fa fa-check"></i>Retourner</a>

                            </div>
                          </div>
                        </td>
                        <td><b>{{cmd.ref_cmd}}</b>
                        </td>
                        <td *ngIf="tagActive==='affected'&&cmdsList.length>0">
                          {{cmd.date_creation_cmd | date:'dd/MM/yyyy'}}
                        </td>
                        <td>
                          <div class="d-inline-block ml-1">
                            {{cmd.facturation.nom}}<br />
                            <strong>{{cmd.facturation.numtel}}</strong></div>
                        </td>
                        
                        <td>{{cmd.facturation.adresse}}<br /><b> {{cmd.facturation.ville}}</b></td>
                        <td>{{cmd.total_ca_cmd}} TND</td>
                        <td>{{cmd.bulk_session_datas_cmd.frais_livraison | number:'0.3'}} TND</td>
                        <td *ngIf="tagActive==='livred'&&cmdsList.length>0">
                          <tr  *ngFor="let prd of cmd.basket.prdts, let index = index">
                            <td>{{prd.nom}}</td>
                          </tr>
                          </td>
                        <!-- <td
                          *ngIf="userTracking?.responsable==true">
                          {{cmd?.transport_affect[cmd?.transport_affect?.length-1]?.transporter_name}}</td> -->
                        <!-- <td
                          *ngIf="userTracking?.responsable==true && cmd?.transport_affect?.length>0 && cmd?.transport_affect[cmd?.transport_affect?.length-1]==null">
                          Commande retourné à la société</td>
                        <td *ngIf="userTracking?.responsable==true && cmd?.transport_affect?.length==0">
                        </td> -->
                        <td *ngIf="tagActive==='affected'&&cmdsList.length>0">
                          <div class="badge badge-{{statusCmds[cmd.etat_cmd].color}}">{{statusCmds[cmd.etat_cmd].label}}
                          </div>
                          <div><span>{{cmd.msg_returned_cmd}}</span></div>
                        </td>

                      </tr>
                    </table>
                  </div>
                  <div class="col-12" *ngIf="userTracking?.responsable">
                    <div class="float-right" *ngIf="tagActive==='livred'&&cmdsList.length>0">
                      <div class="input-group">
                        <div class="input-group-append">
                          <button class="btn btn-danger" (click)="updateStatusCmd('paied')"><i class="fas fa-angellist">
                              Valider le Paiement</i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  <!-- </div>
</div> -->