import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddStockProductComponent } from 'src/app/components/shared/dialogs/add-stock-product/add-stock-product.component';
import { ProductsService } from 'src/app/services/products/products.service';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-produits',
  templateUrl: './produits.component.html',
  styleUrls: ['./produits.component.css']
})
export class ProduitsComponent implements OnInit {

  isLoading: Boolean = true
  prdsListAll: any = [];

  isAdmin: Boolean = false
  isSimpleUser: Boolean = false
  isStock: Boolean = false
  isValidator: Boolean = false

  currentPage: number = 0
  totalPages: number = 1
  pages: Array<number> = [];
  pagesDisplaied: Array<number> = [];

  constructor(private PrdsServices: ProductsService,
    private dialog: MatDialog,
    private authService: UsersService,) {
    this.isUserAdmin()
    this.isUserStock()
    this.isUserValidator()
  }

  async ngOnInit() {
    this.listProduits();

    // this.isAdmin = await this.authService.isAdmin()
    // this.isSimpleUser = await this.authService.isSimpleUser()
    // this.isStock = await this.authService.isStock()
    // this.isValidator = await this.authService.isValidator()
  }

  async listProduits() {
    await this.PrdsServices.allPrds(this.currentPage).then((res: any) => {
      if (res) {
        this.isLoading = false
        // console.log("resssssssss : ")
        // console.log(res)
        this.prdsListAll = res?.items;

        this.totalPages = res.totalPages
        this.currentPage = res.currentPage
        this.pages = Array.from(Array(res.totalPages)).map((x, i) => i)
        this.splicePages()
      }
    });
  }
  splicePages() {
    // console.log("currentPage : ")
    //     console.log(this.currentPage)
    if (this.pages.length > 10) {
      // if(this.currentPage < this.pages.length-10) {
      //   this.pagesDisplaied = this.pages.splice(this.currentPage, 10);
      //   console.log("if 1 pagesDisplaied : ")
      //   console.log(this.pagesDisplaied)
      // } else 
      if (this.currentPage < 5) {
        this.pagesDisplaied = this.pages.splice(0, 10);
        // console.log("if 1 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else if (this.currentPage >= 5 && this.currentPage < this.pages.length - 5) {
        this.pagesDisplaied = this.pages.splice(this.currentPage - 4, 10);
        // console.log("if 2 pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      } else {
        this.pagesDisplaied = this.pages.splice(this.pages.length - 10, 10);
        // console.log("else pagesDisplaied : ")
        // console.log(this.pagesDisplaied)
      }
    } else {
      this.pagesDisplaied = this.pages
    }
  }

  nextPage() {
    this.currentPage++
    this.listProduits();
  }

  previousPage() {
    this.currentPage--
    this.listProduits();
  }

  selectPage(page: any) {
    this.currentPage = page
    this.listProduits();
  }

  invaliderProduct(idPrd) {
    if (confirm('Etes vous sûre?')) {
      this.PrdsServices.invalidPrd(idPrd).then((res: any) => {
        // console.log("invaliderProduct res")
        // console.log(res)
        if (res) {
          location.href = '/pending-produit';
        }
      });
    }
  }

  async addStock(idPrd) {
    const confirmDialog = this.dialog.open(AddStockProductComponent, {
      data: {
        idPrd: idPrd,
        stockPrd: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        if (data.stockPrd == null || data.stockPrd == "") {
          alert('Quantité de stock produit est invalide');
        } else {
          this.PrdsServices.addStockProduct(idPrd, data.stockPrd).then((res) => {
            if (res) {

            }
            // else {
            //   // console.log("erreur");
            //   this.notif.notify('error', "Une erreur s'est produite");
            // }
          });
        }

      }
    });

  }
  async isUserAdmin() {
    this.isAdmin = await this.authService.isAdmin()
  }
  async isUserStock() {
    this.isStock = await this.authService.isStock()
  }
  async isUserValidator() {
    this.isValidator = await this.authService.isValidator()
  }
}
