import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users/users.service';

@Component({
  selector: 'app-add-comments',
  templateUrl: './add-comments.component.html',
  styleUrls: ['./add-comments.component.css']
})
export class AddCommentsComponent implements OnInit {

  currentUser: any = null

  constructor(public dialogRef: MatDialogRef<AddCommentsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: UsersService,) { }

  async ngOnInit() {
    this.currentUser = await this.authService.getUserInfos()
  }

  addComment(comment) {
    this.data.comment = comment.value
    this.data.currentUser = this.currentUser
  }
}
