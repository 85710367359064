import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from '../users/users.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private usersService: UsersService,
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.usersService.isConnected()) {
      // const currentUser:any = await this.usersService.getUserInfos()
      // if (currentUser && route.data.roles) {
      //   console.log("route.data.roles")
      //   console.log(route.data.roles)

      //   const filteredItems = route.data.roles.filter(x => currentUser.profiles.some(y => y==x));
      //   console.log("filteredItems");
      //   console.log(filteredItems);
      //   if (filteredItems && filteredItems.length<=0) {
      //     // role not authorised so redirect to home page
      //     this.router.navigate(['/dashboard']);

      //     return false;
      //   }
      // }
      this.usersService.hasRoles(route.data.roles).then((res) => {
        // console.log("rees canActivate : " + res)
        if (!res) {
          this.router.navigate(['/commandes']);
          return false;
        }
      })
      // console.log("hasRole : ")
      // console.log(this.usersService.hasRoles(route.data.roles))
      // var hasRoles = await this.usersService.hasRoles(route.data.roles)
      // if(!this.usersService.hasRoles(route.data.roles)) {
      //   this.router.navigate(['/commandes']);
      //   return false;
      // }
      return true
    } else {
      this.router.navigate(['/login'])
      return false
    }
  }

}
