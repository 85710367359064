import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {

  isLoading: Boolean = true
  allCats: any = [];

  constructor(private PrdsServices: ProductsService) { }

  ngOnInit(): void {
    this.listCats();
  }

  async listCats() {
    await this.PrdsServices.allCats().then((res: any) => {
      if (res) {
        this.isLoading = false
        this.allCats = res;
      }
    });
  }
}
