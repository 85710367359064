import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotifierService } from 'angular-notifier';
import { AddCommentsComponent } from 'src/app/components/shared/dialogs/add-comments/add-comments.component';
import { AffectationDialogComponent } from 'src/app/components/shared/dialogs/affectation-dialog/affectation-dialog.component';
import { ValidationDialogComponent } from 'src/app/components/shared/dialogs/validation-dialog/validation-dialog.component';
import { CommandesService } from 'src/app/services/commandes/commandes.service';

@Component({
  selector: 'app-commandes-website',
  templateUrl: './commandes-website.component.html',
  styleUrls: ['./commandes-website.component.css']
})
export class CommandesWebsiteComponent implements OnInit {

  isLoading: Boolean = true

  villes = ["Selectionnez une ville",
    'Ariana',
    'Béja',
    'Ben Arous',
    'Bizerte',
    'Gabès',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kébili',
    'Kef',
    'Mahdia',
    'Manouba',
    'Médenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    'Sidi Bouzid',
    'Siliana',
    'Sousse',
    'Tataouine',
    'Tozeur',
    'Tunis',
    'Zaghouan'];

  status = ["", "pending", "validated", "preparing", "submited", "transported", "arrived", "delivered", "returned", "refused", "canceled"];
  selectedVille: string = '';
  selectedStatus: string = '';
  cmdsList: any = [];

  cmdsListAll: any = [];
  cmdsListPrete: any = [];
  cmdsListValidate: any = [];
  cmdsListArrived: any = [];
  cmdsListPaied: any = [];
  cmdsListReturned: any = [];
  cmdsListCanceled: any = [];

  statusCmds: any = {};

  cmdsListToAffect: any = [];

  tagActive = "ALL"

  reclamation: any = {};

  constructor(
    private CmdsServices: CommandesService,
    private notif: NotifierService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.CmdsServices.getLabeledStatus().then(
      (lst) => {
        this.statusCmds = lst
      }
    )
    this.listCommandes();
  }

  addCmdToAffect(event: any, cmd) {
    if (event.target.checked) {
      this.cmdsListToAffect.push(cmd)
    } else {
      //const index = this.cmdsListToAffect.controls.findIndex(x => x === cmd);
      //this.cmdsListToAffect.remove(cmd);
      this.cmdsListToAffect = this.cmdsListToAffect.filter(item => item !== cmd);
    }
    // console.log(this.cmdsListToAffect)
  }

  //event handler for the select element's change event
  selectChangeVille(event: any) {
    //update the ui
    this.selectedVille = event.target.value;
  }

  selectChangeStatus(event: any) {
    //update the ui
    this.selectedStatus = event.target.value;
  }
  
  async listCommandes() {
    this.tagActive = "ALL";
    await this.CmdsServices.allCmdsWebsite().then((res: any) => {
      if (res) {
        this.isLoading = false;
        // console.log("resssssssss : ")
        // console.log(res)
        this.cmdsListAll = res;
        this.cmdsList = res;
      }
    });
    this.cmdsListPrete = this.listCommandesByStatus("prepared");
    this.cmdsListValidate = this.listCommandesByStatus("validated");
    this.cmdsListArrived = this.listCommandesByStatus("delivered");
    this.cmdsListPaied = this.listCommandesByStatus("paied");
    this.cmdsListReturned = this.listCommandesByStatus("returned");
    this.cmdsListCanceled = this.listCommandesByStatus("canceled")
  }

  listCommandesByStatus(status) {
    return this.cmdsListAll.filter((cmd) => (cmd.etat_cmd == status));
  }

  listCmdsByStatus(status) {
    this.tagActive = status;
    if (status == "ALL") {
      this.cmdsList = this.cmdsListAll
    } else {
      this.cmdsList = this.listCommandesByStatus(status)
    }
  }

  async updateStatusCmd(cmd, status) {
    // this.notif.notify('success', 'Commande mis à jour avec succès');
    // return;
    if (confirm('Etes vous sûre?'))
      await this.CmdsServices.updateCmdStatus(cmd, status).then((res) => {
        if (res) {
          this.notif.notify('success', 'Commande mis à jour avec succès');
        }
        else {
          this.notif.notify('error', "Une erreur s'est produite");
        }
      });
  }

  async search(ref, numero, datein, datefin) {
    await this.CmdsServices.searchCmds(ref.value, this.selectedStatus, this.selectedVille, numero.value, datein.value, datefin.value).then((res: any) => {
      if (res) {
        // console.log("resssssssss : ")
        // console.log(res)
        this.cmdsList = res;
      }
    });
    // if (datein.value != null && datein.value != "" ) {
    //   console.log("date");
    //   console.log(datein.value);
    //   let datee = new Date(datein.value).toLocaleDateString("en-us");
    //   console.log("datee");
    //   console.log(datee);
    //   //var d = new Date(timestamp).toLocaleDateString("en-us")
    //   this.cmdsList = this.cmdsListAll.filter((cmd) => (
    //     new Date(cmd.date_creation_cmd).toLocaleDateString("en-us") == datee
    //   ));
    //   console.log(this.cmdsList);
    // }
    // if (ref.value != null && ref.value != "") {
    //   console.log("ref");
    //   console.log(ref.value);
    //   this.cmdsList = this.cmdsListAll.filter((cmd) => (cmd.ref_cmd == ref.value));
    //   console.log(this.cmdsList);
    // }
    // if (this.selectedVille != null) {
    //   console.log("this.selectedVille");
    //   console.log(this.selectedVille);
    //   this.cmdsList = this.cmdsListAll.filter((cmd) => (cmd.facturation.ville == this.selectedVille));
    //   console.log(this.cmdsList);
    // }
    // if (numero.value != null && numero.value != "") {
    //   console.log("numero");
    //   console.log(numero.value);
    //   this.cmdsList = this.cmdsListAll.filter((cmd) => (cmd.facturation.numtel == numero.value));
    //   console.log(this.cmdsList);
    // }

  }
  async validerCmd(cmd) {
    const confirmDialog = this.dialog.open(ValidationDialogComponent, {
      data: {
        cmd: cmd
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.updateStatusCmd(cmd._id, 'validated')
        this.listCommandes();
      }
    });

  }
  async affectCmd(idCmd) {
    const confirmDialog = this.dialog.open(AffectationDialogComponent, {
      data: {
        idCmd: idCmd,
        user: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        // console.log("dataaaaaaaaaa : ")
        // console.log(data.user)
        this.CmdsServices.affectTransport(idCmd, data.user).then((res) => {
          //console.log(res);
          if (res) {

          }
          // else {
          //   // console.log("erreur");
          //   this.notif.notify('error', "Une erreur s'est produite");
          // }
        });
        this.listCommandes();
      }
    });

  }

  async affectMoreCmds() {
    const confirmDialog = this.dialog.open(AffectationDialogComponent, {
      data: {
        title: 'Confirm Remove Employee',
        message: 'Supprimer un produit',
        idCmd: null,
        user: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        // console.log("dataaaaaaaaaa : ")
        // console.log(data.user)
        this.cmdsListToAffect.forEach(element => {
          this.CmdsServices.affectTransport(element._id, data.user).then((res) => {
            if (res) {

            }
          });
        });

        this.listCommandes();
      }
    });
  }

  async createReclamation(idCmd) {
    await this.haveReclamation(idCmd)
    if (this.reclamation != null) {
      if (this.reclamation.status_reclamation == "EN_COURS") {
        location.href = '/validate-reclamation?cmd=' + idCmd;
      } else {
        location.href = '/view-reclamation?cmd=' + idCmd;
      }
    } else {
      location.href = '/add-reclamation?cmd=' + idCmd;
    }
  }

  async haveReclamation(idCmd) {
    await this.CmdsServices.getOneReclamation(idCmd).then((rec) => {
      // console.log("rec : ")
      // console.log(rec)
      if (rec) {
        this.reclamation = rec;
      } else {
        this.reclamation = null
      }
    });
  }

  async addComment(cmd) {
    const confirmDialog = this.dialog.open(AddCommentsComponent, {
      data: {
        cmd: cmd,
        currentUser: null,
        comment: null,
      }
    });

    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        var data = confirmDialog.componentInstance.data
        console.log("data : ")
        console.log(data)
        if (data.comment == null || data.comment == "") {
          alert('Quantité de stock produit est invalide');
        } else {
          this.CmdsServices.addComment(cmd._id, data.currentUser._id,
            data.currentUser.prenom_user + " " + data.currentUser.nom_user, data.comment).then((data: any) => {
              console.log("dddddddddddddd")
              console.log(data)
              this.listCommandes();
              // if (data) {
              //   cmd = data;
              //   data.comment = null
              // }
            });
        }

      }
    });

  }
}