<div id="app">
    <div class="main-wrapper">
        <app-top></app-top>
        <app-menu></app-menu>

        <router-outlet></router-outlet>

        <!-- <app-footer></app-footer> -->
    </div>
</div>
