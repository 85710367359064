import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products/products.service';
import * as $ from 'jquery';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductData } from '../../../models/product';
import { CommercantsService } from 'src/app/services/commercants/commercants.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-add-produit',
  templateUrl: './add-produit.component.html',
  styleUrls: ['./add-produit.component.css']
})
export class AddProduitComponent implements OnInit {
  // labelVariation: string = ""
  // quantityVariation: any = []

  filesToUpload: Array<File> = [];
  video: File = null;

  allCats: any = [];
  nbVariations = 0;

  images = [];
  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  isDelivery: Boolean = false
  tested: Boolean = false
  quantiteLimite: Boolean = false

  allCommercants: any = []
  idCommercant: string = ""

  cats_produit: any = []
  stock_reelle: number = null

  listCatsProds: any = []
  catsPrds: any = {}
  listSousCatsProds: any = []
  sousCatsPrd: string = ""
  typeCatsPrds: string = ""
  totalCatsPrd: number = 0

  tags_prd: any = []
  types: any = ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11",
    "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29",
    "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47",
    "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60", "61", "62", "63", "64", "65",
    "66", "67", "68", "69", "70", "71", "72", "73", "74", "75", "76", "77", "78", "79", "80", "81", "82", "83",
    "84", "85", "86", "87", "88", "89", "90", "91", "92", "93", "94", "95", "96", "97", "98", "99"]

  form: FormGroup;

  constructor(private PrdsServices: ProductsService,
    private commercantsService: CommercantsService,
    private http: HttpClient,
    private fb: FormBuilder,) {
    this.form = fb.group({
      variations: fb.array([])
    })
  }

  // upload() {
  //   const formData: any = new FormData();
  //   const files: Array<File> = this.filesToUpload;
  //   console.log(files);

  //   for (let i = 0; i < files.length; i++) {
  //     formData.append("uploads[]", files[i], files[i]['name']);
  //   }
  //   console.log('form data variable :   ' + formData.toString());
  //   this.http.post('https://api.marketbey.tn:2043/products/upload', formData)
  //     .subscribe(files => console.log('files', files))
  // }

  // fileChangeEvent(fileInput: any) {
  //   this.filesToUpload = <Array<File>>fileInput.target.files;
  //   //this.product.photo = fileInput.target.files[0]['name'];
  // }

  ngOnInit(): void {
    this.listCommercants();
    this.listCats()
    this.listCatsPrds()
    //this.addNewProductGroup()
  }

  // loadQuantities() {
  //   var quantityStock = 0
  //   this.quantityVariation.forEach(vart => {
  //     quantityStock += vart.quantity
  //   });
  //   this.stock_reelle = quantityStock
  // }
  handleVideoInput(event) {
    const fileToUpload = <Array<File>>event.target.files;
    this.video = fileToUpload[0]
  }
  onFileChange(event) {
    const newFfilesToUpload = <Array<File>>event.target.files;
    // this.selectedFiles = event.target.files;
    for (let i = 0; i < newFfilesToUpload.length; i++) {
      this.filesToUpload.push(newFfilesToUpload[i]);
    }

    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();

        reader.onload = (event: any) => {
          this.images.push(event.target.result);

          this.myForm.patchValue({
            fileSource: this.images
          });
        }

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  selectCatsPrd(item) {
    console.log("event.target.value")
    console.log(item)
    if (item != null) {
      this.listSousCatsPrds(item._id)
    }
    // this.selectedVille = event.target.value;
  }

  async listCommercants() {
    await this.commercantsService.allCommercants().then((res: any) => {
      if (res) {
        this.allCommercants = res;
      }
    });
  }

  // selectLabelVariation() {
  //   if (this.labelVariation != "") {
  //     var filterVart = this.form.value.variations.find(vart => {return vart.label == this.labelVariation})
  //     this.quantityVariation = []
  //     filterVart.valeurs.split(",").forEach(element => {
  //       this.quantityVariation.push({
  //         "variation": element,
  //         "quantity": ""
  //       })
  //     });
  //   } else {
  //     this.quantityVariation = []
  //   }
  // }
  // quantityVariationNotEmpty() {
  //   for (var i = 0; i < this.quantityVariation.length; i++) {
  //     if(this.quantityVariation[i].quantity == null || this.quantityVariation[i].quantity == "") {
  //       return false
  //     }
  //   }
  //   return true
  // }
  formVariationNotEmpty() {
    for (var i = 0; i < this.form.value.variations.length; i++) {
      if (this.form.value.variations[i].label == null || this.form.value.variations[i].label == ""
        || this.form.value.variations[i].valeurs == null || this.form.value.variations[i].valeurs == "") {
        return false
      }
    }
    return true
  }
  // variationNotEmpty() {
  //   if (this.form.value.variations.length == 0) return false
  //   return this.formVariationNotEmpty()
  // }

  async addProduct(nom_produit, code_barre, prix_achat_produit, prix_produit, ancienprix_produit,
    seuil_stock, description, commentaire,
    tag_prd1, tag_prd2, tag_prd3, tag_prd4, tag_prd5, tag_prd6) {

    if (nom_produit.value != '' && this.idCommercant != '' && this.catsPrds != '' && this.sousCatsPrd != ''
      && this.typeCatsPrds != '' &&
      (tag_prd1.value != '' || tag_prd2.value != '' || tag_prd3.value != ''
        || tag_prd4.value != '' || tag_prd5.value != '' || tag_prd6.value != '')
      && this.cats_produit.length > 0 && prix_achat_produit.value != '' && prix_achat_produit.value > 0
      && prix_produit.value != '' && prix_produit.value >= 0
      && this.stock_reelle != null && this.stock_reelle > 0
      && description.value != '' && this.images.length > 0 
      // && (this.form.value.variations.length > 0 && this.labelVariation != "" 
      //     && this.variationNotEmpty() && this.quantityVariationNotEmpty())
          ) {

      const formData: any = new FormData();
      const files: Array<File> = this.filesToUpload;
      // console.log("files");
      // console.log(files);

      for (let i = 0; i < files.length; i++) {
        formData.append("uploads[]", files[i], files[i]['name']);
      }

      if(this.video != null){
        formData.append("video", this.video, this.video['name']);
      }

      await this.countCatsPrds();

      var variations = []
      this.form.value.variations.forEach(vart => {
        variations.push({
          label: vart.label,
          valeurs: vart.valeurs.split(",")
        })
      });
      // console.log("variations : ")
      // console.log(JSON.stringify(variations))

      // console.log(this.myForm.value);
      var tags_prd = []
      if (tag_prd1.value != "") tags_prd.push(tag_prd1.value)
      if (tag_prd2.value != "") tags_prd.push(tag_prd2.value)
      if (tag_prd3.value != "") tags_prd.push(tag_prd3.value)
      if (tag_prd4.value != "") tags_prd.push(tag_prd4.value)
      if (tag_prd5.value != "") tags_prd.push(tag_prd5.value)
      if (tag_prd6.value != "") tags_prd.push(tag_prd6.value)

      // console.log("this.catsPrds : ")
      // console.log(this.catsPrds)

      var product = {
        pro_produit: this.idCommercant,
        nom_produit: nom_produit.value,
        codeabrre_produit: code_barre.value,
        tags_produit: JSON.stringify(tags_prd),
        prox_cat_produits: JSON.stringify(this.cats_produit),
        prix_achat_produit: prix_achat_produit.value,
        prix_produit: prix_produit.value,
        ancienprix_produit: ancienprix_produit.value,
        stock_reelle: this.stock_reelle,
        seuil_stock: seuil_stock.value,
        isDelivery: this.isDelivery,
        tested: this.tested,
        quantiteLimite: this.quantiteLimite,
        comment_produit: commentaire.value,
        description_produit: description.value,
        variations: JSON.stringify(variations),
        categorie_produit: this.catsPrds.code_cat_prdt,
        ss_categorie_produit: this.sousCatsPrd,
        typecode_prdt: this.typeCatsPrds,
        ref_interne_produit: this.catsPrds.code_cat_prdt + "-" + this.sousCatsPrd + "-" + this.typeCatsPrds + "-" + this.totalCatsPrd
      }

      // console.log("produit : ")
      // console.log(product)

      for (var key in product) {
        formData.append(key, product[key]);
      }

      // console.log('form data variable :   ' + formData.toString());
      // this.http.post('https://api.marketbey.tn:2043/products/upload', formData)
      //   .subscribe(files => console.log('files', files))

      // console.log("this.myForm.value")
      // console.log(this.myForm.value)
      //////////////
      console.log("formDataaaaaaaaaaaaaaaaaa")
      console.log(formData)
      if (confirm('Etes vous sûre?')) {
        await this.PrdsServices.addProduct(formData).then((res: any) => {
          console.log("resssssss uploadPrds : ")
          console.log(res)
          if (res) {
            location.href = '/pending-produit';
          }
        }).catch((e) => { console.log(e) });
        // location.href = '/pending-produit';
      }
    } else {
      alert('produit est invalide');
    }
  }
  addNewProductGroup() {
    const add = this.form.get('variations') as FormArray;

    add.push(this.fb.group({
      label: [],
      valeurs: []
    }))
    // console.log(add)
  }

  deleteProductGroup(index: number) {
    console.log("index")
    console.log(index)
    const add = this.form.get('variations') as FormArray;
    
    console.log("value index : ")
    console.log(this.form.value.variations[index])
    // if(this.form.value.variations[index].label == this.labelVariation) {
    //   this.labelVariation = ""
    //   this.quantityVariation = []
    // }
    add.removeAt(index)
  }

  get f() {
    return this.myForm.controls;
  }

  removeSelectedFile(index) {
    // console.log(index)
    // Delete the item from fileNames list
    this.myForm.value.fileSource.splice(index, 1);
    this.filesToUpload.splice(index, 1)
    // delete file from FileList
    // this.fileList.splice(index, 1);
    // console.log(this.myForm.value);
  }

  async listCats() {
    await this.PrdsServices.allCats().then((res: any) => {
      if (res) {
        this.allCats = res;
      }
    });
  }
  async listCatsPrds() {
    await this.PrdsServices.allCatsPrds().then((res: any) => {
      // console.log("resssssss : ")
      // console.log(res)
      if (res) {
        this.listCatsProds = res;
      }
    });
  }
  async listSousCatsPrds(idCatsPrd) {
    await this.PrdsServices.allSousCatsPrds(idCatsPrd).then((res: any) => {
      // console.log("resssssss : ")
      // console.log(res)
      if (res) {
        this.listSousCatsProds = res;
      }
    });
  }
  async countCatsPrds() {
    await this.PrdsServices.countCatsPrds(this.catsPrds.code_cat_prdt, this.sousCatsPrd, this.typeCatsPrds).then((res: any) => {
      if (res) {
        this.totalCatsPrd = res + 1;
      } else {
        this.totalCatsPrd = 1;
      }
    });
  }

  // AddVariation() {
  //   var tplVriation = $("#exemplevariation").html().replace(/##NBR##/g, this.nbVariations);
  //   $("#rowVariations").append(tplVriation)
  //   this.nbVariations++
  // }

  // deleteVariation(idVr) {
  //   if (confirm("Etes vous sure ?"))
  //     if ($("[data-idVariation=" + idVr + "]").length)
  //       $("[data-idVariation=" + idVr + "]").remove()
  // }

}
