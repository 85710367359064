<i class="material-icons" style="font-size: 100px;color: #ff0000">info</i>
<div mat-dialog-content style="width: 450px;">
    <h1 style="text-align:center"><strong></strong></h1>
    <h2 style="text-align:center;font-size: x-large; color: rgb(194, 192, 192);">Ajouter un acompte</h2>
    <div class="div-form">
        <div class="form-group">
            <!-- <select class="form-control selectric" (change)="selectChangeHandler($event)">
                <option [value]="null">Selectionner un transporteur</option>
                <option [value]="user.element._id" *ngFor="let user of leadersList" #user ngModel>
                    {{user.element.nom_prenom_tracking}}</option>
            </select> -->

            <label style="font-size: medium; color: white;">Entrer l'acompte :</label>
            <input type="number" min="1" class="form-control" name="account"
                (input)="accountChange($event.target.value)">
            <mat-error style="color: red;font-weight: bold;" *ngIf="data.accountProvider<=0">Entrez acompte supérieure à 0
            </mat-error>
            <!-- <div class="d-block">
          <label for="ville">Ville</label>
        </div>
        <input id="ville" type="text" class="form-control" name="ville" tabindex="2" #ville ngModel> -->
            <!-- <div class="invalid-feedback">
        Mot Obligatoire
        </div> -->
        </div>
    </div>
</div>
<div class="float-right">
    <input type="button" value="NON" [mat-dialog-close]="false" class="btn btn-info">

    <input style="margin:0px 10px;" type="button" value="OUI" [mat-dialog-close]="true" class="btn btn-danger" *ngIf="data.accountProvider>0">
</div>