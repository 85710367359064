import { Component } from '@angular/core';
import { UsersService } from './services/users/users.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Marketbey - E-tracking';
  isAdmin: Boolean = false
  constructor(
    private authService: UsersService,) {
    this.isUserAdmin()
  }

  async isUserAdmin() {
    this.isAdmin = await this.authService.isAdmin()
  }
}
