<i class="material-icons" style="font-size: 100px;color: #ff0000">info</i>
<div mat-dialog-content>
    <h1 style="text-align:center"><strong></strong></h1>
    <h2 style="text-align:center">Vous allez sélectionner le transporteur</h2>
    <div class="div-form">
        <div class="form-group">
            <select class="form-control selectric" (change)="selectChangeHandler($event)" >
                <option [value]="null">Selectionner un transporteur</option>
                <option [value]="user.element._id" *ngFor="let user of leadersList" #user ngModel>{{user.element.nom_prenom_tracking}}</option>
            </select>
            <!-- <div class="d-block">
          <label for="ville">Ville</label>
        </div>
        <input id="ville" type="text" class="form-control" name="ville" tabindex="2" #ville ngModel> -->
            <!-- <div class="invalid-feedback">
        Mot Obligatoire
        </div> -->
        </div>
    </div>
</div>
<div class="float-right">
    <input type="button" value="NON" [mat-dialog-close]="false" class="btn btn-info">

    <input style="margin:0px 10px;" type="button" value="OUI" [mat-dialog-close]="true" class="btn btn-danger">
</div>